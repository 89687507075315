import * as Yup from "yup";

export const notificationSettingsSchema = Yup.object().shape({

    MinWin: Yup.number()
    .min(0, "Min Win must be at least 0.")
    .integer("Min Win must be a whole number.")
    .required("Min Win is required."),
    SlotsMinBet: Yup.number()
    .min(0, "Min Win must be at least 0.")
    .integer("Min Win must be a whole number.")
    .required("Min Win is required."),
    TableMinBet: Yup.number()
    .min(0, "Min Win must be at least 0.")
    .integer("Min Win must be a whole number.")
    .required("Min Win is required."),
    PackageActivation: Yup.boolean(),
    TournamentActivation: Yup.boolean(),
    GiveawayActivation: Yup.boolean(),
});