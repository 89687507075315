import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Table, Form as BForm } from "@themesberg/react-bootstrap";
import {
  formatDateMDY,
} from "../../../utils/dateFormatter.js";

import { getCRMbonusDetail, getCRMPromoCodeHistory, getPromoCodeHistory } from "../../../utils/apiCalls.js";
import { useQuery } from "@tanstack/react-query";
import { viewPromocodeHeaders } from "../constant.js";


const ViewCRMPromoCode = ({ data }) => {
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('')
  const [sort, setSort] = useState('DESC')
  const { crmPromotionId } = useParams();

  const { data: promoDetail, isLoading: loading } = useQuery({
    queryFn: () => {
        return getCRMPromoCodeHistory(crmPromotionId)
    },
    select: (res) => res?.data?.details,
    refetchOnWindowFocus: false
})

console.log(promoDetail);
// const { data: bonusDetails, isLoading: lbonusDetailoading } = useQuery({
//   queryKey: ['bonusDetails', limit, page, orderBy, sort, crmPromotionId],

//   queryFn: ({ queryKey }) => {
//     const params = { pageNo: queryKey[2], limit: queryKey[1], crmPromotionId: queryKey[5] };
//     if (queryKey[3]) params.limit = queryKey[3]
//     if (queryKey[3]) params.page = queryKey[3]
//     if (queryKey[3]) params.orderBy = queryKey[3]
//     if (queryKey[4]) params.sort = queryKey[4]
//     if (queryKey[5]) params.crmPromotionId = queryKey[5]

//     return getCRMbonusDetail(crmPromotionId);
//   },
//   select: (res) => res?.data,
//   refetchOnWindowFocus: false,
// });
// console.log('bonusDetails',bonusDetails);

  const handleAddGame = (e,item) =>{
   console.log(e,item)
  }

  
  return (

    <div>
      <Row>
        <Col sm={8}>
          <h3>View CRM Promo Codes </h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={3} >
          <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
            <h6 className='mb-0 me-2'>Promocode</h6>
            <span >{promoDetail?.promocode}</span>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} >
          <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
            <h6 className='mb-0 me-2'>Name</h6>
            <span >{promoDetail?.name}</span>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} >
          <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
            <h6 className='mb-0 me-2'>Promotion Type</h6>
            <span >{promoDetail?.promotionType === 'scheduled-campain' ? 'Scheduled' : 'Trigerred'}</span>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} >
          <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
            <h6 className='mb-0 me-2'>Status</h6>
            <span >{promoDetail?.isActive == true ? "Active" : "Inactive"}</span>
          </div>
        </Col>

      </Row>

      {/* <Row className="mt-3">
        <BForm.Label>Selected Package Details</BForm.Label>
        <div style={{ overflow: 'auto' }}>
          {promoDetail?.packageDetails ? (
            <Table bordered striped hover size='sm' className='text-center mt-4'>
              <thead className='thead-dark'>
                <tr>
                  <th>
                    Selected
                  </th>
                  <th>Package ID</th>
                  <th>Amount</th>
                  <th>GC Coin</th>
                  <th>SC Coin</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {promoDetail?.packageDetails?.map((item, idx) => (
                  <tr key={idx}>
                    <td>
                      <input
                        name='select'
                        type='checkbox'
                        className='form-check-input cursor-pointer'
                        disabled
                        checked={item.packageId}
                        onChange={(e) => handleAddGame(e, item)}
                      />
                    </td>
                    <td>{item.packageId}</td>
                    <td>{item.amount}</td>
                    <td>{item.gcCoin}</td>
                    <td>{item.scCoin}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                  </tr>
                ))}
                {promoDetail?.packageDetails.length === 0 && (
                  <tr><td className='text-danger' colSpan={6}>No Data Available</td></tr>
                )}
              </tbody>
            </Table>
          ) : (
            <p className='text-danger text-center mt-3'>No Data Available</p>
          )}
        </div>
      </Row>
      <Row className="mt-3">

        <Table
          bordered
          striped
          responsive
          hover
          size="sm"
          className="text-center mt-4"
        >
          <thead className="thead-dark">
            <tr>
              {viewPromocodeHeaders.map((h, idx) => (
                <th
                  key={idx}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {h.labelKey}{" "}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {promoDetail?.appliedDetails?.count > 0 &&
              promoDetail?.appliedDetails?.rows?.map(
                ({ userId, User, claimedAt }) => {
                  return (
                    <tr key={userId}>
                      <td>{userId}</td>
                      <td>{User?.username}</td>
                      <td><Link to={`/admin/player-details/${userId}`}>{User?.email}</Link></td>
                      <td>{User?.firstName} {User?.lastName}</td>
                      <td>{User?.isActive ? "Active" : "Inactive"}</td>
                      <td>{formatDateMDY(claimedAt)}</td>
                    </tr>
                  );
                }
              )}

            {promoDetail?.appliedDetails?.count === 0 && (
              <tr>
                <td colSpan={7} className="text-danger text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row> */}

    </div>
  );
};

export default ViewCRMPromoCode;
