export const redeemRules = [
  // { label: 'Minimum Account Balance', value: 'MINIMUM_ACCOUNT_BALANCE' },
  { label: 'Maximum Redemption Amount', value: 'Maximum Redemption Amount' },
  { label: 'Minimum Redemption Amount', value: 'Minimum Redemption Amount' },
  // { label: 'Daily Redemption Limit', value: 'DAILY_REDEMPTION_LIMIT' },
  { label: 'Ngr', value: 'Ngr' },
  { label: 'Ggr', value: 'Ggr' },
];
export const tableHeaders = [
  { labelKey: 'headers.id', value: 'ruleId' },
  { labelKey: 'headers.title', value: 'ruleName' },
  { labelKey: 'headers.status', value: 'isActive' },
  { labelKey: 'headers.action', value: '' },
]


// export const states = [
//   { label: "Alaska (Id:1)", value: "1" },
//   { label: "Alabama", value: "2" },
//   { label: "Arkansas", value: "3" },
//   { label: "Arizona", value: "4" },
//   { label: "California", value: "5" },
//   { label: "Colorado", value: "6" },
//   { label: "Connecticut", value: "7" },
//   { label: "Delaware", value: "9" },
//   { label: "Florida", value: "10" },
//   { label: "Georgia", value: "11" },
//   { label: "Hawaii", value: "12" },
//   { label: "Iowa", value: "13" },
//   { label: "Illinois", value: "14" },
//   { label: "Indiana", value: "15" },
//   { label: "Kansas", value: "16" },
//   { label: "Louisiana", value: "17" },
//   { label: "Massachusetts", value: "18" },
//   { label: "Maryland", value: "19" },
//   { label: "Maine", value: "20" },
//   { label: "Michigan", value: "21" },
//   { label: "Minnesota", value: "22" },
//   { label: "Missouri", value: "23" },
//   { label: "Mississippi", value: "24" },
//   { label: "Montana", value: "25" },
//   { label: "North Carolina", value: "26" },
//   { label: "North Dakota", value: "27" },
//   { label: "Nebraska", value: "28" },
//   { label: "New Hampshire", value: "29" },
//   { label: "New Jersey", value: "30" },
//   { label: "New Mexico", value: "31" },
//   { label: "New York", value: "32" },
//   { label: "Ohio", value: "33" },
//   { label: "Oklahoma", value: "34" },
//   { label: "Oregon", value: "35" },
//   { label: "Pennsylvania", value: "36" },
//   { label: "Rhode Island", value: "37" },
//   { label: "South Carolina", value: "38" },
//   { label: "South Dakota", value: "39" },
//   { label: "Tennessee", value: "40" },
//   { label: "Texas", value: "41" },
//   { label: "Utah", value: "42" },
//   { label: "Virginia", value: "43" },
//   { label: "Vermont", value: "44" },
//   { label: "Wisconsin", value: "45" },
//   { label: "West Virginia", value: "46" },
//   { label: "Wyoming", value: "47" },
//   { label: "Nevada", value: "48" },
//   { label: "Kentucky", value: "49" },
//   { label: "Madhya Pradesh", value: "63" },
//   { label: "Karnataka", value: "64" },
// ];
export const states = [
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Montana', value: 'Montana' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New York', value: 'New York' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wyoming', value: 'Wyoming' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
  { label: 'Karnataka', value: 'Karnataka' }
]

export const KycLevel = [
  { label: "K0", value: "K0" },
  { label: "K1", value: "K1" },
  { label: "K2", value: "K2" },
  { label: "K3", value: "K3" },
  { label: "K4", value: "K4" },
  { label: "K5", value: "K5" },
];

export const fields = [
  { name: "userId", label: "User Id" ,defaultValue:"true" },
  { name: "kycStatus", label: "KYC Status" },
  {name:"paymentProvider", label: "Payment Provider"},
  { name: "minimumLastRedeemHours", label: "Min. Last Redeem Hours" },
  { name: "ngr", label: "NGR" },
 { name: "ggr", label: "GGR" },
  { name: "playThrough", label: "Playthrough" },
  { name: "depositAmount", label: "Total Deposit Amount" },
  { name: "notAllowedStates", label: "Not Allowed States" },
  { name: "tierLevel", label: "Tier Level"},
  { name: "totalRedeemAmount", label: "Total Redeem Amount" },
  { name: "redeemAmount", label: "Redeem Amount" },
];

export const customOperators = [
  { name: "=", label: "=" },
 
  { name: "<", label: "<" },
  { name: ">", label: ">" },
  { name: "<=", label: "≤" },
  { name: ">=", label: "≥" },
];

export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: '0' },
  { label: 'In Progress', value: '7' },
  { label: 'Approved', value: '1' },
  { label: 'Declined', value: '6' },
  { label: 'Failed', value: '3' },
  { label: 'Canceled', value: '2' },
  {label:'Scheduled', value: '8' },
]
export const paymentProviderOptions = [
  { label: 'Skrill', value: 'SKRILL' },
  { label: 'Pay By Bank', value: 'PAY_BY_BANK' },
]