import React, { useState } from "react";
import { Table, Button } from "@themesberg/react-bootstrap";
import { InlineLoader } from "../Preloader";
import PaginationComponent from "../Pagination";
import { tableHeaders } from "./constants";
import { getDateTime } from "../../utils/dateFormatter";
import { Link } from "react-router-dom";
import {
    convertToTimeZone,
    getFormattedTimeZoneOffset,
} from "../../utils/helper";
import { getItem } from "../../utils/storageUtils";
import { timeZones } from "../../pages/Dashboard/constants";
import { useUpdatePostalCodeStatus } from "../../reactQuery/hooks/customMutationHook";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckSquare,
    faWindowClose,
} from '@fortawesome/free-solid-svg-icons'
import { ConfirmationModal } from "../ConfirmationModal";
import Trigger from "../OverlayTrigger";

const AmoeList = ({
    page,
    setLimit,
    limit,
    setPage,
    totalPages,
    loading,
    data,
}) => {
    const timeZone = getItem("timezone");
    const timezoneOffset = timeZone != null ? timeZones.find(x => x.code === timeZone).value : getFormattedTimeZoneOffset()
    const [updatedStatuses, setUpdatedStatuses] = useState({});
    const [statusShow, setStatusShow] = useState(false);
    const [statusToUpdate, setStatusToUpdate] = useState(null);
    const [itemToUpdate, setItemToUpdate] = useState(null);

    const renderStatus = (status) => {
        if (status === 0 || status === 2) return <span >Not Claimed</span>;
        if (status === 1) return <span >Claimed</span>;
    };

    const updateStatus = useUpdatePostalCodeStatus({})

    const handleOnSubmit = async () => {
        if (!itemToUpdate || statusToUpdate == null) return;

        const payload = {
            userId: itemToUpdate?.User?.userId.toString(),
            status: statusToUpdate.toString(),
            postalCodeId: itemToUpdate?.postalCode.toString(),
            bonusType: "postal-code-bonus",
        };

        updateStatus.mutate(payload, {
            onSuccess: () => {
                setUpdatedStatuses((prev) => ({
                    ...prev,
                    [itemToUpdate?.postalCodeId]: statusToUpdate,
                }));
                setStatusShow(false); // Close modal on success
            },
        });
    };

    const handleActionClick = (item, status) => {
        setItemToUpdate(item);
        setStatusToUpdate(status);
        setStatusShow(true); // Show confirmation modal
    };

    const renderActionButtons = (item, currentStatus) => {
        if (new Date(item.validTo) < new Date()) {
            console.log("####item", item)
            return <span className="text-warning">Expired</span>;
        }

        switch (currentStatus) {
            case 1:
                return <span className="text-success m-2" >Success</span>;
            case 2:
                return <span className="text-danger m-2">Rejected</span>;
            default:
                return (
                    <div>
                        <Trigger message="Accept" id={item?.postalCodeId + "accept"} />
                        <Button
                            className="btn btn-success m-1"
                            id={item?.postalCodeId + "accept"}
                            size="sm"
                            onClick={() => handleActionClick(item, 1)}
                        >
                            <FontAwesomeIcon icon={faCheckSquare} />
                        </Button>
                        <Trigger message="Reject" id={item?.postalCodeId + "reject"} />
                        <Button
                            className="btn btn-danger m-1"
                            id={item?.postalCodeId + "reject"}
                            size="sm"
                            onClick={() => handleActionClick(item, 2)}
                        >
                            <FontAwesomeIcon icon={faWindowClose} />
                        </Button>
                    </div>
                );
        }
    };
    return (
        <>
            <Table
                bordered
                striped
                responsive
                hover
                size="sm"
                className="text-center mt-4"
            >
                <thead className="thead-dark">
                    <tr>
                        {tableHeaders.map((h, idx) => (
                            <th
                                key={idx}
                                style={{
                                    cursor: 'pointer'
                                }}
                            >
                                {h.labelKey}
                            </th>
                        ))}
                    </tr>
                </thead>
                {loading ?
                    (<tr>
                        <td colSpan={10} className="text-center">
                            <InlineLoader />
                        </td>
                    </tr>) :
                    (
                        <tbody>
                            {data && data?.rows?.length > 0 ? (
                                data?.rows?.map((value, index) => {
                                    const currentStatus = updatedStatuses[value?.postalCodeId] ?? value?.status;
                                    return (
                                        <tr key={index} className="text-center" style={{
                                            height: "40px",
                                            verticalAlign: "middle",
                                        }}>
                                            <td>{value?.postalCodeId}</td>
                                            <td><Link to={`/admin/player-details/${value?.User?.userId}`}>{value?.User?.email}</Link></td>
                                            <td>{value?.User?.userId}</td>
                                            <td>{value?.User?.username}</td>
                                            <td>{renderStatus(currentStatus)}</td>
                                            <td>{value?.postalCode}</td>
                                            <td>{getDateTime(convertToTimeZone(value?.createdAt, timezoneOffset))}</td>
                                            <td>{getDateTime(convertToTimeZone(value?.validTo, timezoneOffset))}</td>
                                            <td>{value?.ipAddress}</td>
                                            <td style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: "5px",
                                            }}
                                            >{renderActionButtons(value, currentStatus)}</td>
                                        </tr>
                                    );
                                }
                                )
                            ) : (
                                <tr>
                                    <td colSpan={9} className="text-danger text-center">
                                        No data Found
                                    </td>
                                </tr>
                            )}

                        </tbody>)}
            </Table>

            {data?.count !== 0 && (
                <PaginationComponent
                    page={data?.count < page ? setPage(1) : page}
                    totalPages={totalPages}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                />
            )}



            <ConfirmationModal
                setShow={setStatusShow}
                show={statusShow}
                handleYes={handleOnSubmit}
                message={`Are you sure you want to mark this as ${statusToUpdate === 1 ? "Success" : "Reject"
                    }?`}
                loading={updateStatus.isLoading}
            />
        </>
    );
};

export default AmoeList;
