import React, { useState } from "react"
import { Card, ListGroup, InputGroup, Form, FormControl, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap'
import './notifications.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTrash, faCheckCircle, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons"
import { useUserStore } from '../../store/store'
import NotificationDetail from "./NotificationDetail"

const groupNotificationsByDate = (notifications) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset to start of today

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1); // Move to yesterday

  const grouped = {
    today: [],
    yesterday: [],
    older: []
  };

  notifications?.data?.notifications?.rows.forEach((notif) => {
    const notifDate = new Date(notif.createdAt);
    notifDate.setHours(0, 0, 0, 0); // Reset to start of notification's day

    if (notifDate.getTime() === today.getTime()) {
      grouped.today.push(notif);
    } else if (notifDate.getTime() === yesterday.getTime()) {
      grouped.yesterday.push(notif);
    } else {
      grouped.older.push(notif);
    }
  });

  return grouped;
};

const getTimeDifference = (timestamp) => {
  const now = new Date();
  const past = new Date(timestamp);
  const diffMs = now - past; // Difference in milliseconds

  const seconds = Math.floor(diffMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
  if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  return "Just now";
};

const NotificationList = ({
  notificationsData,
  setSearch,
  isUnread,
  handleSetUnread,
  markReadNotifications,
  handleLoadMore
}) => {
  const [notifications, setNotifications] = useState(notificationsData?.data?.notifications?.rows);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);

  const userDetails = useUserStore((state) => state.userDetails)

  // Delete Notification
  const deleteNotification = (id) => {
    setNotifications(notifications.filter(n => n.id !== id));
  };

  const groupedNotifications = groupNotificationsByDate(notificationsData);

  return (
    <Card className="notifications-list-container mt-1 shadow-sm" style={{ margin: "auto" }}>
      <Card.Header className="d-flex justify-content-between align-items-center p-2">
        <InputGroup className="w-75 me-3">
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <FormControl
            placeholder="Search Notifications"
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
        <Form.Check
          className="unread-check"
          type="switch"
          id="unread-switch"
          label="Unread Only"
          checked={isUnread}
          onChange={handleSetUnread}
        />
      </Card.Header>

      <Card.Body>
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          {Object.values(groupedNotifications).every(group => group.length === 0) ? (
            <div style={{ width: "430px" }} className="text-center text-muted">No Data Available</div>
          ) : (
            Object.keys(groupedNotifications).map((group) => (
              groupedNotifications[group].length > 0 && (
                <div key={group}>
                  <h6 className="fw-bold">
                    {group === "today" ? "Today" : group === "yesterday" ? "Yesterday" : "Older"}
                  </h6>
                  <ListGroup variant="flush">
                    {groupedNotifications[group].map((notif) => {
                      const isUnread = !notif?.status?.includes?.(userDetails?.adminUserId);
                      const isSelected = selectedNotificationId === notif.id;

                      return (
                        <ListGroup.Item
                          key={notif.id}
                          className="d-flex align-items-center"
                          style={{ backgroundColor: isUnread ? "#E4E0E1" : "#ffffff" }}
                        >
                          <div
                            className="flex-grow-1"
                            onClick={() => {
                              if (isUnread) markReadNotifications(notif.id);
                            }}
                          >
                            <strong>{notif.title}</strong>
                            <span className="time-text text-muted ms-2">{getTimeDifference(notif.createdAt)}</span>
                            <p className="notification-message mb-0 text-muted">{notif.message}</p>

                            <div
                              className="notification-show-more mb-0 text-muted"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedNotificationId(isSelected ? null : notif.id);
                                if (isUnread) markReadNotifications(notif.id);
                              }}
                            >
                              {isSelected ? "Show less" : "Show more"}
                            </div>

                            {isSelected && (
                              <NotificationDetail
                                notificationData={notif}
                                setShowNotificationDetails={() => setSelectedNotificationId(null)}
                              />
                            )}
                          </div>
                          <div className="d-flex flex-column align-items-end">
                            <Dropdown as={ButtonGroup} drop="end" align="end" autoClose="true">
                              <Dropdown.Toggle className="notification-menu" variant="primary" id="notification-menu">
                                <FontAwesomeIcon icon={faEllipsisVertical} style={{ color: "rgb(38,43,64)" }} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="notification-menu-content">
                                {isUnread && (
                                  <Dropdown.Item onClick={() => markReadNotifications(notif.id)}>
                                    <FontAwesomeIcon icon={faCheckCircle} className="me-1" style={{ color: "rgb(38,43,64)" }} />
                                    Mark as read
                                  </Dropdown.Item>
                                )}
                                {/* <Dropdown.Item onClick={() => deleteNotification(notif.id)}>
                                  <FontAwesomeIcon icon={faTrash} className="me-1" style={{ color: "rgb(38,43,64)" }} />
                                  Delete
                                </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </div>
              )
            ))
          )}
          <div
            className="notifications-load-more mb-0 text-muted"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              console.log('Load more')
              handleLoadMore()
            }}
          >
            Load more
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default NotificationList;
