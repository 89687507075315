import * as Yup from 'yup';

const loyaltyTierSchema = (currentTierId, currentTier) =>
  Yup.object().shape({
    tierId: Yup.number().test('is-valid-tier', `Tier must be greater than ${currentTier}`, (value) => {
      return value !== undefined && value > currentTierId;
    }),
    expiryDate: Yup.date()
      .typeError('Expiry Date is required')
      .min(new Date(), 'Date cannot be earlier than today')
      .required('Expiry Date is required'),

    reason: Yup.string().required('Reason is required'),
  });

export default loyaltyTierSchema;
