import React, { useState } from 'react';
import { Card, Row, Col } from '@themesberg/react-bootstrap';
import { Formik } from 'formik';
import { updatePackageSchema } from '../schemas';
import PackageCreateForm from './PackageCreateForm';
import useEditPackage from '../hooks/useEditPackage';
import { useNavigate } from 'react-router-dom';
import { convertToUTC, getDateTime } from '../../../utils/dateFormatter';
import { toast } from '../../../components/Toast';
import { AdminRoutes } from '../../../routes';
import { serialize } from 'object-to-formdata';

const EditPackageDetails = () => {
  const navigate = useNavigate();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const isEditPage = true;
  const onSuccess = (res) => {
    if (res?.data.success && (res?.data?.message || res?.data?.updatedPackage[0])) {
      navigate(AdminRoutes.Packages);
      toast(res?.data?.message, 'success', 'packageUpdate');
    } else {
      toast(res?.data?.message, 'error', 'packageCreate');
    }
  };

  const onError = (error) => {
    toast(error.response.data.errors[0].description, 'error', 'packageCreate');
  };

  const {
    packageData,
    editPackage,
    packageId,
    loading,
    typeOptions,
    typeValue,
    setTypeValue,
    isSelectLoading,
    handleCreateOption,
    handleDeleteModal,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    handleShow,
    handleYes,
    show,
    setShow,
    active,
  } = useEditPackage(onSuccess, onError);

  const handleEditPackageSubmit = (formValues) => {
    const {
      intervalDays,
      discountedAmount,
      subpackageScCoin,
      subpackageGcCoin,
      subpackageGcBonus,
      subpackageScBonus,
      subpackageBonusPercentage,
      subpackageNoOfPurchase,
      subpackagePurchaseDate,
      subpackageIsActive,
      ...updatedFormValues
    } = formValues;
    const body = {
      ...updatedFormValues,
      amount: formValues.amount.toString(),
      isActive: formValues.isActive,
      packageName: formValues.packageName,
      currency: 'USD',
      isVisibleInStore: formValues.isVisibleInStore,
      firstPurchaseApplicable: false,
      firstPurchaseScBonus: formValues.firstPurchaseApplicable ? formValues.firstPurchaseScBonus : 0,
      firstPurchaseGcBonus: formValues.firstPurchaseApplicable ? formValues.firstPurchaseGcBonus : 0,
      welcomePurchaseBonusApplicable: formValues?.welcomePurchaseBonusApplicable,
      welcomePurchaseBonusApplicableMinutes: formValues?.welcomePurchaseBonusApplicable
        ? formValues?.welcomePurchaseBonusApplicableMinutes
        : 0,
      purchaseLimitPerUser: formValues?.purchaseLimitPerUser,
      validTill: formValues.isValidUntil ? convertToUTC(formValues.validTill) : '',
      newPackageType: false,
      textColor: formValues.textColor,
      backgroundColor: formValues.backgroundColor,
      previousAmount: formValues?.previousAmount === 0 ? null : formValues?.previousAmount,
      welcomePurchasePercentage: formValues?.welcomePurchasePercentage,
      validFrom: formValues.isValidFrom ? convertToUTC(formValues.validFrom) : '',
      bonusSc: formValues?.bonusSc || 0,
      bonusGc: formValues?.bonusGc || 0,
      playerIds: selectedUserIds,

      filterType: formValues?.filterType,
      filterOperator: formValues?.filterOperator,
      filterValue: Number(formValues?.filterValue),
      isSpecialPackage: formValues.isSpecialPackage,
      purpackageFirstPurchase: formValues?.purpackageFirstPurchase || [],
      purchaseNo: formValues?.ispurchaseNo ? (formValues?.purchaseNo ? formValues?.purchaseNo : 0) : 0,
      intervalsConfig: formValues?.intervalsConfig?.length ? JSON.stringify(formValues?.intervalsConfig) : null,
    };

    console.log(body);

    if (formValues.image) {
      body.image = formValues.image;
    }
    editPackage(serialize(body));
  };

  return (
    <div>
      <Row>
        <Col sm={8}>
          <h3>Edit Package</h3>
        </Col>
      </Row>

      <Card body>
        {packageData && (
          <Formik
            initialValues={{
              packageName: packageData?.packageName,
              packageId: +packageId,
              amount: packageData?.amount ?? '',
              gcCoin: packageData?.gcCoin ?? '',
              scCoin: packageData?.scCoin ?? '',
              isActive: packageData?.isActive,
              isVisibleInStore: packageData?.isVisibleInStore,
              firstPurchaseApplicable: false,
              firstPurchaseScBonus: packageData.firstPurchaseScBonus || 0,
              firstPurchaseGcBonus: packageData.firstPurchaseGcBonus || 0,
              isValidUntil: packageData.validTill ? true : false,
              validTill: packageData.validTill ? getDateTime(packageData.validTill) : null,
              welcomePurchaseBonusApplicable: packageData?.welcomePurchaseBonusApplicable ? true : false,
              welcomePurchaseBonusApplicableMinutes: packageData?.welcomePurchaseBonusApplicableMinutes,
              purchaseLimitPerUser: packageData?.purchaseLimitPerUser || 0,
              welcomePurchasePercentage: packageData?.welcomePurchasePercentage || 0,
              isValidFrom: packageData.validFrom ? true : false,
              validFrom: packageData.validFrom ? getDateTime(packageData.validFrom) : null,
              bonusSc: packageData?.bonusSc || 0,
              bonusGc: packageData?.bonusGc || 0,
              filterType: packageData?.moreDetails?.filterType || null,
              filterOperator: packageData?.moreDetails?.filterOperator || null,
              filterValue: Number(packageData?.moreDetails?.filterValue) || 0,
              isSpecialPackage: packageData?.isSpecialPackage,
              packageFirstPurchase: packageData?.packageFirstPurchase || [],
              purchaseNo: packageData.purchaseNo ? packageData.purchaseNo : 0,
              ispurchaseNo: packageData.purchaseNo ? true : false,
              intervalDays: '',
              discountedAmount: '',
              subpackageScCoin: '',
              subpackageGcCoin: '',
              subpackageGcBonus: '',
              subpackageScBonus: '',
              subpackageBonusPercentage: '',
              subpackageNoOfPurchase: null,
              subpackagePurchaseDate: false,
              subpackageIsActive: false,
              intervalsConfig: packageData?.nonPurchasePackages,
            }}
            validationSchema={updatePackageSchema}
            enableReinitialize
            onSubmit={handleEditPackageSubmit}
          >
            {({ values, handleChange, handleSubmit, handleBlur, setFieldValue, resetForm }) => (
              <PackageCreateForm
                values={values}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                resetForm={resetForm}
                navigate={navigate}
                loading={loading}
                packageData={packageData}
                isEdit={!!packageData?.PackageUsers?.length}
                typeOptions={typeOptions}
                typeValue={typeValue}
                setTypeValue={setTypeValue}
                isSelectLoading={isSelectLoading}
                handleCreateOption={handleCreateOption}
                selectedUserIds={selectedUserIds}
                setSelectedUserIds={setSelectedUserIds}
                handleDeleteModal={handleDeleteModal}
                handleDeleteYes={handleDeleteYes}
                deleteModalShow={deleteModalShow}
                setDeleteModalShow={setDeleteModalShow}
                show={show}
                handleYes={handleYes}
                active={active}
                handleShow={handleShow}
                setShow={setShow}
                isEditPage={isEditPage}
              />
            )}
          </Formik>
        )}
      </Card>
    </div>
  );
};

export default EditPackageDetails;
