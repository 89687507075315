

export const tableHeaders = [
    { labelKey: 'Promocode Id', value: 'crmPromotionId' },
    { labelKey: 'Promocode', value: 'promocode' },
    { labelKey: 'Name', value: 'name' },
    { labelKey: 'Is Active', value: 'isActive' },
    // { labelKey: 'Claim Bonus', value: 'claimBonus' },
    { labelKey: 'Promotion Type', value: 'promotionType' },
    { labelKey: 'SC Amount', value: 'scAmount' },
    { labelKey: 'GC Amount', value: 'gcAmount' },
    // { labelKey :'Valid Till' , value:'validTill'},
    { labelKey: 'Action', value: 'Action' },
];


export const viewPromocodeHeaders = [
    { labelKey: 'User Id', value: 'userId' },
    { labelKey: 'Username', value: 'username' },
    { labelKey: 'Email', value: 'email' },
    { labelKey: 'Name', value: 'firstName' },
    // { labelKey: 'Last Name', value: 'lastName' },
    { labelKey: 'Status', value: 'isActive' },
    { labelKey: 'Claimed At', value: 'claimedAt' },
  ];
  
