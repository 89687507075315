export const tableHeaders = [
  { labelKey: 'User ID', value: 'userId' },
  { labelKey: 'User Name', value: 'username' },
  { labelKey: 'Email', value: 'email' },
  { labelKey: 'GGR', value: 'ggr' },
  { labelKey: 'NGR', value: 'ngr' },
  { labelKey: 'Lifetime Purchase', value: 'lifetimePurchase' },
  { labelKey: 'Lifetime Redemption', value: 'lifetimeRedemption' },
  { labelKey: 'Player Rating', value: 'playerRating' },
  { labelKey: 'User Profile', value: 'userProfile' },
  { labelKey: 'Managed By', value: 'managedBy' },
];

export const customerHeaders = [
  { labelKey: 'User ID', value: 'userId' },
  { labelKey: 'User Name', value: 'username' },
  { labelKey: 'Email', value: 'email' },
  { labelKey: 'Player Rating', value: 'playerRating' },
  { labelKey: 'Tier Level', value: 'loyaltyTier' },
  { labelKey: 'Final VIP Status', value: 'finalVipStatus' },
  { labelKey: 'Managed By', value: 'managedBy' },
];

export const vipStatusOptions = [
  { label: 'Approved VIP', value: 'approved' },
  { label: 'Revoked VIP', value: 'rejected' },
];

export const daysOptions = [
  { label: '7 Days', value: 7 },
  { label: '14 Days', value: 14 },
  { label: '21 Days', value: 21 },
  { label: '30 Days', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 },
  { label: 'Custom Range', value: 'custom' },
];

export const tierOptions = [
  { label: 'Tier Level', value: '' },
  { label: 'Empire', value: 'Empire' },
  { label: 'Reserve', value: 'Reserve' },
  { label: 'Forge', value: 'Forge' },
  { label: 'Valut', value: 'Valut' },
  { label: 'Mint', value: 'Mint' },
  { label: 'Nexus', value: 'Nexus' },
];
