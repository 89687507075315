import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Table, Button, Col, Form } from '@themesberg/react-bootstrap';
import useVipPlayerListing from '../hooks/useVipPlayerListing';
import { InlineLoader } from '../../../components/Preloader';
import PaginationComponent from '../../../components/Pagination';
import { customerHeaders } from '../constants';
import { AdminRoutes } from '../../../routes';
import LoyaltyTierChange from './LoyaltyTierChange';
import SearchBar from './SearchBar';
import './_vip.scss';

const CustomerManagement = () => {
  const {
    vipPlayerListing,
    isLoading,
    setLimit,
    setPage,
    page,
    totalPages,
    limit,
    updateVipStatus,
    tierUpdateModal,
    setTierUpdateModal,
    vipLoyalTierData,
    isVipLoyaltyTierDataLoading,
    handleUpdateTier,
    setSearch,
    search,
    userTier,
    setUserTier,
    userId,
    setUserId,
  } = useVipPlayerListing();

  const navigate = useNavigate();

  const handleSelect = (event, userId) => {
    const eventValue = event.target.value;
    const payload = {
      userId,
      vipStatus: eventValue,
      comment: null,
    };
    updateVipStatus(payload);
  };

  const handleNavigatePlayerDetails = (userId) => {
    navigate(`${AdminRoutes.VipPlayerDetails.split(':').shift()}${userId}`);
  };
  return (
    <>
      <Row className='d-flex justify-content-between align-items-center'>
        <Col sm={6} lg={3}>
          <h3>Customer Management</h3>
        </Col>
        <Col sm={6} lg={2}>
          <SearchBar search={search} setSearch={setSearch} />
        </Col>
      </Row>
      <Table bordered striped responsive hover size='sm' className='text-center mt-3'>
        <thead className='thead-dark'>
          <tr style={{ boxShadow: '0 4px 4px 0 #797979', borderRadius: '7px' }}>
            {customerHeaders.map((header, index) => (
              <th key={header.value}>{header.labelKey}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={10} className='text-center'>
                <InlineLoader />
              </td>
            </tr>
          ) : vipPlayerListing && vipPlayerListing?.users?.rows.length > 0 ? (
            vipPlayerListing?.users?.rows.map(({ userId, username, email, UserInternalRating, UserTier }) => {
              return (
                <tr key={userId}>
                  <td
                    className='text-link'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleNavigatePlayerDetails(userId)}
                  >
                    {userId || 'NA'}
                  </td>
                  <td
                    className='text-link'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleNavigatePlayerDetails(userId)}
                  >
                    {' '}
                    {username || 'NA'}
                  </td>
                  <td
                    className='text-link'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleNavigatePlayerDetails(userId)}
                  >
                    {email || 'NA'}
                  </td>
                  <td>{UserInternalRating?.rating}</td>
                  <td>
                    <Button
                      className={`${UserTier?.tierName ? UserTier.tierName.toLowerCase() : 'N/A'}`}
                      variant=''
                      style={{ width: '6rem', padding: '5px 10px' }}
                      onClick={() => {
                        setTierUpdateModal(true);
                        setUserTier(UserTier?.tierName);
                        setUserId(userId);
                      }}
                    >
                      {UserTier?.tierName}
                    </Button>
                  </td>
                  <td>
                    <Form.Select
                      className={`${UserInternalRating?.vipStatus}`}
                      onChange={(e) => handleSelect(e, userId)}
                      value={UserInternalRating?.vipStatus}
                    >
                      <option value='' hidden>
                        Select Status
                      </option>
                      <option value='approved'>Approved VIP</option>
                      <option value='rejected'>Revoked VIP</option>
                    </Form.Select>
                  </td>
                  <td>Not available</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={7} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {vipPlayerListing?.users?.count !== 0 && (
        <PaginationComponent
          page={vipPlayerListing?.users?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      {tierUpdateModal && (
        <LoyaltyTierChange
          setShow={setTierUpdateModal}
          show={tierUpdateModal}
          handleSave={handleUpdateTier}
          isVipLoyaltyTierDataLoading={isVipLoyaltyTierDataLoading}
          tierData={vipLoyalTierData}
          currentTier={userTier}
          userId={userId}
        />
      )}
    </>
  );
};
export default CustomerManagement;
