import React from 'react';
import { Modal, Button, Form } from '@themesberg/react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import Datetime from 'react-datetime';
import { convertToUTC, formatDateYMD, getDateTime } from '../../../utils/dateFormatter';
import { serialize } from 'object-to-formdata';
import loyaltyTierSchema from '../schema/loyaltyTierSchema';

const LoyaltyTierChange = ({
  show,
  setShow,
  handleSave,
  isVipLoyaltyTierDataLoading,
  tierData,
  currentTier,
  userId,
}) => {
  const currentTierId = tierData?.tiers?.rows.find((tier) => tier.name === currentTier)?.tierId;

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Tier</Modal.Title>
      </Modal.Header>

      {!isVipLoyaltyTierDataLoading && (
        <Modal.Body>
          <Formik
            initialValues={{
              expiryDate: null,
              reason: '',
              tierId: '',
            }}
            validationSchema={loyaltyTierSchema(currentTierId, currentTier)}
            onSubmit={(formValues) => {
              const data = {
                expiryDate: formValues?.expiryDate ? formatDateYMD(convertToUTC(formValues?.expiryDate)) : null,
                tierId: formValues?.tierId,
                reason: formValues?.reason,
                userId,
              };

              handleSave(serialize(data));
            }}
          >
            {({ values, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
              <>
                <Form>
                  <Form.Group className='mt-3'>
                    <Form.Select
                      name='tierId'
                      value={values?.tierId || tierData?.tiers?.rows.find((tier) => tier.name === currentTier)?.tierId}
                      onChange={handleChange}
                    >
                      {tierData?.tiers?.rows.map(({ name, tierId }) => (
                        <option key={tierId} value={tierId}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <ErrorMessage component='div' name='tierId' className='text-danger' />
                  <Datetime
                    inputProps={{
                      placeholder: 'Exipry Date',
                    }}
                    dateFormat='MM-DD-YYYY'
                    value={values.expiryDate ? getDateTime(values?.expiryDate) : ''}
                    onChange={(e) => setFieldValue('expiryDate', e)}
                    timeFormat={false}
                    className='mt-3'
                  />
                  <ErrorMessage component='div' name='expiryDate' className='text-danger' />
                  <Form.Control
                    type='text'
                    name='reason'
                    placeholder='Reason'
                    className='mt-3'
                    value={values?.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage component='div' name='reason' className='text-danger' />
                </Form>
                <Button className='mt-3 ms-auto d-block ' onClick={handleSubmit}>
                  Save
                </Button>
              </>
            )}
          </Formik>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default LoyaltyTierChange;
