import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";
import { getAllNotifications } from "../../../utils/apiCalls";
import { useMarkNotificationReadMutation } from "../../../reactQuery/hooks/customMutationHook";
import { toast } from '../../../components/Toast'

const useNotifications = () => {
  const queryClient = useQueryClient();
  const [showNotificationsTab, setShowNotificationsTab] = useState(false);
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const [isUnread, setIsUnread] = useState(false);
  const [count, setCount] = useState(0);
  const [showNotificationDetails, setShowNotificationDetails] = useState(false);

  const handleShowNotifications = () => {
    setShowNotificationsTab(!showNotificationsTab);
  };

  const { isLoading: loading, data: notificationsData } = useQuery({
    queryKey: ["notificationsList", limit, page, debouncedSearch, isUnread],
    queryFn: ({ queryKey }) => {
      const params = {pageNo: queryKey[2], limit: queryKey[1]};
      if (queryKey[3]) params.contentSearch = queryKey[3];
      if (queryKey[4]) params.isUnread = queryKey[4];
      return getAllNotifications(params);
    },
  });

  const { mutate: updateNotifications, isLoading: updateloading } =
    useMarkNotificationReadMutation({
      onSuccess: ({ data }) => {
        // toast(data.message, "success");
        queryClient.invalidateQueries({
          queryKey: ["notificationsList"],
        });
      },
      onError: (error) => {
        errorHandler(error);
      },
    });

  const handleSetUnread = () => {
    setIsUnread(!isUnread);
  };

  const markReadNotifications = (id) => {
    updateNotifications(id);
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  }

  useEffect(() => {
    setCount(notificationsData?.data?.notifications?.unreadCount);
  }, [notificationsData]);


  return {
    notificationsData,
    loading,
    count,
    setCount,
    search,
    setSearch,
    isUnread,
    setIsUnread,
    showNotificationsTab,
    setShowNotificationsTab,
    handleShowNotifications,
    handleSetUnread,
    markReadNotifications,
    showNotificationDetails,
    setShowNotificationDetails,
    handleLoadMore
  };
};

export default useNotifications;
