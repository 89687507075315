/*
Filename: Players/index.js
Description: View List of all users.
Author: uchouhan
Created at: 2023/03/03
Last Modified: 2023/03/30
Version: 0.1.0
*/
import React, { useState } from 'react'
import { Row, Col, Card, Form, Button, Table, Tabs, Tab } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faCheckSquare, faWindowClose, faArrowCircleUp, faArrowCircleDown, faPlus } from '@fortawesome/free-solid-svg-icons'
import usePlayerListing from './usePlayerListing'
import Skeleton from 'react-loading-skeleton'
import PaginationComponent from '../../components/Pagination'
import { kycStatusOption } from '../PlayerDetails/constants'
import { AdminRoutes } from '../../routes'
import { tableHeaders } from './constants'
import { InlineLoader } from '../../components/Preloader'
import Trigger from '../../components/OverlayTrigger'
import useCheckPermission from '../../utils/checkPermission'
import { PlayerStatusConfirmationModal } from '../../components/ConfirmationModal'
import PlayerSearch from './PlayerSearch'
import { formatDateMDY } from '../../utils/dateFormatter'
import { PlayerStatusModal } from './Components/PlayerStatusModal'

const Players = () => {
  const { t, navigate, selected, loading, sort, setStatusShow, statusShow, handleYes, status, playerStatusDetail,
    setSort, over, setOver, playersData, search, setSearch, totalPages, page, setPage, limit, setLimit, setKycOptions, setOrderBy, handleStatusShow,
    globalSearch,
    setGlobalSearch,
    orderBy,
    getCsvDownloadUrl,
    playerId,
    playerDetail, updateloading
  } = usePlayerListing();

  const { isHidden } = useCheckPermission()
  const [selectedTab, setSelectedTab] = useState('playerSearch')

  const handlePlayerTableSorting = (param) => {
    if (param.value === orderBy) {
      setSort(sort === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(param.value)
      setSort('asc');
    }
  }

  return (
    <>
      <Card className='p-2 mb-2'>
        <Row>
          <Col>
            <h3>{t('title')}</h3>
          </Col>
        </Row>
        <PlayerSearch
          globalSearch={globalSearch}
          setGlobalSearch={setGlobalSearch}
          getCsvDownloadUrl={getCsvDownloadUrl}
          playersData={playersData}
        />
        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  onClick={() => h.value !== '' && handlePlayerTableSorting(h)}
                  style={{
                    cursor: 'pointer'
                  }}
                  className={
                    selected(h)
                      ? 'border-3 border border-blue'
                      : ''
                  }
                >
                  {t(h.labelKey)}{' '}
                  {selected(h) &&
                    (sort === 'asc'
                      ? (
                        <FontAwesomeIcon
                          style={over ? { color: 'red' } : {}}
                          icon={faArrowCircleUp}
                          onClick={() => setSort('desc')}
                          onMouseOver={() => setOver(true)}
                          onMouseLeave={() => setOver(false)}
                        />
                      )
                      : (
                        <FontAwesomeIcon
                          style={over ? { color: 'red' } : {}}
                          icon={faArrowCircleDown}
                          onClick={() => setSort('asc')}
                          onMouseOver={() => setOver(true)}
                          onMouseLeave={() => setOver(false)}
                        />
                      ))}
                </th>
              ))}
            </tr>
          </thead>

          {loading ? (
            <tr>
              <td colSpan={10} className="text-center">
                <InlineLoader />
              </td>
            </tr>
          ) : (
            <tbody>
              {playersData && playersData.rows.length > 0 ? (
                playersData?.rows.map((player) => {
                  return (
                    <tr key={player.userId}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        const contextMenu = document.getElementById(`contextMenu-${player.userId}`);
                        contextMenu.style.top = `${e.clientY}px`;
                        contextMenu.style.left = `${e.clientX}px`;
                        contextMenu.style.display = 'block';
                      }}>
                      <td>{player.userId}</td>
                      <td>{player.email}</td>
                      <td>{player.createdAt ? formatDateMDY(player.createdAt) : 'NA'}</td>
                      <td>{player.username || 'NA'}</td>
                      <td className='text-link' style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate(
                            `${AdminRoutes.PlayerDetails.split(':').shift()}${player.userId}`
                          )
                        }}>
                        {(player.firstName && player.lastName) ? `${player.firstName} ${player.lastName}` : 'NA'}
                      </td>
                      <td>{player.userWallet.scBalance}</td>
                      <td>{player.UserReport.totalPurchaseAmount}</td>
                      <td>{player.UserReport.totalRedemptionAmount}</td>
                      <td>{player.lastLoginDate ? formatDateMDY(player.lastLoginDate) : 'NA'}</td>
                      <td>{player.UserTier.tierName || 'NA'}</td>
                      <td>
                        {player.status === 'Active' ? <span className='text-success'>{player.status}</span> : <span className='text-danger'>{player.status}</span>}
                      </td>
                      {/* <td>{player.kycStatus}</td> */}
                      <td>
                        <>
                          <Trigger message='View' id={player.userId + 'view'} />
                          <Button
                            id={player.userId + 'view'}
                            className='m-1'
                            size='sm'
                            variant='info'
                            onClick={() => {
                              // const playerDetailsUrl = `${AdminRoutes.PlayerDetails.split(':').shift()}${player.userId}`;
                              // window.open(playerDetailsUrl, '_blank');
                              navigate(
                                `${AdminRoutes.PlayerDetails.split(':').shift()}${player.userId}`
                              )
                            }}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                          <div
                            id={`contextMenu-${player.userId}`}
                            style={{
                              position: 'fixed',
                              display: 'none',
                              backgroundColor: 'white',
                              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                              borderRadius: '5px',
                              padding: '5px',
                              zIndex: '9999',
                            }}
                          >
                            <div
                              onClick={() => {
                                window.open(
                                  `${AdminRoutes.PlayerDetails.split(':').shift()}${player.userId}`,
                                  '_blank'
                                );
                                document.getElementById(`contextMenu-${player.userId}`).style.display = 'none';
                              }}
                              style={{
                                cursor: 'pointer',
                                padding: '5px',
                              }}
                            >
                              Open in new tab
                            </div>
                          </div>
                          {/* Code to set Player as Active/Inactive   */}
                          {player.status !== "Active"
                            ? (
                              <>
                                <Trigger message='Set Status' id={player.userId + 'active'} />
                                <Button
                                  id={player.userId + 'active'}
                                  className='m-1'
                                  size='sm'
                                  variant='success'
                                  onClick={() =>
                                    handleStatusShow(
                                      player.userId,
                                      player.status,
                                      player?.statusDetails,
                                      player
                                    )}
                                  hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
                                >
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </Button>
                              </>) : (
                              <>
                                <Trigger message='Set Status' id={player.userId + 'inactive'} />
                                <Button
                                  id={player.userId + 'inactive'}
                                  className='m-1'
                                  size='sm'
                                  variant='danger'
                                  onClick={() =>
                                    handleStatusShow(
                                      player.userId,
                                      player.status,
                                      player?.statusDetails,
                                      player
                                    )}
                                  hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
                                >
                                  <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                              </>
                            )}
                        </>
                      </td>
                    </tr>
                  )
                }
                )
              ) : (
                <tr>
                  <td colSpan={6} className='text-danger text-center'>
                    {t('noDataFound')}
                  </td>
                </tr>
              )
              }
            </tbody>
          )}
        </Table>
        {playersData?.rows?.length !== 0 && (
          <PaginationComponent
            page={playersData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}
      </Card>
      {statusShow && <PlayerStatusModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        status={status}
        playerId={playerId}
        loading={updateloading}
      />}
      {/* <PlayerStatusConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        playerStatusDetail={playerStatusDetail}
      /> */}
    </>
  )
}
export default Players