import { useEffect, useState } from 'react'
import { getDateDaysAgo } from '../../../utils/dateFormatter'
import { getItem } from '../../../utils/storageUtils'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { getAmoeData } from '../../../utils/apiCalls'
import { useDebounce } from 'use-debounce'
import { timeZones } from '../../Dashboard/constants'
import { appendCurrentTime, convertTimeZone, convertToUtc, getFormattedTimeZoneOffset } from '../../../utils/helper'
import moment from 'moment'

const useAmoeList = () => {

  const { t } = useTranslation('players')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [username, setUsername] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  const [debouncedUsername] = useDebounce(username, 500)
  const [debouncedPostalCode] = useDebounce(postalCode, 500)
  const [statusFilter, setStatusFilter] = useState('all')

  const timeZone = getItem("timezone");
  const timezoneOffset = timeZone != null ? timeZones.find(x => x.code === timeZone).value : getFormattedTimeZoneOffset()
  const [timeZoneCode, setTimeZoneCode] = useState(timeZones.find(x => x.value === timezoneOffset)?.code);
  const [startDate, setStartDate] = useState(convertTimeZone(getDateDaysAgo(10), timeZoneCode));
  const [endDate, setEndDate] = useState(convertTimeZone(moment().endOf('day'), timeZoneCode));

  useEffect(() => {
    setTimeZoneCode(timeZones.find(x => x.value === timezoneOffset)?.code)
  }, [timezoneOffset])

  const resetFilters = () => {
    setSearch("");
    setUsername("");
    setPostalCode("");
    setStatusFilter("all"); 
    setLimit(15);
    setPage(1);
    setStartDate(convertTimeZone(getDateDaysAgo(10), timeZoneCode));
    setEndDate(convertTimeZone(moment().endOf('day'), timeZoneCode));
  };

  useEffect(() => {
    setStartDate(convertTimeZone(getDateDaysAgo(10), timeZoneCode));
    setEndDate(convertTimeZone(moment().endOf('day'), timeZoneCode));
  }, [timeZoneCode]);


  const { data: AmoeData = [], isLoading: loading, refetch: AmoeRefetch } = useQuery({
    queryKey: ['AmoeList', limit, page, debouncedSearch, debouncedUsername, debouncedPostalCode, statusFilter, convertToUtc(appendCurrentTime(startDate)), convertToUtc(appendCurrentTime(endDate)), timeZone],
    queryFn: ({ queryKey }) => {
      const params = {
        pageNo: queryKey[2],
        limit: queryKey[1]
      };
      if (queryKey[3]) params.email = queryKey[3]
      if (queryKey[4]) params.username = queryKey[4]
      if (queryKey[5]) params.postalCode = queryKey[5]
      if (queryKey[6]) params.status = queryKey[6]
      if (queryKey[7]) params.startDate = queryKey[7]
      if (queryKey[8]) params.endDate = queryKey[8]

      return getAmoeData(params)
    },
    refetchOnWindowFocus: false,
    select: (res) => res?.data
  })

  const totalPages = Math.ceil(AmoeData?.count / limit)

  return {
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    t,
    AmoeData,
    loading,
    startDate,
    setStartDate: (date) => {
      setStartDate(moment(date).format('YYYY-MM-DD'))
    },
    endDate,
    setEndDate: (date) => setEndDate(moment(date).endOf('day').format('YYYY-MM-DD')),
    setSearch,
    AmoeRefetch,
    username,
    setUsername,
    statusFilter,
    setStatusFilter,
    postalCode,
    setPostalCode,
    resetFilters
  }
}

export default useAmoeList