// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.limit h6 {
  align-items: center; }

.limit div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px; }

.limit div div {
  display: flex; }

.limit div div span {
  margin-right: 10px; }

.limit div div button {
  margin-right: 10px; }
`, "",{"version":3,"sources":["webpack://./src/components/ResponsibleGaming/style.scss"],"names":[],"mappings":"AAAA;EACG,mBAAmB,EAAA;;AAGtB;EACI,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB,EAAA;;AAGtB;EACI,aAAa,EAAA;;AAGjB;EACI,kBAAkB,EAAA;;AAGtB;EACI,kBAAkB,EAAA","sourcesContent":[".limit h6 {\n   align-items: center;\n}\n\n.limit div {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 4px;\n}\n\n.limit div div{\n    display: flex;\n}\n\n.limit div div span{\n    margin-right: 10px;\n}\n\n.limit div div button{\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
