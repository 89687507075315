import React, { useState } from "react";
import { Row, Col, Button, Tabs, Tab, Nav } from "@themesberg/react-bootstrap";
import { PlayersTabInfo } from "../constants";
import ResponsibleGaming from "../../../components/ResponsibleGaming";
import EditInfo from "./EditInfo";
import AuditTable from "./AuditTable";
import LogsTable from "./LogsTable";
import LexisNexisData from "./LexisNexisData";
import RSGData from "./RSGData";
import Settings from "./Settings";
import BankDetails from "./BankDetails";
import CommsTable from "./CommsTable";
import ActivityTable from "./Activity";
import VerificationDetails from "./Verification";
import { EditPlayerFieldContainer, PlayerTabContainer } from "../style";
import EditPlayer from "./EditPlayer";

const PlayerTab = (props) => {
  const {
    basicInfo,
    getUserDetails,
    userData,
    userDocuments,
    handleVerify,
    updateDocument,
    show,
    setShow,
    handleYes,
    handleClose,
    showReasonModal,
    status,
    enable,
    setEnable,
    docLabels,
    handleReRequest,
    title,
    imagePreviewModalShow,
    setImagePreviewModalShow,
    handleImagePreview,
    imageUrl,
    setImageUrl,
    userLimits,
    handelRefetchActivity,
    docStatus,
    setDocStatus,
    currentSelectedTab,
    setCurrentSelectedTab
  } = props;
  const [selectedInnerButton, setSelectedInnerButton] = useState({});
  const [openEditInfoModal, setOpenEditInfoModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showKYCModal, setShowKYCModal] = useState(false);
  const [showKYCHistory, setShowKYCHistory] = useState(false);

  const parentTabToggler = (itemValue) => {
    setCurrentSelectedTab(itemValue);
  };
  const handleCloseDocsModal = () => setShowModal(false);
  const handleCloseKYCHistory = () => setShowKYCHistory(false);
  const handleCloseKYCModal = () => setShowKYCModal(false);


  const setSelectedInnerToggler = (dataValue) => {
    switch (dataValue.innerItem) {
      case "isBan":
      case "isRestrict":
      case "phoneVerified":
      case "isRedemptionSubscribed":
      case "isSubscribed":
      case "isInternalUser":
      case "addDeductCoinsChild":
      case "forceLogoutChild":
      case "passwordChild":
      case "removePwLock":
      case "isUserVerified":
      case "socialSecurityChild":
        setOpenEditInfoModal(true);
        break;
      default:
        break;
    }
    setSelectedInnerButton(dataValue);
  };
  return (
    <PlayerTabContainer>
     
        <ActivityTable basicInfo={basicInfo} />
      
    </PlayerTabContainer>
  );
};

export default PlayerTab;
