import { useQuery } from '@tanstack/react-query';
import { getVipUserAnswers, getVipUserQuestions } from '../../../utils/apiCalls';
import { useUpdateVipUserAnswers, useUpdateVipUserQuestionnaire } from '../../../reactQuery/hooks/customMutationHook';
import { toast } from '../../../components/Toast';
import { useParams } from 'react-router-dom';

const useVipQuestions = (accordionOpen) => {
  const { userId } = useParams();
  const {
    data: vipQuestionsList,
    isLoading: isVipQuestionsLoading,
    refetch: refetchVipUserQuestions,
  } = useQuery({
    queryKey: ['vipQuestionsList'],
    queryFn: () => {
      return getVipUserQuestions();
    },
    select: (res) => res?.data?.questions,
    enabled: accordionOpen,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: false,
  });

  const {
    data: vipAnswersList,
    isLoading: isVipAnswerLoading,
    refetch: refetchVipUserAnswers,
  } = useQuery({
    queryKey: ['vipAnswersList', userId],
    queryFn: ({ queryKey }) => {
      const params = {};
      if (queryKey[1]) params.userId = queryKey[1];
      return getVipUserAnswers(params);
    },
    select: (res) => res?.data?.answers,
    enabled: accordionOpen,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  // const { mutate: updateVipUserQuestions, isLoading: isVipQuestionUpdating } = useUpdateVipUserQuestionnaire({
  //   onSuccess: (res) => {
  //     toast(res?.data?.message, 'success');
  //     queryClient.invalidateQueries({
  //       queryKey: ['vipQuestionsList'],
  //     });
  //     refetchVipUserQuestions();
  //   },
  //   onError: (err) => {
  //     const error = err?.response?.data?.errors?.[0];
  //     console.log(error);
  //     toast(error?.description, 'error');
  //   },
  // });

  const { mutate: updateVipUserAnswers, isLoading: isVipAnswerUpdating } = useUpdateVipUserAnswers({
    onSuccess: (res) => {
      toast(res?.data?.message, 'success');
      refetchVipUserAnswers();
    },
    onError: (err) => {
      const error = err?.response?.data?.errors?.[0];
      console.log(error);
      toast(error?.description, 'error');
    },
  });

  return {
    vipQuestionsList,
    isVipQuestionsLoading,
    // updateVipUserQuestions,
    // isVipQuestionUpdating,
    vipAnswersList,
    isVipAnswerLoading,
    updateVipUserAnswers,
    isVipAnswerUpdating,
    userId,
  };
};

export default useVipQuestions;
