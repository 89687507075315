import React, { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./navbar.scss";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Badge,
  Form,
  Col,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faUserAlt,
  faSignOutAlt,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useUserStore } from "../../store/store";
import Notifications from "../Notifications/Notifications";
import Popup from "./Popup";
import { toast } from "../Toast";
import { AdminRoutes, AffiliateRoute } from "../../routes";

import { useLogoutUser } from "../../reactQuery/hooks/customMutationHook";
import { getItem, removeLoginToken, setItem } from "../../utils/storageUtils";
import useNotifications from "../../pages/NotificationCenter/hooks/useNotifications";
import { getFormattedTimeZoneOffset } from "../../utils/helper";
import { timeZones } from "../../pages/Dashboard/constants";

const Navbar = ({ open, setOpen }) => {
  const { t } = useTranslation(["sidebar"]);
  const navigate = useNavigate();
  const isUserAffiliate = useUserStore((state) => state.isUserAffiliate);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const notificationRef = useRef(null);
  const currentTimeZone = getItem("timezone");
  const currentTimezoneOffset = timeZones?.find(
    (x) => x.code === currentTimeZone
  )?.value;
  const timeZoneOffset = getFormattedTimeZoneOffset();
  const [timeStamp, setTimeStamp] = useState(
    currentTimezoneOffset ? currentTimezoneOffset : timeZoneOffset
  );

  const { count } = useNotifications({showNotification: false});
  const { timeZoneCode, setTimeZoneCode } = useUserStore((state) => state);

  useEffect(() => {
    setTimeZoneCode(timeZones.find((x) => x.value === timeStamp)?.code);
    setItem("timezone", timeZones.find((x) => x.value === timeStamp)?.code);
  }, [timeStamp]);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const logoutUser = () => {
    removeLoginToken();
    localStorage.clear();
    toast(t("logoutSuccessToast"), "success", "logoutToast");
    navigate(
      isUserAffiliate ? AffiliateRoute.AffiliateSignIn : AdminRoutes.AdminSignin
    );
  };

  const { mutate: logout } = useLogoutUser({ onSuccess: () => logoutUser() });

  return (
    <>
      <div className="app-navbar d-flex justify-content-end align-items-center">
        <OverlayTrigger
          key="menu"
          placement="bottom"
          overlay={
            <Tooltip id={`tooltip-profile`}>
              {!open ? <strong>Open Menu</strong> : <strong>Close Menu</strong>}
            </Tooltip>
          }
        >
          <Button
            onClick={() => setOpen((current) => !current)}
            className="btn menu-btn"
          >
            {!open ? (
              <FontAwesomeIcon
                icon={faBars}
                className="me-1"
                style={{ color: "rgb(38,43,64)" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faXmark}
                className="me-1"
                style={{ color: "rgb(38,43,64)" }}
              />
            )}
          </Button>
        </OverlayTrigger>

        <div className="notification-popup">
          <OverlayTrigger
            key="notifications"
            placement="bottom"
            overlay={
              <Tooltip id={`tooltip-notifications`}>
                <strong>Notifications</strong>
              </Tooltip>
            }
          >
            <Button
              ref={notificationRef}
              onClick={togglePopover}
              className="btn notification-btn"
            >
              <FontAwesomeIcon
                icon={faBell}
                className="me-1"
                style={{ color: "rgb(38,43,64)" }}
              />
              <Badge bg="secondary" className="translate-middle rounded-pill">
                {count}
              </Badge>
            </Button>
          </OverlayTrigger>

          <Notifications isOpen={popoverOpen}
            onClose={() => setPopoverOpen(false)}
            title="Notifications"
            targetElement={notificationRef.current} />

        </div>
        <OverlayTrigger
          key="profile"
          placement="bottom"
          overlay={
            <Tooltip id={`tooltip-profile`}>
              <strong>Profile</strong>
            </Tooltip>
          }
        >
          <Button
            onClick={() => navigate(AdminRoutes.Profile)}
            className="btn profile-btn"
          >
            <FontAwesomeIcon
              icon={faUserAlt}
              className="me-1"
              style={{ color: "rgb(38,43,64)" }}
            />
          </Button>
        </OverlayTrigger>
        <Form.Select
          className="w-auto timezone-select"
          // style={{ maxWidth: "250px" }}
          value={timeStamp}
          onChange={(event) => {
            setTimeStamp(event.target.value);
          }}
        >
          {timeZones?.map(({ labelKey, value, code }) => {
            return (
              <option key={value} value={value}>
                {t(labelKey)} ({code}) {value}
              </option>
            );
          })}
        </Form.Select>
        <OverlayTrigger
          key="logout"
          placement="bottom"
          overlay={
            <Tooltip id={`tooltip-logout`}>
              <strong>Logout</strong>
            </Tooltip>
          }
        >
          <Button onClick={() => logout()} className="btn navbar-logout-btn">
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="me-1"
              style={{ color: "rgb(38,43,64)" }}
            />
          </Button>
        </OverlayTrigger>
      </div>
    </>
  );
};

export default Navbar;