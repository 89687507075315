// VIPTournamentForm.js
import React, { useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query'

import {
    Col,
    Row,
    Form as BForm,
    Button,
    Spinner
} from "@themesberg/react-bootstrap";
import { ErrorMessage } from "formik";
import AddVIPTournament from './AddVIPTournament';
import ImportCsvTournamentModal from './ImportCsvTournamentModal';
import { errorHandler, useUploadCsvTournamentMutation } from '../../../../../reactQuery/hooks/customMutationHook';
import { toast } from '../../../../../components/Toast';



const VIPTournamentForm = ({
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    type,
    tournamentData,
    details,
    refetchTournament,
    loading
}) => {
    const queryClient = useQueryClient();

    // State for Import CSV
    const [importedFile, setImportedFile] = useState(null);
    const [importModalShow, setImportModalShow] = useState(false);

    const fileTournamentInputRef = useRef(null);

    const handleImportChange = (e) => {
        let file = e.target.files[0]
        setImportedFile(e.target.files[0]);
        if (file) {
            setImportModalShow(true)
        }
        // Reset the input value to allow re-selection
        e.target.value = null;
    }

    const handleImportClick = () => {
        fileTournamentInputRef.current.click();
    }

    // Import CSV
    const { data: csvData, mutate: uploadCSV, isLoading: uploadCSVLoading } =
        useUploadCsvTournamentMutation({
            onSuccess: ({ data }) => {
                toast(data?.message, "success");
                queryClient.invalidateQueries({
                    queryKey: ["tournamentId"],
                });
                setImportModalShow(false);
            },
            onError: (error) => {
                errorHandler(error);
                setImportModalShow(false);
            },
        });

    const handleCSVSumbit = () => {
        let formData = new FormData();
        formData.append("file", importedFile);
        formData.append("vipTournament", true); // Add the vipTournament flag to the formData

        uploadCSV(formData, { vipTournament: true });
    };

    return (
        <Row className="mt-0">
            {/* VIP Tournament Toggle */}
            <Col md={6} sm={6} className="d-flex mt-3">
                <BForm.Label>
                    {"Enable VIP Tournament"}
                </BForm.Label>

                <BForm.Check
                    type="switch"
                    name="vipTournament"
                    className="ms-6"
                    checked={values?.vipTournament}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={details}
                />

            </Col>

            <Col sm={12} md={6} className="d-flex flex-column align-items-end">
                {!details &&
                    (
                        <>
                            <Button
                                variant='secondary'
                                style={{ marginLeft: '10px' }}
                                onClick={handleImportClick}
                                type="button"
                                id={"csvFileInput"}
                                disabled={!values?.vipTournament} // Disable if VIP is not enabled
                            >
                                Import CSV
                            </Button>

                            {/* Display message if VIP Tournament is not enabled */}
                            {!values?.vipTournament && (
                                <div className="mt-2 text-warning">
                                    {"You need to enable the VIP Tournament before importing CSV."}
                                </div>
                            )}
                            <input
                                type="file"
                                accept=".csv"
                                ref={fileTournamentInputRef}
                                onChange={handleImportChange}
                                style={{ display: "none" }} // Hide the file input
                            />
                        </>
                    )}
            </Col>

            {importModalShow && (
                <ImportCsvTournamentModal
                    setShow={setImportModalShow}
                    show={importModalShow}
                    handleYes={handleCSVSumbit}
                    loading={uploadCSVLoading}
                    importedFile={importedFile}
                />
            )}

            {/* Show AddVIPTournament component when VIP is enabled */}
            {values?.vipTournament && (
                <Row className="mt-0">
                    <AddVIPTournament
                        tournamentData={tournamentData}
                        isViewMode={details}
                        selectedUser={values?.allowedUsers}
                        setSelectedUser={(selectedUser) => {
                            setFieldValue("allowedUsers", selectedUser || []);
                        }}
                        type={type}
                        disabled={details}
                        setFieldValue={setFieldValue}
                        csvData={csvData}
                    />
                </Row>
            )}
            <ErrorMessage
                component="div"
                name="allowedUsers"
                className="text-danger"
            />
        </Row>
    );
};

export default VIPTournamentForm;
