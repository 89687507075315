// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 100px;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
  }
  
  .tooltip-text::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background-color: #192733;
    padding: 5px;
    z-index: 1;
  }
  
  .hover-text:hover .tooltip-text {
    visibility: visible;
  }
  
  #top {
    top: -40px;
    left: -50%;
  }
  
  #top::before {
    top: 80%;
    left: 45%;
  }
  
  #bottom {
    top: 35px;
    left: -50%;
    transform: translate(-9%, 8%);
  }
  
  #bottom::before {
    top: -5%;
    left: 45%;
  }
  
  #left {
    top: -8px;
    right: 120%;
  }
  
  #left::before {
    top: 35%;
    left: 94%;
  }
  
  #right {
    top: -8px;
    left: 120%;
  }
  
  #right::before {
    top: 35%;
    left: -2%;
  }
  
  .hover-text {
    position: relative;
    display: inline-block;
    /* margin: 40px; */
    font-family: Arial;
    text-align: center;
  }
  .react-tooltip{
    z-index: 999 !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/OverlayTrigger/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,mBAAmB;IACnB,4BAA4B;EAC9B;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,wBAAwB;IACxB,yBAAyB;IACzB,YAAY;IACZ,UAAU;EACZ;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,UAAU;EACZ;;EAEA;IACE,QAAQ;IACR,SAAS;EACX;;EAEA;IACE,SAAS;IACT,UAAU;IACV,6BAA6B;EAC/B;;EAEA;IACE,QAAQ;IACR,SAAS;EACX;;EAEA;IACE,SAAS;IACT,WAAW;EACb;;EAEA;IACE,QAAQ;IACR,SAAS;EACX;;EAEA;IACE,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,QAAQ;IACR,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;EACpB;EACA;IACE,uBAAuB;EACzB","sourcesContent":[".tooltip-text {\n    visibility: hidden;\n    position: absolute;\n    z-index: 2;\n    width: 100px;\n    color: white;\n    font-size: 12px;\n    background-color: #192733;\n    border-radius: 10px;\n    padding: 10px 15px 10px 15px;\n  }\n  \n  .tooltip-text::before {\n    content: \"\";\n    position: absolute;\n    transform: rotate(45deg);\n    background-color: #192733;\n    padding: 5px;\n    z-index: 1;\n  }\n  \n  .hover-text:hover .tooltip-text {\n    visibility: visible;\n  }\n  \n  #top {\n    top: -40px;\n    left: -50%;\n  }\n  \n  #top::before {\n    top: 80%;\n    left: 45%;\n  }\n  \n  #bottom {\n    top: 35px;\n    left: -50%;\n    transform: translate(-9%, 8%);\n  }\n  \n  #bottom::before {\n    top: -5%;\n    left: 45%;\n  }\n  \n  #left {\n    top: -8px;\n    right: 120%;\n  }\n  \n  #left::before {\n    top: 35%;\n    left: 94%;\n  }\n  \n  #right {\n    top: -8px;\n    left: 120%;\n  }\n  \n  #right::before {\n    top: 35%;\n    left: -2%;\n  }\n  \n  .hover-text {\n    position: relative;\n    display: inline-block;\n    /* margin: 40px; */\n    font-family: Arial;\n    text-align: center;\n  }\n  .react-tooltip{\n    z-index: 999 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
