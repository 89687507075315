import { faCommentAlt, faImage, faListAlt, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faChartPie,
  faFileExport,
  faGamepad,
  faMoneyCheckDollar,
  faDollarSign,
  faVault,
  faList12,
  faPeopleGroup,
  faPhotoVideo,
  faShieldAlt,
  faShippingFast,
  faUserAlt,
  faUsers,
  faAward,
  faCreditCard,
  faMoneyBillWave,
  faBell,
  faPager,
  faGift,
  faEnvelope,
  faListCheck,
  faListUl,
  faPersonCircleCheck,
  faHourglassHalf,
  faUserPlus,
  faUsersGear,
} from '@fortawesome/free-solid-svg-icons';
import { AdminRoutes, AffiliateRoute } from '../routes';

export const navItems = [
  {
    titleKey: 'dashboard',
    link: AdminRoutes.Dashboard,
    icon: faChartPie,
    permissionLabel: 'Report',
    inSidePermissionLabel: 'DR',
  },

  {
    titleKey: 'staff',
    link: AdminRoutes.Staff,
    icon: faPeopleGroup,
    permissionLabel: 'Admins',
  },
  {
    titleKey: 'Admin Added Coins',
    link: AdminRoutes.StaffCreditDetails,
    icon: faVault,
    permissionLabel: 'AdminAddedCoins',
  },

  {
    titleKey: 'players',
    link: AdminRoutes.Players,
    icon: faUsers,
    permissionLabel: 'Users',
  },
  {
    titleKey: 'casinoManagement',
    path: 'casino-management',
    icon: faListAlt,
    permissionLabel: 'CasinoManagement',
    isCollapsable: true,
    options: [
      {
        titleKey: 'Aggregators',
        link: AdminRoutes.Aggregators,
        icon: faUserCircle,
        permissionLabel: 'CasinoManagement',
      },
      {
        titleKey: 'providers',
        link: AdminRoutes.CasinoProviders,
        icon: faUserCircle,
        permissionLabel: 'CasinoManagement',
      },

      {
        titleKey: 'subCategories',
        link: AdminRoutes.CasinoSubCategories,
        icon: faList12,
        permissionLabel: 'CasinoManagement',
      },
      {
        titleKey: 'games',
        link: AdminRoutes.CasinoGames,
        icon: faGamepad,
        permissionLabel: 'CasinoManagement',
      },
    ],
  },
  {
    titleKey: 'bannerManagement',
    link: AdminRoutes.BannerManagement,
    icon: faPhotoVideo,
    permissionLabel: 'Banner',
  },

  {
    titleKey: 'vipManagement',
    path: 'vip-management',
    icon: faListCheck,
    permissionLabel: 'VipManagement',
    isCollapsable: true,
    options: [
      {
        titleKey: 'dashboard',
        link: AdminRoutes.VipDashboard,
        icon: faChartPie,
        permissionLabel: 'VipManagement',
      },
      // {
      //   titleKey: 'vipPlayers',
      //   link: AdminRoutes.VipPlayerDetails,
      //   icon: faUserCircle,
      //   permissionLabel: 'VipManagement',
      // },
      {
        titleKey: 'pendingVip',
        link: AdminRoutes.VipPendingUserLists,
        icon: faHourglassHalf,
        permissionLabel: 'VipManagement',
      },
      {
        titleKey: 'customerManagement',
        link: AdminRoutes.VipCustomerManagement,
        icon: faUsersGear,
        permissionLabel: 'VipManagement',
      },
    ],
  },

  {
    titleKey: 'transactions',
    path: 'transactions',
    icon: faMoneyCheckDollar,
    permissionLabel: 'Transactions',
    isCollapsable: true,
    options: [
      {
        titleKey: 'transactionsBanking',
        link: AdminRoutes.BankingTransactions,
        icon: faDollarSign,
        permissionLabel: 'Transactions',
      },
      {
        titleKey: 'casinoTransactions',
        link: AdminRoutes.CasinoTransactions,
        icon: faCreditCard,
        permissionLabel: 'Transactions',
      },
      {
        titleKey: 'withdrawRequests',
        link: AdminRoutes.WithdrawRequest,
        icon: faMoneyBillWave,
        permissionLabel: 'Transactions',
      },
      {
        titleKey: 'Vault',
        link: AdminRoutes.VaultRequest,
        icon: faVault,
        permissionLabel: 'Transactions',
      },
      {
        titleKey: 'Reedem Rules',
        link: AdminRoutes.RedeemRulelisting,
        icon: faMoneyBillWave,
        permissionLabel: 'Transactions',
      },
    ],
  },
  {
    titleKey: 'packages',
    link: AdminRoutes.Packages,
    icon: faShippingFast,
    permissionLabel: 'Package',
  },
  {
    titleKey: 'Tournaments',
    link: AdminRoutes.Tournament,
    icon: faPager,
    permissionLabel: 'Tournaments',
  },
  {
    titleKey: 'Tiers',
    link: AdminRoutes.Tier,
    icon: faPager,
    permissionLabel: 'Tiers',
  },
  {
    titleKey: 'Giveaways',
    link: AdminRoutes.Raffle,
    icon: faPager,
    permissionLabel: 'Raffles',
  },

  {
    titleKey: 'cms',
    path: 'cms',
    icon: faCommentAlt,
    permissionLabel: 'CMS',
    isCollapsable: true,
    options: [
      {
        titleKey: 'dynamicCms',
        link: AdminRoutes.CmsListing,
        icon: faShieldAlt,
        permissionLabel: 'CMS',
      },
    ],
  },

  {
    titleKey: 'Email Center',
    link: AdminRoutes.EmailCenter,
    icon: faEnvelope,
    permissionLabel: 'emailCenter',
  },
  {
    titleKey: 'bonus',
    link: AdminRoutes.BonusListing,
    icon: faAward,
    permissionLabel: 'Bonus',
  },

  {
    titleKey: 'Affiliate',
    link: AdminRoutes.Affiliate,
    icon: faUsers,
    permissionLabel: 'Affiliates',
  },
  {
    titleKey: 'AMOE Bonus',
    link: AdminRoutes.AmoeRequest,
    icon: faGift,
    permissionLabel: 'Amoe',
  },
  {
    titleKey: 'promoCode',
    path: 'promotion-bonus',
    icon: faListAlt,
    permissionLabel: 'Promocode',
    isCollapsable: true,
    options: [
      {
        titleKey: 'Affiliate Promo Codes',
        link: AdminRoutes.PromotionBonus,
        icon: faImage,
        permissionLabel: 'PromotionBonus',
      },
      {
        titleKey: 'Purchase Promo Codes',
        link: AdminRoutes.PromoCodeBonus,
        icon: faImage,
        permissionLabel: 'Promocode',
      },
    ],
  },
  {
    titleKey: 'CRM Promotion',
    path: 'crm-promotion',
    icon: faMoneyCheckDollar,
    permissionLabel: 'CrmPromotion',
    isCollapsable: true,
    options: [
      {
        titleKey: 'CRM Promo Code',
        link: AdminRoutes.CRMPromoCode,
        icon: faAward,
        permissionLabel: 'Promocode',
      },
      {
        titleKey: 'CRM Promo Bonus',
        link: AdminRoutes.CRMPromoBonus,
        icon: faAward,
        permissionLabel: 'CrmPromotion',
      },
    ],
  },
  {
    titleKey: 'Export Center',
    link: AdminRoutes.ExportCenter,
    icon: faFileExport,
    permissionLabel: 'ExportCenter',
  },
  {
    titleKey: 'Notification Center',
    link: AdminRoutes.NotificationCenter,
    icon: faBell,
    permissionLabel: 'NotificationCenter',
  },

  {
    titleKey: 'Domain Blocking',
    // labelKey: "Geo-blocking",
    link: AdminRoutes.DomainBlock,
    icon: faImage,
    permissionLabel: 'BlockUsers',
  },
  {
    titleKey: 'Promocode Blocking',
    link: AdminRoutes.PromoCodeBlocking,
    icon: faImage,
    permissionLabel: 'BlockUsers',
  },
];

export const affiliateNavLink = [
  {
    titleKey: 'Players',
    link: AffiliateRoute.AffiliatePlayers,
    icon: faUsers,
    permissionLabel: 'AffiliatePlayers',
  },
  {
    titleKey: 'Profile',
    link: AffiliateRoute.AffiliateProfile,
    icon: faUserAlt,
  },
];
