import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Form as BForm,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Table,
  Container,
} from '@themesberg/react-bootstrap';

import useCheckPermission from '../../../utils/checkPermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InlineLoader } from '../../../components/Preloader';
import { faEdit, faTrash, faSave, faCancel } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Form, ErrorMessage } from 'formik';
import Datetime from 'react-datetime';
import { AdminRoutes } from '../../../routes';
import { addHours, getDateTime, getDateTimeByYMD } from '../../../utils/dateFormatter';
import Trigger from '../../../components/OverlayTrigger';
import { ConfirmationModal, DeleteConfirmationModal } from '../../../components/ConfirmationModal';
import useEditPackage from '../hooks/useEditPackage';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { add, divide, multiply, subtract } from 'lodash';
import { onDownloadCsvDirectClick } from '../../../utils/helper';
import { toast } from '../../../components/Toast';
import { errorHandler, useUploadCsvPackageMutation } from '../../../reactQuery/hooks/customMutationHook';
import ImportPackageCsvModal from './ImportedPackageCsvModal';
import { useQueryClient } from '@tanstack/react-query';

const PackageCreateForm = ({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  loading,
  navigate,
  setFieldValue,
  packageData,
  isEdit = false,
  typeOptions,
  typeValue,
  setTypeValue,
  isSelectLoading,
  handleCreateOption,
  selectedUserIds,
  setSelectedUserIds,
  handleDeleteModal,
  handleDeleteYes,
  deleteModalShow,
  setDeleteModalShow,
  handleYes,
  show,
  setShow,
  active,
  handleShow,
  isEditPage,
}) => {
  const { t } = useTranslation(['packages']);

  const { isHidden } = useCheckPermission();
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedColorBackground, setSelectedColorBackground] = useState('');
  const yesterday = new Date(Date.now() - 86400000);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [ladderPackageList, setLadderPackageList] = useState([]);
  const queryClient = useQueryClient();
  const [importedFile, setImportedFile] = useState(null);
  const [importModalShow, setImportModalShow] = useState(false);
  const [csvPlayerData, setCsvPlayerData] = useState(null);
  const [showAddSubPackages, setShowAddSubPackages] = useState(false);

  useEffect(() => {
    if (packageData && packageData?.textColor) {
      setSelectedColor(packageData?.textColor);
    }
    if (packageData && packageData?.backgroundColor) {
      setSelectedColorBackground(packageData?.backgroundColor);
    }
  }, [packageData]);

  const getCsvDownloadUrl = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/package?packageId=${packageData?.packageId}&orderBy=orderId&sort=ASC&csvDownload=true`;
    return url;
  };

  const handleDownloadClick = async () => {
    try {
      const filename = `user_packages`;
      setDownloadInProgress(true);
      const url = getCsvDownloadUrl();
      await onDownloadCsvDirectClick(url, filename);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      setDownloadInProgress(false);
    }
  };

  useEffect(() => {
    if (values.amount) {
      const roundedScCoin = Math.ceil(values.amount);
      const roundedGcCoin = Math.ceil(values.amount) * 1000;

      setFieldValue('scCoin', roundedScCoin);
      setFieldValue('gcCoin', roundedGcCoin);
    } else {
      // Reset the values when amount is cleared
      setFieldValue('scCoin', '');
      setFieldValue('gcCoin', '');
    }
  }, [values.amount, setFieldValue]);

  useEffect(() => {
    if (values.gcCoin || values.scCoin) {
      setFieldValue('scCoin', values.scCoin);
      setFieldValue('gcCoin', values.gcCoin);
    } else {
      setFieldValue('scCoin', '');
      setFieldValue('gcCoin', '');
    }
  }, [values.gcCoin, setFieldValue]);
  useEffect(() => {
    if (values.bonusSc) {
      const roundedBonusGc = Math.ceil(values.bonusSc) * 1000;
      setFieldValue('bonusGc', roundedBonusGc);
    } else {
      setFieldValue('bonusGc', 0);
    }
  }, [values.bonusSc, setFieldValue]);
  useEffect(() => {
    if (values.bonusGc || values.bonusSc) {
      setFieldValue('bonusSc', values.bonusSc);
      setFieldValue('bonusGc', values.bonusGc);
    } else {
      setFieldValue('bonusSc', 0);
      setFieldValue('bonusGc', 0);
    }
  }, [values.bonusGc, setFieldValue]);
  useEffect(() => {
    if (packageData) {
      setFieldValue('filterType', packageData?.moreDetails?.filterType || '');
      setFieldValue('filterOperator', packageData?.moreDetails?.filterOperator || '=');
      setFieldValue('filterValue', packageData?.moreDetails?.filterValue || 0);
      setSelectedUserIds(packageData?.playerId || []);
      setCsvPlayerData(packageData?.userDetails);
    }
  }, [packageData, setFieldValue]);

  const [selectedOption, setSelectedOption] = useState('Basic Package');

  useEffect(() => {
    if (values?.firstPurchaseApplicable) {
      setSelectedOption('Basic package');
    } else if (values?.welcomePurchaseBonusApplicable) {
      setSelectedOption('Welcome Purchase Packages');
      setFieldValue('intervalsConfig', null);
    } else if (values?.isLadderPackage) {
      setSelectedOption('Ladder Packages');
      setFieldValue('intervalsConfig', null);
    } else {
      setSelectedOption('Basic Package');
    }
  }, [values]);

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
    handleEvent(eventKey);
  };
  const handleEvent = (option) => {
    switch (option) {
      case 'Welcome Purchase Packages':
        setFieldValue('welcomePurchaseBonusApplicable', true);
        setFieldValue('isSpecialPackage', false);
        setFieldValue('firstPurchaseApplicable', false);
        setFieldValue('isValidFrom', false);
        setFieldValue('isValidUntil', false);
        setFieldValue('validTill', null);
        setFieldValue('validFrom', null);
        setFieldValue('ispurchaseNo', false);
        setFieldValue('purchaseNo', 0);
        setFieldValue('isLadderPackage', false);

        break;

      case 'Special Purchase Package':
        setFieldValue('isSpecialPackage', true);
        setFieldValue('welcomePurchaseBonusApplicable', false);
        setFieldValue('firstPurchaseApplicable', false);
        break;
      case 'Basic Package':
        setFieldValue('firstPurchaseApplicable', false);
        setFieldValue('welcomePurchaseBonusApplicable', false);
        setFieldValue('isLadderPackage', false);

        break;
      case 'Ladder Packages':
        setFieldValue('isLadderPackage', true);
        setFieldValue('firstPurchaseApplicable', false);
        setFieldValue('welcomePurchaseBonusApplicable', false);

        break;
      default:
        break;
    }
  };

  const [showRows, setShowRows] = useState(false);
  const [bonusdata, setBonusData] = useState([]);
  useEffect(() => {
    setBonusData(values?.packageFirstPurchase);
  }, [values]);

  const handleButtonClick = () => {
    setShowRows(!showRows);
  };
  const handleCancel = () => {
    setShowRows(false);

    setLadderPackageList([]);
    setFieldValue('packageName', '');
    setFieldValue('amount', '');
    setFieldValue('scCoin', '');
    setFieldValue('gcCoin', '');
    setFieldValue('bonusSc', '');
    setFieldValue('bonusGc', '');
    setFieldValue('isSpecialPackage', false);
  };

  const [editRowId, setEditRowId] = useState(null);
  const [editValues, setEditValues] = useState({});
  const { deleteftploading, statusFtploading } = useEditPackage();

  const handleSubpackageSubmit = () => {
    const existingPackages = values?.intervalsConfig || [];
    const alreadyExists = existingPackages.some(
      (pkg) => +pkg.intervalDays === +editValues.intervalDays && pkg.intervalDays !== editRowId,
    );

    if (alreadyExists) {
      toast(`Package with the same interval already exists in the list`, 'error');
      return;
    }

    setFieldValue(
      'intervalsConfig',
      existingPackages.map((pkg) => (pkg.intervalDays === editRowId ? { ...pkg, ...editValues } : pkg)),
    );

    setEditRowId(null);
  };

  const handleEditClick = (row) => {
    setEditRowId(row.intervalDays);
    setEditValues({
      intervalDays: row?.intervalDays,
      discountedAmount: row?.discountedAmount,
      subpackageGcCoin: row?.subpackageGcCoin,
      subpackageScCoin: row?.subpackageScCoin,
      subpackageGcBonus: row?.subpackageGcBonus,
      subpackageScBonus: row?.subpackageScBonus,
      subpackageBonusPercentage: row?.subpackageBonusPercentage,
      subpackageNoOfPurchase: row?.subpackageNoOfPurchase || null,
      subpackagePurchaseDate: row?.subpackagePurchaseDate,
      subpackageIsActive: row?.subpackageIsActive,
    });
  };

  const handleCancelEdit = () => {
    setEditRowId(null);
    setEditValues({});
  };

  const handleEditSubpackage = (e) => {
    const { name, value, type, checked } = e.target;

    setEditValues((prev) => {
      const updatedValues = {
        ...prev,
        [name]: type === 'checkbox' ? checked : value === '' ? null : value,
      };
      if (name === 'discountedAmount') {
        updatedValues.subpackageScCoin = Math.ceil(+value);
        updatedValues.subpackageGcCoin = updatedValues.subpackageScCoin * 1000;
      }
      if (['discountedAmount', 'subpackageScCoin', 'subpackageScBonus'].includes(name)) {
        const { discountedAmount, subpackageScCoin, subpackageScBonus } = updatedValues;
        updatedValues.subpackageBonusPercentage = Math.floor(
          +multiply(
            +divide(+subtract(+add(+subpackageScCoin, +subpackageScBonus), +discountedAmount), +discountedAmount),
            100,
          ),
        );
      }
      return updatedValues;
    });
  };

  const fileInputRef = useRef(null);
  const handleImportChange = (e) => {
    let file = e.target.files[0];
    setImportedFile(e.target.files[0]);
    if (file) {
      setImportModalShow(true);
    }
    e.target.value = null;
  };

  const handleReplaceCsvClick = () => {
    fileInputRef.current.click();
  };

  const { mutate: uploadPackageCSV, isLoading: uploadCSVLoading } = useUploadCsvPackageMutation({
    onSuccess: ({ data }) => {
      setCsvPlayerData(data?.data?.rows ?? []);
      toast(data.message, 'success');
      queryClient.invalidateQueries({
        queryKey: ['packageAppliedPlayerList'],
      });
      setImportModalShow(false);
    },
    onError: (error) => {
      errorHandler(error);
      setImportModalShow(false);
    },
  });

  useEffect(() => {
    const allUserId = csvPlayerData?.map((user) => user.userId);
    setSelectedUserIds(allUserId);
  }, [csvPlayerData]);
  const handleCSVSumbit = () => {
    let formData = new FormData();
    formData.append('file', importedFile);
    uploadPackageCSV(formData);
    setImportModalShow(false);
  };

  const handleAddHours = (setFieldValue, validFrom, hoursToAdd) => {
    validFrom = validFrom === null || validFrom === undefined ? new Date() : new Date(validFrom);
    const updatedValidTill = addHours(validFrom, hoursToAdd);
    setFieldValue('validTill', updatedValidTill);
  };

  const handleAddLadderPackages = () => {
    const packageData = {
      packageName: values?.packageName,
      amount: values?.amount,
      scCoin: values?.scCoin,
      gcCoin: values?.gcCoin,
      scBonus: values?.bonusSc,
      gcBonus: values?.bonusGc,
      isSpecialPackage: values?.isSpecialPackage,
    };

    const alreadyExistsPackageName = ladderPackageList.find((pkg) => pkg.packageName === packageData.packageName);
    if (alreadyExistsPackageName !== undefined) {
      toast(`Package with same name already exists in the list`, 'error');
      return;
    }
    if (packageData.isSpecialPackage === true) {
      const specialLadderPackage = ladderPackageList.find((pkg) => pkg.isSpecialPackage === true);
      if (specialLadderPackage !== undefined) {
        toast('Only one package can be a special package', 'error');
        return;
      }
    }

    setLadderPackageList((prevList) => {
      const updatedLadderPackageList = [...prevList, packageData];
      setFieldValue('ladderPackageData', updatedLadderPackageList);
      return updatedLadderPackageList;
    });

    setFieldValue('packageName', '');
    setFieldValue('amount', '');
    setFieldValue('scCoin', '');
    setFieldValue('gcCoin', '');
    setFieldValue('bonusSc', '');
    setFieldValue('bonusGc', '');
    setFieldValue('isSpecialPackage', false);
  };

  const handleDeletePackage = (packageName) => {
    const updatedLadderPackageList = ladderPackageList?.filter((pkg) => pkg.packageName !== packageName);
    setLadderPackageList(updatedLadderPackageList);
    setFieldValue('ladderPackageData', updatedLadderPackageList);
  };

  const handleCheckBoxChange = (e) => {
    setFieldValue('isValidFrom', e.target.checked);
    setFieldValue('validFrom', null);
  };

  const handleAddSubPackages = () => {
    const newSubPackage = {
      intervalDays: values?.intervalDays,
      discountedAmount: values?.discountedAmount,
      subpackageGcCoin: values?.subpackageGcCoin,
      subpackageScCoin: values?.subpackageScCoin,
      subpackageGcBonus: values?.subpackageGcBonus,
      subpackageScBonus: values?.subpackageScBonus,
      subpackageBonusPercentage: values?.subpackageBonusPercentage,
      subpackageNoOfPurchase: values?.subpackageNoOfPurchase,
      subpackagePurchaseDate: values?.subpackagePurchaseDate,
      subpackageIsActive: values?.subpackageIsActive,
    };

    const existingPackages = values.intervalsConfig || [];
    const alreadyExists = existingPackages.some((pkg) => pkg.intervalDays === newSubPackage.intervalDays);

    if (alreadyExists) {
      toast(`Package with the same interval already exists in the list`, 'error');
      return;
    }

    setFieldValue('intervalsConfig', [...existingPackages, newSubPackage]);

    setFieldValue('intervalDays', '');
    setFieldValue('discountedAmount', '');
    setFieldValue('subpackageScCoin', '');
    setFieldValue('subpackageGcCoin', '');
    setFieldValue('subpackageGcBonus', '');
    setFieldValue('subpackageScBonus', '');
    setFieldValue('subpackageBonusPercentage', '');
    setFieldValue('subpackageNoOfPurchase', null);
    setFieldValue('subpackagePurchaseDate', false);
    setFieldValue('subpackageIsActive', false);
  };

  const handleDeleteSubPackages = (intervalDays) => {
    const updatedPackages = values.intervalsConfig?.filter((pkg) => pkg.intervalDays !== intervalDays);
    setFieldValue('intervalsConfig', updatedPackages);
  };

  const handleCancelSubPackage = () => {
    setShowAddSubPackages(!showAddSubPackages);

    setFieldValue('intervalDays', '');
    setFieldValue('discountedAmount', '');
    setFieldValue('subpackageScCoin', '');
    setFieldValue('subpackageGcCoin', '');
    setFieldValue('subpackageGcBonus', '');
    setFieldValue('subpackageScBonus', '');
    setFieldValue('subpackageBonusPercentage', '');

    setFieldValue('subpackageNoOfPurchase', null);
    setFieldValue('subpackagePurchaseDate', false);
    setFieldValue('subpackageIsActive', false);
  };

  const handleChangeDiscountAmount = (e) => {
    const { value } = e.target;
    if (value.match(/^(\d+(\.\d{0,2})?)?$/)) {
      const discountedAmount = value;
      const roundedScCoin = Math.ceil(+discountedAmount);
      const roundedGcCoin = Math.ceil(+discountedAmount) * 1000;

      const bonusPercentage = Math.floor(
        +multiply(
          +divide(
            +subtract(+add(roundedScCoin, +values?.subpackageScBonus ?? 0), +discountedAmount),
            +discountedAmount,
          ),
          100,
        ),
      );

      setFieldValue('discountedAmount', value);
      setFieldValue('subpackageScCoin', roundedScCoin);
      setFieldValue('subpackageGcCoin', roundedGcCoin);
      setFieldValue('subpackageBonusPercentage', bonusPercentage);
    } else {
      setFieldValue('subpackageScCoin', '');
      setFieldValue('subpackageGcCoin', '');
      setFieldValue('discountedAmount', '');
      setFieldValue('subpackageBonusPercentage', 0);
    }
  };

  const handleChangeScCoin = (e) => {
    const { value } = e.target;

    const bonusPercentage = Math.floor(
      +multiply(
        +divide(
          +subtract(+add(+value, +values?.subpackageScBonus ?? 0), +values?.discountedAmount),
          +values?.discountedAmount,
        ),
        100,
      ),
    );
    setFieldValue('subpackageBonusPercentage', bonusPercentage);
    setFieldValue('subpackageScCoin', value);
  };

  const handleChangeSubpackageScBonus = (e) => {
    const { value } = e.target;

    if (value) {
      const bonusPercentage = Math.floor(
        +multiply(
          +divide(
            +subtract(+add(+values?.subpackageScCoin, +value ?? 0), +values?.discountedAmount),
            +values?.discountedAmount,
          ),
          100,
        ),
      );
      setFieldValue('subpackageBonusPercentage', bonusPercentage);
      setFieldValue('subpackageScBonus', value);
    } else {
      setFieldValue('subpackageBonusPercentage', 0);
      setFieldValue('subpackageScBonus', '');
    }
  };

  return (
    <>
      <Form className='border rounded p-2'>
        <div className='container-fluid'>
          <Row>
            <div className='col-12 col-lg-6'>
              <BForm.Label>Select Package Type</BForm.Label>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-package-type'>Select the type of package.</Tooltip>}
              >
                <BForm.Select
                  name='packageType'
                  value={selectedOption}
                  onChange={(e) => handleSelect(e.target.value)}
                  onBlur={handleBlur}
                >
                  <option value='Basic Package'>Basic Package</option>
                  <option value='Welcome Purchase Packages'>Welcome Purchase Package</option>
                  {!isEditPage && <option value='Ladder Packages'>Ladder Packages</option>}
                </BForm.Select>
              </OverlayTrigger>
              <ErrorMessage component='div' name='packageType' className='text-danger' />
            </div>
            {!values?.welcomePurchaseBonusApplicable && !values?.isLadderPackage && (
              <div className='col-12 col-lg-6'>
                <BForm.Label>Package Purchase Number</BForm.Label>
                <div style={{ position: 'relative' }}>
                  <BForm.Check
                    type='switch'
                    name='ispurchaseNo'
                    checked={values.ispurchaseNo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isEdit}
                    style={{
                      position: 'absolute',
                      top: '10px',
                      left: 'auto',
                      right: '10px',
                      zIndex: 2,
                    }}
                  />
                  {values.ispurchaseNo === true ? (
                    <>
                      <BForm.Control
                        type='number'
                        name='purchaseNo'
                        min='1'
                        onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                        placeholder={t('Enter Package Purchase Number')}
                        value={values.purchaseNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onPaste={(evt) => evt.preventDefault()}
                      />

                      <ErrorMessage component='div' name='purchaseNumber' className='text-danger' />
                    </>
                  ) : (
                    <>
                      <div className=''>
                        <BForm.Control type='number' name='purchaseNumber' min='0' disabled={true} />
                        <ErrorMessage component='div' name='purchaseNumber' className='text-danger' />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </Row>
          <Row className='mt-2 '>
            {!values?.isLadderPackage && (
              <div className='col-12 col-lg-6'>
                <BForm.Label>
                  Package Name
                  <span className='text-danger'> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id='tooltip-package-name'>Enter the name of the package.</Tooltip>}
                >
                  <BForm.Control
                    type='text'
                    name='packageName'
                    placeholder={t('Package Name')}
                    value={values?.packageName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={(evt) => ['+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                  />
                </OverlayTrigger>

                <ErrorMessage component='div' name='packageName' className='text-danger' />
              </div>
            )}

            <div className='col-12 col-lg-6'>
              <BForm.Label>
                Package purchase limit per user
                <span className='text-danger'> *</span>
              </BForm.Label>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-top'>Enter zero if you do not want to add a limit.</Tooltip>}
              >
                <BForm.Control
                  type='number'
                  name='purchaseLimitPerUser'
                  min='0'
                  onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                  placeholder={t('createPackage.inputFields.purchaseLimitPerUser.placeholder')}
                  value={values.purchaseLimitPerUser}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={values.welcomePurchaseBonusApplicable}
                />
              </OverlayTrigger>
              <ErrorMessage component='div' name='purchaseLimitPerUser' className='text-danger' />
            </div>
          </Row>
        </div>

        <div className='container-fluid'>
          {values?.welcomePurchaseBonusApplicable && (
            <Row className='mt-3'>
              <Col>
                <BForm.Label>
                  Timer
                  <span className='text-danger'> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id='tooltip-timer'>Set the timer for special purchases in minutes.</Tooltip>}
                >
                  <BForm.Control
                    type='number'
                    name='welcomePurchaseBonusApplicableMinutes'
                    min='0'
                    onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                    placeholder={t('createPackage.inputFields.welcomePurchaseBonusApplicableMinutes.placeholder')}
                    value={values.welcomePurchaseBonusApplicableMinutes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.welcomePurchaseBonusApplicable}
                  />
                </OverlayTrigger>
                <ErrorMessage component='div' name='welcomePurchaseBonusApplicableMinutes' className='text-danger' />
              </Col>
              <Col>
                <BForm.Label>
                  Welcome Purchase Percentage
                  <span className='text-danger'> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip id='tooltip-welcome-percentage'>Enter the percentage for welcome purchases.</Tooltip>
                  }
                >
                  <BForm.Control
                    type='number'
                    name='welcomePurchasePercentage'
                    min='0'
                    placeholder={'Welcome Purchase Percentage'}
                    value={values.welcomePurchasePercentage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.welcomePurchaseBonusApplicable}
                  />
                </OverlayTrigger>
                <ErrorMessage component='div' name='welcomePurchasePercentage' className='text-danger' />
              </Col>
            </Row>
          )}
        </div>
        {!values?.isLadderPackage && (
          <div className='container-fluid'>
            <Row className='mt-3'>
              <Col>
                <BForm.Label>
                  {`${t('createPackage.inputFields.amountField.label')} ( $ )`}
                  <span className='text-danger'> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id='tooltip-amount'>Enter the amount for the package.</Tooltip>}
                >
                  <BForm.Control
                    type='number'
                    name='amount'
                    min='0'
                    onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                    placeholder={t('createPackage.inputFields.amountField.placeholder')}
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isEdit}
                  />
                </OverlayTrigger>
                <ErrorMessage component='div' name='amount' className='text-danger' />
              </Col>
              <Col>
                <BForm.Label>
                  {t('createPackage.inputFields.gcField.label')}
                  <span className='text-danger'> *</span>
                </BForm.Label>
                <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-amount'>gc coin</Tooltip>}>
                  <BForm.Control
                    type='number'
                    name='gcCoin'
                    min='0'
                    onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                    placeholder={t('createPackage.inputFields.gcField.placeholder')}
                    value={values.gcCoin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isEdit}
                  />
                </OverlayTrigger>
                <ErrorMessage component='div' name='gcCoin' className='text-danger' />
              </Col>
              <Col>
                <BForm.Label>
                  {t('createPackage.inputFields.scField.label')}
                  <span className='text-danger'> *</span>
                </BForm.Label>
                <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-amount'>sc coin</Tooltip>}>
                  <BForm.Control
                    type='number'
                    name='scCoin'
                    min='0'
                    onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                    placeholder={t('createPackage.inputFields.scField.placeholder')}
                    value={values.scCoin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isEdit}
                  />
                </OverlayTrigger>
                <ErrorMessage component='div' name='scCoin' className='text-danger' />
              </Col>

              {/* {!values?.firstPurchaseApplicable && ( */}
              <Col>
                <BForm.Label>{t('createPackage.inputFields.bonusSc.label')}</BForm.Label>
                <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-bonus-sc'>sc bonus</Tooltip>}>
                  <BForm.Control
                    type='number'
                    name='bonusSc'
                    min='0'
                    onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                    placeholder={t('createPackage.inputFields.bonusSc.placeholder')}
                    value={values.bonusSc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isEdit}
                  />
                </OverlayTrigger>
                <ErrorMessage component='div' name='bonusSc' className='text-danger' />
              </Col>

              <Col>
                <BForm.Label>{t('createPackage.inputFields.bonusGc.label')}</BForm.Label>
                <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-bonus-sc'>gc bonus</Tooltip>}>
                  <BForm.Control
                    type='number'
                    name='bonusGc'
                    min='0'
                    onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                    placeholder={t('createPackage.inputFields.bonusGc.placeholder')}
                    value={values.bonusGc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isEdit}
                  />
                </OverlayTrigger>
                <ErrorMessage component='div' name='bonusGc' className='text-danger' />
              </Col>
              {/* )} */}
            </Row>
          </div>
        )}

        <div className='container-fluid'>
          <Row className='mt-3 justify-content-between'>
            <Col>
              <Row>
                {!values?.welcomePurchaseBonusApplicable && !values?.isLadderPackage && (
                  <div className='col-12 col-lg-4'>
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip id='tooltip-special-package'>This is a special package.</Tooltip>}
                    >
                      <div
                        className='d-flex align-items-center  rounded p-2 justify-content-between'
                        style={{ border: '0.0625rem solid #d1d7e0' }}
                      >
                        <p className='mb-0'>
                          {t('createPackage.inputFields.specialPackage')}
                          <span className='text-danger'> *</span>
                        </p>

                        <BForm.Check
                          name='isSpecialPackage'
                          checked={values.isSpecialPackage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={values.welcomePurchaseBonusApplicable}
                        />
                      </div>
                    </OverlayTrigger>
                    <ErrorMessage component='div' name='isSpecialPackage' className='text-danger' />
                  </div>
                )}
                <div className='col-12 col-lg-4'>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip-active'>
                        {values.isActive ? 'The package is currently active.' : 'The package is currently inactive.'}
                      </Tooltip>
                    }
                  >
                    <div
                      className='d-flex align-items-center  rounded p-2 justify-content-between'
                      style={{ border: '0.0625rem solid #d1d7e0' }}
                    >
                      <p className='mb-0'>
                        {t('createPackage.inputFields.active')}
                        <span className='text-danger'> *</span>
                      </p>
                      <BForm.Check
                        name='isActive'
                        className='ml-2'
                        checked={values.isActive}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isEdit}
                      />
                    </div>
                  </OverlayTrigger>
                  <ErrorMessage component='div' name='isActive' className='text-danger' />
                </div>
                {values?.welcomePurchaseBonusApplicable !== true && (
                  <div className='col-12 col-lg-4'>
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tooltip-visible-store'>
                          {values.isVisibleInStore
                            ? 'The package is visible in the store.'
                            : 'Select package to make it visible in the store.'}
                        </Tooltip>
                      }
                    >
                      <div
                        className='d-flex align-items-center    rounded p-2 justify-content-between '
                        style={{ border: '0.0625rem solid #d1d7e0' }}
                      >
                        <p className='mb-0'>
                          {t('createPackage.inputFields.visibleInStore')}
                          <span className='text-danger'> *</span>
                        </p>

                        <BForm.Check
                          name='isVisibleInStore'
                          // placeholder='Enter User Name'
                          checked={values.isVisibleInStore}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </OverlayTrigger>
                    <ErrorMessage component='div' name='isVisibleInStore' className='text-danger' />
                  </div>
                )}
              </Row>
            </Col>

            {
              <div className='container-fluid'>
                <Row className='mt-3'>
                  {!values?.welcomePurchaseBonusApplicable && (
                    <div className='col-12 col-lg-6' border='primary'>
                      <Row className='mt-3' style={{ position: 'relative' }}>
                        <div className='col-12 col-lg-12'>
                          <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='tooltip-valid-from'>Starts Validity</Tooltip>}
                          >
                            <div className='d-flex  gap-5 align-items-center justify-content-between'>
                              <h5 className='m-0 ' style={{ fontSize: '18px' }}>
                                Valid From
                              </h5>
                              <div className='relative flex-grow-1'>
                                <BForm.Check
                                  type='switch'
                                  name='isValidFrom'
                                  checked={values.isValidFrom}
                                  // onChange={handleChange}
                                  onChange={handleCheckBoxChange}
                                  onBlur={handleBlur}
                                  disabled={isEdit}
                                  style={{
                                    position: 'absolute',
                                    top: '9px',
                                    left: 'auto',
                                    right: '10px',
                                    zIndex: 2,
                                  }}
                                />

                                <ErrorMessage component='div' name='isValidFrom' className='text-danger' />
                                {values.isValidFrom === true ? (
                                  <>
                                    <div className=''>
                                      <Datetime
                                        inputProps={{
                                          placeholder: 'MM-DD-YYYY HH:MM',
                                        }}
                                        dateFormat='MM-DD-YYYY'
                                        onChange={(e) => {
                                          setFieldValue('validFrom', e);
                                        }}
                                        value={values.validFrom ? getDateTime(values.validFrom) : values.validFrom}
                                        isValidDate={(e) => {
                                          return (
                                            e._d > yesterday || getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                                          );
                                        }}
                                        timeFormat={true}
                                      />
                                      <ErrorMessage component='div' name='validFrom' className='text-danger' />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className=''>
                                      <BForm.Control type='number' name='validFrom' min='0' disabled={true} />

                                      <ErrorMessage component='div' name='validFrom' className='text-danger' />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </Row>

                      <Row className='mt-3' style={{ position: 'relative' }}>
                        <div className='col-12 col-lg-12'>
                          <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='tooltip-valid-until'>Ends Validity</Tooltip>}
                          >
                            <div className='d-flex  gap-5 align-items-center justify-content-between'>
                              <h5 className='m-0 ' style={{ fontSize: '18px' }}>
                                Valid Until
                              </h5>
                              <div className='relative flex-grow-1'>
                                <BForm.Check
                                  type='switch'
                                  name='isValidUntil'
                                  checked={values.isValidUntil}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={isEdit}
                                  style={{
                                    position: 'absolute',
                                    top: '9px',
                                    left: 'auto',
                                    right: '10px',
                                    zIndex: 2,
                                  }}
                                />
                                <ErrorMessage component='div' name='isValidUntil' className='text-danger' />
                                {values.isValidUntil === true ? (
                                  <>
                                    <div className=''>
                                      <Datetime
                                        inputProps={{
                                          placeholder: 'MM-DD-YYYY HH:MM',
                                        }}
                                        dateFormat='MM-DD-YYYY'
                                        onChange={(e) => {
                                          setFieldValue('validTill', e);
                                        }}
                                        value={values.validTill ? getDateTime(values.validTill) : values.validTill}
                                        isValidDate={(e) => {
                                          return (
                                            e._d > yesterday || getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                                          );
                                        }}
                                        timeFormat={true}
                                      />
                                      <ErrorMessage component='div' name='validTill' className='text-danger' />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className=''>
                                      <BForm.Control type='number' name='validTill' min='0' disabled={true} />

                                      <ErrorMessage component='div' name='validTill' className='text-danger' />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </Row>
                      <div className='d-flex align-items-center mt-2' style={{ gap: '16px' }}>
                        <Button
                          variant='primary'
                          onClick={() => handleAddHours(setFieldValue, values.validFrom, 12)}
                          disabled={!values.isValidUntil}
                          className='m-2'
                        >
                          {t('createPackage.inputFields.12Hour')}
                        </Button>

                        <Button
                          variant='primary'
                          onClick={() => handleAddHours(setFieldValue, values.validFrom, 24)}
                          disabled={!values.isValidUntil}
                          className='ml-4'
                        >
                          {t('createPackage.inputFields.24Hour')}
                        </Button>
                      </div>
                    </div>
                  )}

                  <div
                    className='col-12 col-lg-6  rounded  '
                    style={{
                      position: 'relative',
                      border: '0.0625rem solid #d1d7e0  ',
                      minHeight: '120px',
                    }}
                  >
                    <BForm.Label className='relative mt-3'>
                      Package Image
                      <span className='text-danger'> *</span>
                    </BForm.Label>
                    <BForm.Text>
                      <Trigger message={t('createPackage.inputFields.thumbnail.message')} id={'mes'} />

                      <Button
                        className='file-upload-button'
                        style={{
                          position: 'absolute',
                          bottom: '10px',
                          top: 'auto',
                          left: 'auto',
                          right: '15px',
                        }}
                      >
                        Choose File
                        <input
                          id={'mes'}
                          title=' '
                          name='image'
                          type='file'
                          style={{
                            opacity: '0',
                            position: 'absolute',
                            width: '100%',
                            right: '2px',
                          }}
                          onChange={(event) => {
                            setFieldValue('image', event.currentTarget.files[0]);
                          }}
                        />
                      </Button>
                      {values?.image && (
                        <img
                          alt='not found'
                          width='200px'
                          height='100px'
                          src={URL.createObjectURL(values.image)}
                          style={{ marginTop: '10px' }}
                        />
                      )}
                      {!values?.image && packageData?.imageUrl && (
                        <img
                          alt='not found'
                          width='60px'
                          src={packageData.imageUrl}
                          style={{
                            height: '120px',
                            width: '230px',
                            marginTop: '1%',
                          }}
                        />
                      )}
                    </BForm.Text>

                    <ErrorMessage component='div' name='image' className='text-danger' />
                  </div>
                </Row>
              </div>
            }
          </Row>
        </div>

        {values?.isLadderPackage && (
          <>
            <div className='container-fluid'>
              <Row className='rounded mt-2 p-2' style={{ border: '0.0625rem solid #d1d7e0' }}>
                <Button
                  variant='outline-primary'
                  className='f-right ml-2'
                  onClick={handleButtonClick}
                  hidden={showRows}
                >
                  Add Packages
                </Button>

                {showRows && (
                  <div className='container-fluid'>
                    <Row className='mt-3'>
                      <Col md={6}>
                        <BForm.Label>
                          Package Name<span className='text-danger'> *</span>
                        </BForm.Label>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-package-name'>Enter the name of the package.</Tooltip>}
                        >
                          <BForm.Control
                            type='text'
                            name='packageName'
                            placeholder={t('Package Name')}
                            value={values.packageName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={(evt) => ['+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                          />
                        </OverlayTrigger>
                        <ErrorMessage component='div' name='packageName' className='text-danger' />
                      </Col>

                      <Col md={3}>
                        <BForm.Label>
                          {`${t('createPackage.inputFields.amountField.label')} ( $ )`}
                          <span className='text-danger'> *</span>
                        </BForm.Label>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-amount'>Enter the amount for the package.</Tooltip>}
                        >
                          <BForm.Control
                            type='number'
                            name='amount'
                            min='0'
                            onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                            placeholder={t('createPackage.inputFields.amountField.placeholder')}
                            value={values.amount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isEdit}
                          />
                        </OverlayTrigger>
                        <ErrorMessage component='div' name='amount' className='text-danger' />
                      </Col>

                      <Col md={3}>
                        <BForm.Label>
                          {t('createPackage.inputFields.gcField.label')}
                          <span className='text-danger'> *</span>
                        </BForm.Label>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-gc'>Enter the GC value.</Tooltip>}
                        >
                          <BForm.Control
                            type='number'
                            name='gcCoin'
                            min='0'
                            onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                            placeholder={t('createPackage.inputFields.gcField.placeholder')}
                            value={values.gcCoin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isEdit}
                          />
                        </OverlayTrigger>
                        <ErrorMessage component='div' name='gcCoin' className='text-danger' />
                      </Col>
                    </Row>
                    <Row className='mt-3 d-flex align-items-end'>
                      <Col md={3}>
                        <BForm.Label>
                          {t('createPackage.inputFields.scField.label')}
                          <span className='text-danger'> *</span>
                        </BForm.Label>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-sc'>Enter the SC value.</Tooltip>}
                        >
                          <BForm.Control
                            type='number'
                            name='scCoin'
                            min='0'
                            onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                            placeholder={t('createPackage.inputFields.scField.placeholder')}
                            value={values.scCoin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isEdit}
                          />
                        </OverlayTrigger>
                        <ErrorMessage component='div' name='scCoin' className='text-danger' />
                      </Col>

                      <Col md={3}>
                        <BForm.Label>{t('createPackage.inputFields.bonusSc.label')}</BForm.Label>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-bonus-sc'>Enter SC bonus value.</Tooltip>}
                        >
                          <BForm.Control
                            type='number'
                            name='bonusSc'
                            min='0'
                            onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                            placeholder={t('createPackage.inputFields.bonusSc.placeholder')}
                            value={values.bonusSc}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isEdit}
                          />
                        </OverlayTrigger>
                        <ErrorMessage component='div' name='bonusSc' className='text-danger' />
                      </Col>

                      <Col md={3}>
                        <BForm.Label>{t('createPackage.inputFields.bonusGc.label')}</BForm.Label>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-bonus-gc'>Enter GC bonus value.</Tooltip>}
                        >
                          <BForm.Control
                            type='number'
                            name='bonusGc'
                            min='0'
                            onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                            placeholder={t('createPackage.inputFields.bonusGc.placeholder')}
                            value={values.bonusGc}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isEdit}
                          />
                        </OverlayTrigger>
                        <ErrorMessage component='div' name='bonusGc' className='text-danger' />
                      </Col>

                      <Col md={3}>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-special-package'>This is a special package.</Tooltip>}
                        >
                          <div
                            className='d-flex align-items-center rounded p-2 justify-content-between'
                            style={{ border: '0.0625rem solid #d1d7e0' }}
                          >
                            <p className='mb-0'>
                              {t('createPackage.inputFields.specialPackage')}
                              <span className='text-danger'> *</span>
                            </p>
                            <BForm.Check
                              name='isSpecialPackage'
                              checked={values.isSpecialPackage}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={values.welcomePurchaseBonusApplicable}
                            />
                          </div>
                        </OverlayTrigger>
                        <ErrorMessage component='div' name='isSpecialPackage' className='text-danger' />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <div className='mt-4 d-flex justify-content-between align-items-center'>
                        <Button variant='warning' onClick={handleCancel}>
                          Cancel
                        </Button>
                        <Button
                          variant='primary'
                          onClick={handleAddLadderPackages}
                          disabled={
                            values?.packageName === '' ||
                            values?.amount === '' ||
                            values?.amount <= 0 ||
                            values?.gcCoin === '' ||
                            values?.scCoin === ''
                          }
                        >
                          Add Package
                        </Button>
                      </div>
                    </Row>

                    {ladderPackageList?.length > 0 && (
                      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
                        <thead className='thead-dark'>
                          <tr>
                            {[
                              'PACKAGE NAME',
                              'AMOUNT',
                              'GC COIN',
                              'SC COIN',
                              'GC bONUS',
                              'SC BONUS',
                              'SPECIAL PACKAGE',
                              'ACTION',
                            ].map((h) => (
                              <th key={h}>{h}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {ladderPackageList?.map((pkg, index) => {
                            return (
                              <tr key={index}>
                                <td>{pkg.packageName}</td>
                                <td>{pkg.amount}</td>
                                <td>{pkg.gcCoin}</td>
                                <td>{pkg.scCoin}</td>
                                <td>{pkg.gcBonus}</td>
                                <td>{pkg.scBonus}</td>
                                <td>{pkg.isSpecialPackage ? 'Yes' : 'No'}</td>
                                <td>
                                  <Trigger message='Delete' id={pkg?.packageName + 'delete'} />
                                  <Button
                                    id={pkg?.packageName + 'delete'}
                                    className='m-1'
                                    size='sm'
                                    variant='danger'
                                    onClick={() => handleDeletePackage(pkg.packageName)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </div>
                )}
              </Row>

              <ErrorMessage component='div' name='ladderPackageData' className='text-danger' />
            </div>
          </>
        )}

        {!values?.isLadderPackage && !values?.welcomePurchaseBonusApplicable && (
          <>
            <Button
              className='mt-4'
              onClick={() => setShowAddSubPackages(!showAddSubPackages)}
              disabled={values?.amount === '' || values?.amount <= 0 || values?.gcCoin === '' || values?.scCoin === ''}
              hidden={showAddSubPackages}
            >
              Add SubPackages
            </Button>
            {showAddSubPackages && (
              <Container fluid className='mt-4 rounded border p-4'>
                <Row>
                  <Col xs={12} md={6} lg={2}>
                    <BForm.Label>
                      Days <span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Control
                      type='number'
                      placeholder='Enter interval days'
                      name='intervalDays'
                      min='0'
                      onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                      value={values?.intervalDays}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col xs={12} md={6} lg={2}>
                    <BForm.Label>
                      Discounted Amount <span className='text-danger'> *</span>
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      placeholder={'Enter the Discounted Amount'}
                      name='discountedAmount'
                      min='0'
                      onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                      value={values?.discountedAmount}
                      onChange={handleChangeDiscountAmount}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col xs={12} md={6} lg={2}>
                    <BForm.Label>
                      SC Coin <span className='text-danger'> *</span>
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      name='subpackageScCoin'
                      value={values?.subpackageScCoin}
                      onChange={handleChangeScCoin}
                      onBlur={handleBlur}
                      min='0'
                      onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={2}>
                    <BForm.Label>
                      GC Coin <span className='text-danger'> *</span>
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      min='0'
                      onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                      name='subpackageGcCoin'
                      value={values?.subpackageGcCoin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={2}>
                    <BForm.Label>
                      SC Bonus Coin <span className='text-danger'> *</span>
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      name='subpackageScBonus'
                      min='0'
                      onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                      placeholder='Enter Subpackage SC Bonus'
                      value={values?.subpackageScBonus}
                      onChange={handleChangeSubpackageScBonus}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={2}>
                    <BForm.Label>
                      GC Bonus Coin <span className='text-danger'> *</span>
                    </BForm.Label>
                    <BForm.Control
                      type='number'
                      name='subpackageGcBonus'
                      min='0'
                      onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                      placeholder='Enter Subpackage GC Bonus'
                      value={values?.subpackageGcBonus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <Row className='mt-2 align-items-end'>
                  <Col xs={12} md={6} lg={2}>
                    <BForm.Label>Calculated Bonus Percentage</BForm.Label>
                    <BForm.Control
                      type='number'
                      name='subpackageBonusPercentage'
                      value={values?.subpackageBonusPercentage}
                      onChange={handleChange}
                      min='0'
                      onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                      onBlur={handleBlur}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6} lg={2}>
                    <BForm.Label>Number of Purchases</BForm.Label>
                    <BForm.Control
                      type='number'
                      name='subpackageNoOfPurchase'
                      min='0'
                      value={values?.subpackageNoOfPurchase || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                    />
                  </Col>

                  <Col xs={12} md={6} lg={3}>
                    <div className='d-flex align-items-center  rounded p-2 justify-content-between border'>
                      <BForm.Label>
                        {values?.subpackagePurchaseDate ? 'Last Purchase Date enabled' : 'Registration Date enabled'}
                      </BForm.Label>
                      <BForm.Check
                        type='switch'
                        name='subpackagePurchaseDate'
                        value='true'
                        checked={values?.subpackagePurchaseDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={2}>
                    <div className='d-flex align-items-center  rounded p-2 justify-content-between border'>
                      <BForm.Label>Active</BForm.Label>
                      <BForm.Check
                        name='subpackageIsActive'
                        value='true'
                        checked={values?.subpackageIsActive}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className='mt-3 justify-content-between'>
                  <Button className='w-auto ' variant='warning' onClick={handleCancelSubPackage}>
                    Cancel
                  </Button>
                  <Button
                    className='w-auto'
                    variant='primary'
                    onClick={handleAddSubPackages}
                    disabled={
                      values?.intervalDays <= 0 ||
                      values?.discountedAmount <= 0 ||
                      values?.subpackageScCoin === '' ||
                      values?.subpackageGcCoin === '' ||
                      values?.subpackageGcBonus === '' ||
                      values?.subpackageScBonus === '' ||
                      values?.subpackageBonusPercentage < 0
                    }
                  >
                    Add SubPackages
                  </Button>
                </Row>
              </Container>
            )}
          </>
        )}

        {values?.intervalsConfig && values?.intervalsConfig?.length > 0 && (
          <>
            <div className='d-flex justify-content-between align-items-baseline'>
              <h5 className='mt-4'>Sub Package Lists</h5>
              <p className='text-danger'>
                Please &apos;Save&apos; before submitting if you have made any edits to the subpackages.
              </p>
            </div>
            <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
              <thead className='thead-dark'>
                <tr>
                  {[
                    'INTERVAL DAYS',
                    'DISCOUNTED AMOUNT',
                    'GC COIN',
                    'SC COIN',
                    'GC BONUS',
                    'SC BONUS',
                    'BONUS PERCENTAGE',
                    'NUMBER OF PURCHASES',
                    'ENABLE LAST PURCHASE DATE',
                    'ACTIVE',
                    'ACTION',
                  ].map((h) => (
                    <th key={h}>{h}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {values?.intervalsConfig.map((pkg, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <>
                            <BForm.Control
                              type='number'
                              name='intervalDays'
                              step='any'
                              min='0'
                              onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                              value={editValues.intervalDays || ''}
                              onChange={handleEditSubpackage}
                            />
                          </>
                        ) : (
                          pkg.intervalDays
                        )}
                      </td>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <>
                            {' '}
                            <BForm.Control
                              type='number'
                              name='discountedAmount'
                              step='any'
                              min='0'
                              onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                              value={editValues.discountedAmount || ''}
                              onChange={handleEditSubpackage}
                            />
                          </>
                        ) : (
                          pkg.discountedAmount
                        )}
                      </td>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <BForm.Control
                            style={{ width: '5rem' }}
                            type='number'
                            name='subpackageGcCoin'
                            step='any'
                            min='0'
                            onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                            value={editValues.subpackageGcCoin || ''}
                            onChange={handleEditSubpackage}
                          />
                        ) : (
                          pkg.subpackageGcCoin
                        )}
                      </td>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <BForm.Control
                            style={{ width: '5rem' }}
                            type='number'
                            name='subpackageScCoin'
                            step='any'
                            min='0'
                            onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                            value={editValues.subpackageScCoin || ''}
                            onChange={handleEditSubpackage}
                          />
                        ) : (
                          pkg.subpackageScCoin
                        )}
                      </td>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <BForm.Control
                            type='number'
                            style={{ width: '5rem' }}
                            name='subpackageGcBonus'
                            step='any'
                            min='0'
                            value={editValues.subpackageGcBonus || ''}
                            onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                            onChange={handleEditSubpackage}
                          />
                        ) : (
                          pkg.subpackageGcBonus
                        )}
                      </td>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <BForm.Control
                            style={{ width: '5rem' }}
                            type='number'
                            name='subpackageScBonus'
                            step='any'
                            min='0'
                            value={editValues.subpackageScBonus || ''}
                            onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                            onChange={handleEditSubpackage}
                          />
                        ) : (
                          pkg.subpackageScBonus
                        )}
                      </td>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <BForm.Control
                            type='number'
                            name='subpackageBonusPercentage'
                            step='any'
                            min='0'
                            onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                            value={editValues.subpackageBonusPercentage || ''}
                            onChange={handleEditSubpackage}
                            disabled
                          />
                        ) : (
                          pkg?.subpackageBonusPercentage
                        )}
                      </td>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <BForm.Control
                            type='number'
                            name='subpackageNoOfPurchase'
                            step='any'
                            min='0'
                            onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                            value={editValues.subpackageNoOfPurchase || ''}
                            onChange={handleEditSubpackage}
                          />
                        ) : (
                          pkg?.subpackageNoOfPurchase
                        )}
                      </td>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <BForm.Check
                            type='switch'
                            name='subpackagePurchaseDate'
                            checked={editValues.subpackagePurchaseDate}
                            onChange={handleEditSubpackage}
                          />
                        ) : pkg?.subpackagePurchaseDate ? (
                          'Yes'
                        ) : (
                          'No'
                        )}
                      </td>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <BForm.Check
                            name='subpackageIsActive'
                            checked={editValues.subpackageIsActive}
                            onChange={handleEditSubpackage}
                          />
                        ) : pkg.subpackageIsActive ? (
                          'Yes'
                        ) : (
                          'No'
                        )}
                      </td>
                      <td>
                        {editRowId === pkg?.intervalDays ? (
                          <>
                            <Trigger message={'Save'} id={pkg?.intervalDays + 'save'} />
                            <Button
                              id={pkg?.intervalDays + 'save'}
                              className='m-1'
                              size='sm'
                              variant='warning'
                              onClick={handleSubpackageSubmit}
                              disabled={
                                editValues?.intervalDays <= 0 ||
                                editValues?.discountedAmount === '' ||
                                editValues?.discountedAmount <= 0 ||
                                editValues?.subpackageGcCoin === '' ||
                                editValues?.subpackageScCoin === '' ||
                                editValues?.subpackageGcBonus === '' ||
                                editValues?.subpackageScBonus === '' ||
                                editValues?.subpackageBonusPercentage < 0
                              }
                            >
                              <FontAwesomeIcon icon={faSave} />
                            </Button>
                            <Trigger message={'Cancel'} id={pkg?.intervalDays + 'cancel'} />

                            <Button
                              id={pkg?.intervalDays + 'cancel'}
                              className='m-1'
                              size='sm'
                              variant='warning'
                              onClick={handleCancelEdit}
                            >
                              <FontAwesomeIcon icon={faCancel} />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Trigger message='Delete' id={pkg?.intervalDays + 'delete'} />
                            <Button
                              id={pkg?.intervalDays + 'delete'}
                              className='m-1'
                              size='sm'
                              variant='danger'
                              onClick={() => handleDeleteSubPackages(pkg?.intervalDays)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>

                            <Trigger message={'Edit'} id={pkg?.intervalDays + 'edit'} />
                            <Button
                              id={pkg?.intervalDays + 'edit'}
                              className='m-1'
                              size='sm'
                              variant='warning'
                              onClick={() => handleEditClick(pkg)}
                              hidden={isHidden({ module: { key: 'Package', value: 'U' } })}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}

        <Row>
          {!values?.welcomePurchaseBonusApplicable && (
            <Col className='mt-3 mb-2 d-flex align-items-center'>
              <Trigger message='Import .csv with column title email and email ids are mandatory.' id={'csvFileInput'} />
              <Button
                variant='secondary'
                // style={{ marginLeft: '10px' }}
                className='ml-4 me-4'
                onClick={handleReplaceCsvClick}
                type='button'
                id={'csvFileInput'}
              >
                Import User CSV
              </Button>
              <input
                type='file'
                accept='.csv'
                ref={fileInputRef}
                onChange={handleImportChange}
                style={{ display: 'none' }} // Hide the file input
              />

              <>
                {packageData?.userDetails && packageData?.userDetails?.length > 0 && (
                  <>
                    <Trigger message='Download only email IDs as a CSV ' id={'csv'} />
                    <Button
                      id={'csv'}
                      variant='success'
                      disabled={packageData?.userDetails?.length === 0}
                      onClick={handleDownloadClick}
                    >
                      {downloadInProgress ? (
                        <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                      ) : (
                        <FontAwesomeIcon icon={faFileDownload} />
                      )}
                    </Button>
                  </>
                )}
              </>
            </Col>
          )}

          <Col className='mt-3  mb-2 d-flex  align-items-center' style={{ justifyContent: 'flex-end' }}>
            <Button variant='warning' onClick={() => navigate(AdminRoutes.Packages)} className='m-2 btn btn-secondary '>
              {t('createPackage.cancelButton')}
            </Button>

            <Button variant='success' onClick={handleSubmit} className='  ml-4' disabled={loading}>
              {loading ? (
                <Spinner as='span' animation='border' role='status' aria-hidden='true' />
              ) : (
                <>{t('createPackage.submitButton')}</>
              )}
            </Button>
          </Col>
        </Row>

        {csvPlayerData && csvPlayerData?.length > 0 && (
          <>
            <p>
              <b>Note: </b>{' '}
              <span style={{ color: 'red' }}>
                To retain existing user records, download the CSV, add user email IDs, and re-import it.
              </span>
            </p>
            <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
              <thead className='thead-dark'>
                <tr>
                  {['USER ID', 'USERNAME', ' EMAIL ID', 'IS ACTIVE'].map((h) => (
                    <th key={h}>{h}</th>
                  ))}
                </tr>
              </thead>
              {uploadCSVLoading ? (
                <tr>
                  <td colSpan={10} className='text-center'>
                    <InlineLoader />
                  </td>
                </tr>
              ) : (
                <tbody>
                  {csvPlayerData?.map((user, index) => {
                    return (
                      <tr key={user?.userId}>
                        <td>{user?.userId}</td>
                        <td>{user?.username || 'NA'}</td>
                        <td>{user?.email}</td>
                        <td>{user?.isActive ? 'Yes' : 'No'}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </>
        )}
      </Form>

      {deleteModalShow && (
        <DeleteConfirmationModal
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
          handleDeleteYes={handleDeleteYes}
          loading={deleteftploading}
        />
      )}
      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          loading={statusFtploading}
        />
      )}

      {importModalShow && (
        <ImportPackageCsvModal
          setShow={setImportModalShow}
          show={importModalShow}
          handleYes={handleCSVSumbit}
          loading={uploadCSVLoading}
          importedFile={importedFile}
        />
      )}
    </>
  );
};

export default PackageCreateForm;
