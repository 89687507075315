import React from 'react';
import { InputGroup, Form } from '@themesberg/react-bootstrap';
export default function SearchBar({ search, setSearch }) {
  return (
    <InputGroup className='mb-3 shadow-sm '>
      <InputGroup.Text>
        <img src='/svg/magnifying-glass.svg' alt='Search Icon' />
      </InputGroup.Text>
      <Form.Control
        type='search'
        value={search}
        placeholder='Search Player'
        onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
      />
    </InputGroup>
  );
}
