import React, { useState } from "react";

import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
  Modal,
  ProgressBar,
  Tabs,
  Tab,
} from "@themesberg/react-bootstrap";

import {
  formatDateMDY,
  getDateTime,
  getDateTimeByYMD,
} from "../../../utils/dateFormatter";
import Datetime from "react-datetime";
import { serialize } from "object-to-formdata";
import { toast } from "../../../components/Toast";
import {
  errorHandler,
  useCreateCRMPromotionMutation,
  useCreatePromotionMutation,
  useCreateRaffleMutation,
  useGetRandomPromoCodeMutation,
} from "../../../reactQuery/hooks/customMutationHook/index.js";
import Trigger from "../../../components/OverlayTrigger/index.jsx";
import { AdminRoutes } from "../../../routes.js";
import { useNavigate } from "react-router-dom";
import { createCRMPromoSchema, createPromotionSchema } from "../schemas.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleRight,
  faGrinStars,
} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

const CreateCRMPromoCode = ({ data }) => {
  const navigate = useNavigate();
  const yesterday = new Date(Date.now());
  const [randomCode, setRandomCode] = useState("");
  const { mutate: createPromotionBonus, isLoading: createLoading } =
    useCreateCRMPromotionMutation({
      onSuccess: (res) => {
        toast("Promotion Bonus Created Successfully", "success");
        navigate(AdminRoutes.CRMPromoCode);
      },
      onError: (error) => {
        errorHandler(error);
      },
    });

  const handleCreatePromotionBonusSubmit = (formValues) => {
    const body = {
      ...formValues,
      promocode: formValues.promocode,
      name: formValues.name,
      claimBonus: true,
      promotionType: formValues.promotionType,
      scAmount: formValues.scAmount,
      gcAmount: formValues.gcAmount,
      isActive: formValues.isActive,
    };
    createPromotionBonus(body);
  };

  return (
    <div>
      <Row>
        <Col sm={8}>
          <h3>Create CRM Promo Codes</h3>
        </Col>
      </Row>

      <Formik
        initialValues={{
          
          promocode: randomCode,
          name:'',
          claimBonus:true,
          promotionType:'scheduled-campaign',
          scAmount: '',
          gcAmount: '', 
          isActive:false,
          
        }}
        enableReinitialize
        validationSchema={createCRMPromoSchema}
        onSubmit={handleCreatePromotionBonusSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <Row>
              <Col>
                <BForm.Label>Promocode</BForm.Label>
                <Row>
                  <Col>
                    <BForm.Control
                      type="text"
                      name="promocode"
                      min="0"
                      value={values.promocode}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <ErrorMessage
                  component="div"
                  name="promocode"
                  className="text-danger"
                />
              </Col>
              <Col >
                <BForm.Label>Name</BForm.Label>

                <BForm.Control
                  type="text"
                  name="name"
                  min="0"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="name"
                  className="text-danger"
                />
              </Col>

              <Col>
                <BForm.Label>
                  Promotion Type
                  <span className="text-danger"> *</span>
                </BForm.Label>
                <BForm.Select
                  type="text"
                  name={"promotionType"}
                  value={values.promotionType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {
                    <>
                      <option key={"scheduled"} value={"scheduled-campaign"}>
                        Scheduled
                      </option>
                      <option key={"triggered"} value={"triggered-campaign"}>
                        Triggerred
                      </option>
                    </>
                  }
                </BForm.Select>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <BForm.Label>Bonus Sc</BForm.Label>

                <BForm.Control
                  type="number"
                  name="scAmount"
                  min="0"
                  value={values.scAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="scAmount"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Bonus Gc</BForm.Label>

                <BForm.Control
                  type="number"
                  name="gcAmount"
                  min="0"
                  value={values.gcAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="gcAmount"
                  className="text-danger"
                />
              </Col>
              <Col className="mt-3">
                <BForm.Label>Is Active</BForm.Label>

                <BForm.Check
                  type="switch"
                  name="isActive"
                  checked={values.isActive}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component="div"
                  name="claimBonus"
                  className="text-danger"
                />
              </Col>
            </Row>
           
            <div className="mt-4 d-flex justify-content-between align-items-center">
              <Button
                variant="warning"
                onClick={() => navigate(AdminRoutes.CRMPromoCode)}
              >
                Cancel
              </Button>

              <Button
                variant="success"
                onClick={() => {
                  handleSubmit();
                }}
                className="ml-2"
                disabled={createLoading}
              >
                Submit
                {createLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateCRMPromoCode;
