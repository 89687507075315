import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Col, Row, Form as BForm, Button, Spinner } from '@themesberg/react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  errorHandler,
  useRedeemRuleMutation,
  useUpdateRedeemRuleMutation,
} from '../../../reactQuery/hooks/customMutationHook/index.js';
import { toast } from '../../../components/Toast/index.jsx';

import { AdminRoutes } from '../../../routes.js';
import { createRulesSchema, validationSchema } from '../schemas.js';
import { redeemRules } from '../constants.js';
import {
  faCheckSquare,
  faEdit,
  faEye,
  faCancel,
  faWindowClose,
  faTrash,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConditionTree from './Card.jsx';

import { Queries } from './Queries.jsx';
import { sendUploadedCsv } from '../../../utils/apiCalls.js';

const transformToOperatorConditions = (data) => {
  if (!data) return null;

  const transformed = {};

  if (data.combinator) {
    transformed.operator = data.combinator;
  }

  if (data.rules) {
    transformed.conditions = data.rules.map((rule) => transformToOperatorConditions(rule));
  } else {
    transformed.field = data.field;
    transformed.value = data.value;
    transformed.operator = data.operator;
  }

  return transformed;
};

const transformToCombinatorRules = (data) => {
  if (!data) return null;

  const transformed = {};

  if (data.operator && (data.operator === 'and' || data.operator === 'or')) {
    transformed.combinator = data.operator;
  }

  if (data.conditions) {
    transformed.rules = data.conditions.map((condition) => transformToCombinatorRules(condition));
  } else {
    transformed.field = data.field;
    transformed.value = data.value;
    transformed.operator = data.operator;
  }

  return transformed;
};
const CreateRedeemRule = (editdata) => {
  const navigate = useNavigate();
  const [ruleName, setRuleName] = useState('');
  const [completionTime, setCompletionTime] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [ruleConditon, setRuleConditon] = useState({});

  const { mutate: createRedeemRule, isLoading: createLoading } = useRedeemRuleMutation({
    onSuccess: () => {
      toast('Rules Created Successfully', 'success');
      navigate(AdminRoutes.RedeemRulelisting);
    },
    onError: (error) => {
      errorHandler(error);
    },
  });
  const { mutate: editRedeemRule, isLoading: editloading } = useUpdateRedeemRuleMutation({
    onSuccess: () => {
      toast('Rule updated Successfully', 'success');

      navigate(AdminRoutes.RedeemRulelisting);
    },
    onError: (errors) => {
      toast(errors.description, 'error');
      errorHandler(errors);
    },
  });

  const tempdata = editdata?.tempdata;

  useEffect(() => {
    if (tempdata) {
      setRuleName(tempdata?.ruleName);
      setCompletionTime(tempdata?.completionTime);
      setRuleConditon(transformToCombinatorRules(tempdata?.ruleCondition));
      setIsActive(tempdata?.isActive);
    }
  }, [tempdata]);
  const handleCreateRedeemRulesSubmit = (formValues) => {
    if (formValues.ruleCondition.rules.length === 0) {
      toast('Please add atleast one conditon', 'warning');

      return;
    }

    const body = {
      ruleCondition: transformToOperatorConditions(ruleConditon),
      ruleName: ruleName,
      completionTime: completionTime,
      isActive: isActive,
    };
    if (tempdata) {
      editRedeemRule({
        ruleId: tempdata.ruleId,
        ruleCondition: transformToOperatorConditions(ruleConditon),
        ruleName: ruleName,
        completionTime: completionTime,
        isActive: isActive,
      });
    } else {
      createRedeemRule(body);
    }
  };

  return (
    <div>
      <Row>
      
        <Col  sm={8}>
                  <h3>{tempdata ? "Edit Redeem Rule" : "Create Redeem Rule"} </h3>
                </Col>
      </Row>

      <Formik
        initialValues={{
          ruleName: tempdata ? tempdata?.ruleName : '',
          isActive: tempdata ? tempdata?.isActive : false,
          ruleCondition: tempdata ? transformToCombinatorRules(tempdata?.ruleCondition) : {},
          completionTime: tempdata ? tempdata?.completionTime : '',
        }}
        validationSchema={validationSchema}
        onSubmit={(formData, { resetForm }) => {
          handleCreateRedeemRulesSubmit(formData);
          resetForm();
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur, setFieldValue, resetForm }) => (
          <Form>
            <div className='mt-5'>
              <Queries
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                handleSubmit={handleSubmit}
                createLoading={createLoading}
                tempdata={tempdata}
                ruleName={ruleName}
                setRuleName={setRuleName}
                completionTime={completionTime}
                setCompletionTime={setCompletionTime}
                isActive={isActive}
                setIsActive={setIsActive}
                ruleConditon={ruleConditon}
                setRuleConditon={setRuleConditon}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateRedeemRule;
