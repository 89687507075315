import { deleteParamsRequest, deleteRequest, getRequest, patchRequest, postRequest, putRequest } from './axios';

const { REACT_APP_API_URL, REACT_APP_CRON_URL, REACT_APP_CRON_AUTH } = process.env;

// Get request
const getAllPlayers = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/`, params);
const getAffiliatesList = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/affiliate/all-affiliates`, params);
const getAffiliatesPlayerList = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/affiliate/affiliate-users`, params);
const getPlayerById = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/detail`, params);
const adminRoles = () => getRequest(`${REACT_APP_API_URL}/api/v1/admin/roles`);
const getSiteConfig = () => getRequest(`${REACT_APP_API_URL}/api/v1/admin/config`);
const getStaffGroups = () => getRequest(`${REACT_APP_API_URL}/api/v1/admin/group`);
const getAllAdmins = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/admin/`, params);
const getAdminDetails = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/admin/detail`, params);
const getEmailCenter = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/email-center`, params);
const getEditEmailCenter = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/email-center`, params);

const getAffiliateDetails = (params) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/affiliate/affiliate-details?affiliateId=${params.affiliateId}`);
const getAdminChildren = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/admin/child`, params);
const getUserDocumentsRequest = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/document`, params);
const getPackagesListingRequest = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/package`, params);
const getPackageUserFilter = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/package/user-filter`, params);
const getAllCms = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/cms/`, params);
const getCmsDetail = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/cms/details`, params);
const getCmsDynamicData = () => getRequest(`${REACT_APP_API_URL}/api/v1/cms/dynamic-data`);
const getGallery = () => getRequest(`${REACT_APP_API_URL}/api/v1/gallery`);
const getEmailTemplates = () => getRequest(`${REACT_APP_API_URL}/api/v1/email`);
const getEmailTemplateDetail = ({ emailTemplateId }) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/email/details/${emailTemplateId}`);
const getEmailDynamicData = () => getRequest(`${REACT_APP_API_URL}/api/v1/email/dynamic-data`);
const getCountries = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/country`, params);
const getAllCasinoProviders = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/casino/providers`, params);
const getAllTournamentProviders = () => getRequest(`${REACT_APP_API_URL}/api/v1/tournament/providers`);
const getAllTournamentSubCategories = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/tournament/subcategory`);

const getUserAllCasinoProviders = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/providers`, params);
const getCasinoAggregators = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/casino/aggregator`, params);
const getAllBanners = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/banner`, params);
const getAllCasinoCategories = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/casino/category`, params);
const getAllTierList = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/tiers`, params);
const getAllTierListParams = ({ tierId }) => getRequest(`${REACT_APP_API_URL}/api/v1/tier/${tierId}`);
const getAllTierUserListParams = ({ tierId, params }) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/tier/${tierId}/users`, params);
const getAllCasinoSubCategories = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/casino/subcategory`, params);
const getRestrictedCountries = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/country/restricted`, params);
const getUnrestrictedCountries = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/country/unrestricted`, params);
const getEmailCategoryData = () => getRequest(`${REACT_APP_API_URL}/api/v1/email/category`);
const getDynamicEmailKeyData = () => getRequest(`${REACT_APP_API_URL}/api/v1/email/dynamic-data`);
const getBonusDetail = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/bonus/`, params);
const getLiveUsersCount = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/report/dashboard`, params);
const getReports = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/report`, params);
const getReportsAll = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/report/all`, params);
const getGameReport = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/report/dashboard/game`, params);
const elasticCheck = () => getRequest(`${REACT_APP_API_URL}/api/v1/elastic/healthcheck`);
const getPlayerResponsible = (params) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/user/user-responsible-setting`, params);
const getPlayerBankRequest = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/bank-details`, params);
const getPlayerCasinoRequest = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/casino-detail`, params);
const getAllCasinoGames = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/casino/game`, params);
const getCasinoSubcategoryGames = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/casino/game`, params);
const getRestrictedItems = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/country/restricted/items`, params);
const getUnRestrictedItems = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/country/unrestricted/items`, params);
const getBonusData = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/bonus/`, params);
const getAllTransactions = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/casino-transactions`, params);
const getBankingTransactions = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/transactions`, params);
const getVaultData = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/vault-data`, params);
const getAmoeData = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/amoe/history`, params);
const getAmoeDashboardData = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/amoe/graph-data`, params);
const getAdminAddedCoins = (params) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/admin-credit/admin-credit-coins`, params);
const getUserCreditedByAdmin = (params) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/admin-credit/admin-credit-user`, params);
const getWithdrawRequests = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-requests`, params);
const getUserWithdrawRequests = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-requests`, params);
const getSessionLogs = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/report/session-logs`, params);
const getAuditLogs = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/activity-logs`, params);
const getRSGList = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/user-responsible-setting`, params);
const getStateListing = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/country/get-state`);
const getCityListing = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/country/get-city`, params);
const amoeSearch = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/postal-code/`, params);
const amoeHistory = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/postal-code/history`, params);
const getCommsLogs = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/email-comms-details`, params);
const getActivityTable = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/user-activity`, params);
const getReferralDetails = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/referred-users-detail`, params);
const getUserTickets = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/alert/user-tickets`, params);
const getAgents = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/alert/agent-overview`, params);
const getRules = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/anti-fraud/rules`, params);
const generate2FA = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/admin/generate-otp-2fa`, params);
const getPlayerGroups = () => getRequest(`${REACT_APP_API_URL}/api/v1/anti-fraud/player-group`);
const getAdminsForAlert = () => getRequest(`${REACT_APP_API_URL}/api/v1/alert/admins`);
const getContentPages = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/pages`, params);
const getContentPageDetails = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/pages/details`, params);
const getIps = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/allowed_ip`, params);
const getKYCHistory = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/user-kyc`, params);
const getRaffle = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/raffle`, params);
const getRaffleDetail = (id) => getRequest(`${REACT_APP_API_URL}/api/v1/raffle/details?raffleId=${id}`);
const getRafflePayout = (id) => getRequest(`${REACT_APP_API_URL}/api/v1/rafflePayout?raffleId=${id}`);
const getPayoutUser = (id) => getRequest(`${REACT_APP_API_URL}/api/v1/rafflePayout/${id}`);
const getPayoutUserSearch = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/rafflePayout/search`, params);
const getPromotionBonus = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/promotion`, params);
const getPromoCode = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/promocode`, params);
const getCrmPromoCode = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/promocode?crmPromocode=true`, params);
const getCrmPromoBonus = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/crm-promotion`, params);
const getPromotionBonusDetail = (id) => getRequest(`${REACT_APP_API_URL}/api/v1/promotion/${id}`);
const getPromoCodeHistory = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/promocode/applied-history`, params);
const getCRMPromoCodeHistory = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/promocode/expired-promo`, params);
const getCRMPromoBonusEditHistory = (id) => getRequest(`${REACT_APP_API_URL}/api/v1/crm-promotion/${id}`);
const getCRMPromoBonusHistory = (params) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/crm-promotion/expired-bonus`, params);
const getCRMBonusUserDetailsapi = (params) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/crm-promotion/user-details`, params);
const getPackageDetails = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/promocode/packages`, params);
const getPackageHistory = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/package/user-details`, params);
const getRedeemUserDetails = (params) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-rule/user-details`, params);
const getRedeemDashboardData = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-dashboard`, params);
const getRedeemWithdrawRequest = (params) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-rule/withdraw-details`, params);
const getAutomationPackageDetails = (params) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/package/getSubPackages`, params);
const getArchivePackageHistory = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/package/restore-package`, params);
const getRandomPromoCode = () => getRequest(`${REACT_APP_API_URL}/api/v1/promotion/generate`);
const getSpinWheel = () => getRequest(`${REACT_APP_API_URL}/api/v1/bonus/spin-wheel`);
const getCRMbonusDetail = (id) => getRequest(`${REACT_APP_API_URL}/api/v1/crm-promotion/bonus-details`);
const getSpinWheelId = (id) => getRequest(`${REACT_APP_API_URL}/api/v1/bonus/spin-wheel/${id}`);
const getAllCSVExportData = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/exportCenter/getExportList`, params);
const getUserBanReasonRequest = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/ban-reason`, params);
const getUserBanStatusRequest = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/user-ban-reason`, params);
const getUserBanReasonDetail = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/user/ban-reason/detail`, params);
const fetchPromocodeBlocked = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/blockUsers`, params);
const getRedeemMoreDetail = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-details`, params);
const getRedeemRuleDetail = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-rule`, params);
//const getRedeemReport = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-report`, params)
const getSkrillBalance = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/skrill-balance`, params);
const getAllowedStateListing = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/geo-comply/allowed-states`);
const getBlockedUser = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/geo-comply/block-domain`, params);
const getAllTournamentsList = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/tournament`, params);
const getAllTournamentsListParams = (params, { tournamentId }) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/tournament/${tournamentId}`, params);
const getPayoutTournamentsData = ({ tournamentId }) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/tournament/${tournamentId}/payout`);
const getTournamentGames = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/tournament/games`, params);
const getTournamentUserList = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/tournament/user`, params);

const getAllTierTournamentList = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/tournament/user/tiers`);
const getTournamentDashboard = ({ tournamentId }) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/tournament/${tournamentId}/dashboard`);
const getTournamentDashboardGameIds = ({ tournamentId }) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/tournament/${tournamentId}/dashboard/stats/filters`);
const getTournamentDashboardTotalPlayer = (params, { tournamentId }) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/tournament/${tournamentId}/dashboard/total-score-players-count`, params);
const getTournamentDashboardWinnerBootedSummary = ({ tournamentId }) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/tournament/${tournamentId}/dashboard/winner-booted-summary`);
const getGamesIdsRequest = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/casino/games`);
const getGamesPayment = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/payment/games`);
const getTournamentStatisticsData = (params, { tournamentId }) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/tournament/${tournamentId}/dashboard/stats`, params);
const getWhaleTestNotification = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/notifications-test`, params);
const getAllNotifications = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/admin-notification-center`, params);
const getNotificationCenterSettings = (params) =>
  getRequest(`${REACT_APP_API_URL}/api/v1/admin-notification-center/settings`, params);
const getDailyBonusStreak = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/bonus/`, params);
const getVipPlayerListing = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/vip`, params);
const getVipPlayerDetails = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/vip/detail`, params);
const getVipPlayerReport = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/vip/user-report`, params);
const getBiggestWinnerAndLooser = () => getRequest(`${REACT_APP_API_URL}/api/v1/vip/biggest-looser-winner`);
const getVipDashboardReport = () => getRequest(`${REACT_APP_API_URL}/api/v1/vip/dashboard-report`);
const getVipLoyaltyTier = () => getRequest(`${REACT_APP_API_URL}/api/v1/vip/loyalty-tier`);
const getVipUserQuestions = () => getRequest(`${REACT_APP_API_URL}/api/v1/vip/questionnaire`);
const getVipUserAnswers = (params) => getRequest(`${REACT_APP_API_URL}/api/v1/vip/user-questionnaire-answer`, params);

// Post request
const createFtpBonus = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/package/first-purchase`, data);
const adminLogin = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/admin/login`, data);
const createEmailCenter = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/email-center`, data);
const createRedeemRule = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-rule`, data);
const sendEmailTest = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/email-center/email-sent`, data);
const sendUploadedCsv = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/email-center/upload-email-csv`, data, {
    'Content-Type': 'multipart/formdata',
  });
const sendUploadedRedeemCsv = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-rule/users-from-csv`, data, {
    'Content-Type': 'multipart/formdata',
  });
const AffiliateLogin = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/affiliate/login`, data);
const setUserBanReason = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/user/ban-reason`, data);
const adminLogout = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/admin/logout`, data);
const verify2FA = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/admin/verify-otp-2fa`, data);
const disable2FA = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/admin/disable-auth`, data);
const createStaffAdmin = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/admin/`, data);

const updateAllowedStates = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/geo-comply/store-states`, data);
const setDailyLimits = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/user/daily-limit`, data);
const setDepositLimits = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/user/deposit-limit`, data);
const setLossLimits = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/user/loss-limit`, data);
const setSessionTime = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/user/session-time`, data);
const setDisableUntil = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/user/disable-until`, data);
const createPackageRequest = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/package`, data, { 'Content-Type': 'multipart/formdata' });
const createLadderPackageRequest = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/package/ladder-package`, data, { 'Content-Type': 'multipart/formdata' });
const packageAutomationRequest = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/package/create-config`, data);
const createCms = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/cms/`, data);
const testEmailTemplate = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/email/test`, data);
const createCasinoProvider = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/casino/providers`, data, { 'Content-Type': 'multipart/formdata' });
const addGames = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/casino/add-game`, data, { 'Content-Type': 'multipart/formdata' });
const createBanner = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/banner`, data, { 'Content-Type': 'multipart/formdata' });
const createCasinoCategory = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/casino/category`, data);
const createTournament = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/tournament`, data, { 'Content-Type': 'multipart/formdata' });
const createTier = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/tier`, data, { 'Content-Type': 'multipart/formdata' });
const createTournamentCron = (data) =>
  postRequest(`${REACT_APP_CRON_URL}/api/v1/tournament`, data, { Authorization: `Basic ${REACT_APP_CRON_AUTH}` });
const createBlockingDomain = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/geo-comply/block-domain`, data);
const createCasinoSubCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/casino/subcategory`, data, { 'Content-Type': 'multipart/formdata' });
const addGamestoSubCategory = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/casino/game`, data);
const createEmailTemplate = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/email/template`, data);
const uploadrubyPlayGames = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/ruby-play`, data, { 'Content-Type': 'multipart/formdata' });
const createBonus = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/bonus`, data);
const createDailyBonus = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/bonus`, data, { 'Content-Type': 'multipart/formdata' });
const updateResponsibleStatus = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/user/update-user-responsible-setting`, data);
const uploadAmoeFile = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/postal-code/`, data, { 'Content-Type': 'multipart/formdata' });
const addComments = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/user/comment/`, data);
const assignTicket = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/alert/assign-ticket`, data);
const createPlayerGroup = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/anti-fraud/player-group`, data, { 'Content-Type': 'multipart/formdata' });
const createRule = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/anti-fraud/create-rule`, data);
const createContentPage = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/pages`, data);
const updateSeoDetails = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/pages/seo`, data);
const addPageAsset = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/pages/asset`, data, { 'Content-Type': 'multipart/formdata' });
const createIP = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/allowed_ip`, data);
const checkManualLexisNexis = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/user/process-lexis-nexis`, data);
const createAffiliateUser = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/affiliate/create-affiliate`, data);
const createRaffle = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/raffle`, data, { 'Content-Type': 'multipart/formdata' });
const createPromotionBonus = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/promotion`, data);
const createPromoCode = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/promocode`, data);
const createCRMPromotionBonus = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/crm-promotion`, data);
const reusePackageRequest = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/package/template-package`, data);
const update2FaAuthStatus = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/user/disable-2FA`, data);
const createReferralBonus = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/bonus/referral-bonuses`, data);
const updatePromocodeBlocked = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/blockUsers`, data);
const uploadPromocodeCsv = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/blockUsers/block-from-csv`, data, { 'Content-Type': 'multipart/formdata' });
const uploadPackageCsv = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/package/import-csv`, data, { 'Content-Type': 'multipart/formdata' });
const uploadTournamentCsv = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/tournament/upload-csv`, data, { 'Content-Type': 'multipart/formdata' });
const addFreeEntryOfPlayer = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/tournament/free-entry`, data);
const payoutTournamentPlayers = (data) => {
  const tournamentId = data?.tournamentId;
  return postRequest(`${REACT_APP_API_URL}/api/v1/tournament/${tournamentId}/payout`, data);
};
const reusePromocodeRequest = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/promocode/reuse-promocode`, data);
const setNotificationCenterSettings = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/admin-notification-center/settings`, data);

const dailyBonusStreak = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/v1/bonus/streak-daily-bonus`, data, { 'Content-Type': 'multipart/formdata' });
// const updateVipUserQuestionnaire = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/vip/questionnaire`, data);
const updateVipUserAnswers = (data) => postRequest(`${REACT_APP_API_URL}/api/v1/vip/user-questionnaire-answer`, data);

// Put Request
const deleteRedeemUser = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-rule/remove-users`, data);
const updateEmailCenter = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/email-center`, data);
const updateUserTier = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/admin/update-user-tier`, data);
const restorepackages = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/package/restore-package`, data);
const updateftpBonus = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/package/first-purchase`, data);
const createAffiliatePassword = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/affiliate/set-Password`, data);
const updateMoney = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/admin/add-remove-balance`, data);
const updateStaffMoney = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/walletCoin/add-remove-balance`, data);
const updateVerifyDocumentRequest = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/verify-document`, data);
const updateRequestDocumentRequest = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/request-document`, data);
const cancelDocumentRequest = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/cancel-document-request`, data);
const updatePackageRequest = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/package`, data, { 'Content-Type': 'multipart/formdata' });
const updateStatus = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/status/`, data);
const updateAggregatorsStatus = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/casino/aggregator/`, data);
const updateCms = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/cms/`, data);
const approvedAffiliateUser = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/affiliate/approved-affiliate`, data);
const updateStaffAdmin = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/admin/`, data);
const updateProfile = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/admin/profile`, data);
const ChangeAffiliatePassword = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/affiliate/changePassword`, data);
const updateAffiliateProfile = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/affiliate/affiliate-profile`, data);
const updateEmailTemplate = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/email/`, data);
const updateCreds = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/email/credentials`, data);
const updateConfig = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/admin/config`, data, { 'Content-Type': 'multipart/formdata' });
const updateCasinoProvider = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/casino/providers`, data, { 'Content-Type': 'multipart/formdata' });
const updateBanner = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/banner`, data, { 'Content-Type': 'multipart/formdata' });
const updateCasinoCategory = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/casino/category`, data);
const updateTournaments = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/tournament`, data, { 'Content-Type': 'multipart/formdata' });
const updateTier = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/tier`, data, { 'Content-Type': 'multipart/formdata' });
const updateTournamentsCron = (data) =>
  putRequest(`${REACT_APP_CRON_URL}/api/v1/tournament`, data, { Authorization: `Basic ${REACT_APP_CRON_AUTH}` });
const reorderCasinoCategory = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/casino/category/order`, data);
const reorderCasinoProvider = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/casino/providers/order`, data);
const updateCasinoSubCategory = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/casino/subcategory`, data, { 'Content-Type': 'multipart/formdata' });
const updateRestrictedCountries = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/country/restricted/items`, data);
const reorderCasinoSubCategory = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/casino/subcategory/order`, data);
const updateCasinoGame = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/casino/game`, data, { 'Content-Type': 'multipart/formdata' });
const reorderCasinoSubCategoryGames = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/casino/games/order`, data);
const updateRestrictedItems = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/country/restricted`, data);
const updateManualTemplate = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/email/template`, data);
const updateBonus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/bonus`, data, { 'Content-Type': 'multipart/formdata' });
const updateBonusStatus = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/status`, data);
const updateWithdrawRequest = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-requests`, data);
const reorderPackages = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/package/order`, data);
const reorderFtpBonus = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/package/first-purchase/order`, data);
const updateUserStatus = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/update-user-status`, data);
const updateRemovePwLock = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/remove-pw-lock`, data);
const updateSocialSecurity = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/update-ssn`, data);
const addPlayerBankDetail = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/bank-details`, data);
const updatePlayerInfo = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/update-user`, data);
const addFavActivityLog = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/favorite-log`, data);
const updatePlayerPwd = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/update-password`, data);
const playerForceLogout = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/force-logout`, data);
const uploadUserDocs = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/user/document`, data, { 'Content-Type': 'multipart/formdata' });
const resolveTicket = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/alert/user-tickets`, data);
const updateRuleStatus = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/anti-fraud/update-rules`, data);
const updateContentPage = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/pages`, data);
const updatePageAsset = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/pages/asset`, data, { 'Content-Type': 'multipart/formdata' });
const paymentRefund = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/payment/refund`, data);
const updateUSerKYC = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/user-kyc`, data);
const resetUserResponsibleSetting = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/user/reset-user-responsible-setting`, data);
const updateRaffle = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/raffle`, data, { 'Content-Type': 'multipart/formdata' });
const rafflePayout = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/rafflePayout/payout`, data);
const updatePromotion = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/promotion`, data);
const updatePromoCode = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/promocode`, data);
const updateCRMPromotion = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/crm-promotion`, data);
const updateSpinWheel = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/bonus/spin-wheel`, data);
const deleteUsername = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/delete-username`, data);
const verifyOtp = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/admin/verify-otp-2fa`, data);
const updateUserBanReason = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/ban-reason`, data);
const updateUserBanStatus = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/user/update-user-ban-status`, data);
const updateAmoeBonusTime = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/amoe/bonus-time`, data);
const updateRedeemRule = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-rule`, data);
const approveRedeemRequests = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/payment/approve-redeem-requests`, data);
const updateBlockedDomain = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/geo-comply/block-domain`, data);
const reorderTournaments = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/tournament/order`, data);
const markNotificationStatus = (id) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/admin-notification-center/mark-read/${id}?markRead=true`);
const markAllNotificationStatus = (id) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/admin-notification-center/mark-all-read?markRead=true`);
const updateDailyBonusStreak = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/v1/bonus`, data, { 'Content-Type': 'multipart/formdata' });

const updateUserVipStatus = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/vip`, data);
const updateLoyaltyTier = (data) => putRequest(`${REACT_APP_API_URL}/api/v1/vip/loyalty-tier`, data);

// Delete Request
const deleteEmailCenter = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/email-center`, data);
const deleteImage = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/gallery`, data);
const deleteCasinoCategory = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/casino/category`, data);
const deleteTournament = (params) => deleteParamsRequest(`${REACT_APP_API_URL}/api/v1/tournament`, params);
const deleteTier = (params) => deleteParamsRequest(`${REACT_APP_API_URL}/api/v1/tier`, params);
const deleteCasinoSubCategory = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/casino/subcategory`, data);
const deleteRestrictedCountries = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/country/restricted`, data);
const deleteCasinoGame = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/casino/game`, data);
const deleteRestrictedItem = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/country/restricted/items`, data);
const deleteBonus = (params) => deleteParamsRequest(`${REACT_APP_API_URL}/api/v1/bonus/`, params);
const deleteCms = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/cms/pages`, data);
const deleteProvider = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/casino/providers`, data);
const deleteStaff = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/admin`, data);
const deleteAffiliate = (affiliateId) =>
  deleteRequest(`${REACT_APP_API_URL}/api/v1/affiliate/${affiliateId}/delete-affiliate`);
const deleteEmailTemplete = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/email`, data);
const deleteBanner = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/banner`, data);
const deleteContentPage = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/pages`, data);
const deleteAsset = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/pages/asset`, data);
const deleteIP = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/allowed_ip`, data);
const deletePromotion = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/promotion`, data);
const deletePromoCode = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/promocode`, data);
const deleteCRMPromoCode = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/crm-promotion`, data);
const deletePackageRequest = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/package`, data);
const deleteftpBonuses = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/package/first-purchase`, data);
const deleteUserBanReason = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/user/ban-reason`, data);
const deleteRedeemRule = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-rule`, data);
const deleteBlockedDomain = (data) => deleteRequest(`${REACT_APP_API_URL}/api/v1/geo-comply/block-domain`, data);
//Patch Request
const fetchWithdrawRequests = (data) => patchRequest(`${REACT_APP_API_URL}/api/v1/payment/redeem-requests`, data);
const updateStatusTournament = (data) => patchRequest(`${REACT_APP_API_URL}/api/v1/tournament`, data);
const updateStatusTier = (data) => patchRequest(`${REACT_APP_API_URL}/api/v1/tier`, data);
const updateStatusRaffle = (data) => patchRequest(`${REACT_APP_API_URL}/api/v1/raffle`, data);
const updateStatusPromotion = (data) => patchRequest(`${REACT_APP_API_URL}/api/v1/promotion`, data);
const updateStatusCRMPromotion = (data) => patchRequest(`${REACT_APP_API_URL}/api/v1/crm-promotion`, data);
const updateUserActive = (data) => patchRequest(`${REACT_APP_API_URL}/api/v1/user/ban-reason`, data);
const updateFtpStatus = (data) => patchRequest(`${REACT_APP_API_URL}/api/v1/package/first-purchase`, data);
const cancelTournament = (data) => patchRequest(`${REACT_APP_API_URL}/api/v1/tournament`, data);
const bootPlayerTournament = (data) => patchRequest(`${REACT_APP_API_URL}/api/v1/tournament/boot-player`, data);

export {
  sendEmailTest,
  sendUploadedCsv,
  getEditEmailCenter,
  createRedeemRule,
  sendUploadedRedeemCsv,
  getRedeemUserDetails,
  getRedeemDashboardData,
  getRedeemWithdrawRequest,
  deleteftpBonuses,
  updateFtpStatus,
  updateftpBonus,
  restorepackages,
  updateUserTier,
  createEmailCenter,
  getEmailCenter,
  updateEmailCenter,
  deleteEmailCenter,
  getAllTierUserListParams,
  deleteRedeemUser,
  getAllTierListParams,
  getAllTierList,
  createTier,
  updateTier,
  deleteTier,
  adminLogin,
  createAffiliateUser,
  approvedAffiliateUser,
  getAffiliatesList,
  getLiveUsersCount,
  adminLogout,
  updateProfile,
  getSiteConfig,
  updateConfig,
  getAdminChildren,
  adminRoles,
  getAllAdmins,
  getAllPlayers,
  getAdminDetails,
  getPlayerById,
  setDailyLimits,
  getCountries,
  setDepositLimits,
  setLossLimits,
  amoeSearch,
  amoeHistory,
  updateCreds,
  setSessionTime,
  setDisableUntil,
  updateMoney,
  updateStaffMoney,
  uploadAmoeFile,
  getUserDocumentsRequest,
  updateVerifyDocumentRequest,
  updateRequestDocumentRequest,
  cancelDocumentRequest,
  getPackagesListingRequest,
  createPackageRequest,
  createLadderPackageRequest,
  updatePackageRequest,
  getAllCms,
  createCms,
  updateCms,
  getCmsDetail,
  getCmsDynamicData,
  getGallery,
  deleteImage,
  updateStatus,
  getStaffGroups,
  createStaffAdmin,
  updateStaffAdmin,
  getEmailTemplates,
  getEmailTemplateDetail,
  getEmailDynamicData,
  updateEmailTemplate,
  testEmailTemplate,
  getAllCasinoProviders,
  createCasinoProvider,
  addGames,
  updateCasinoProvider,
  getAllBanners,
  createBanner,
  updateBanner,
  getAllCasinoCategories,
  createCasinoCategory,
  updateCasinoCategory,
  deleteCasinoCategory,
  reorderCasinoCategory,
  reorderCasinoProvider,
  getAllCasinoSubCategories,
  createCasinoSubCategory,
  updateCasinoSubCategory,
  deleteCasinoSubCategory,
  reorderCasinoSubCategory,
  getAllCasinoGames,
  deleteCasinoGame,
  updateCasinoGame,
  addGamestoSubCategory,
  createFtpBonus,
  getRestrictedCountries,
  getUnrestrictedCountries,
  updateRestrictedCountries,
  deleteRestrictedCountries,
  getCasinoSubcategoryGames,
  reorderCasinoSubCategoryGames,
  getRestrictedItems,
  getUnRestrictedItems,
  deleteRestrictedItem,
  updateRestrictedItems,
  getEmailCategoryData,
  createEmailTemplate,
  getDynamicEmailKeyData,
  updateManualTemplate,
  uploadrubyPlayGames,
  createBonus,
  getBonusData,
  getBonusDetail,
  updateBonus,
  updateBonusStatus,
  deleteBonus,
  deleteCms,
  deleteProvider,
  deleteStaff,
  deleteEmailTemplete,
  deleteBanner,
  deletePackageRequest,
  getAllTransactions,
  getBankingTransactions,
  getWithdrawRequests,
  updateWithdrawRequest,
  reorderPackages,
  getReports,
  getReportsAll,
  getGameReport,
  elasticCheck,
  createDailyBonus,
  updateUserStatus,
  getSessionLogs,
  getAuditLogs,
  getRSGList,
  getStateListing,
  getCityListing,
  getPlayerResponsible,
  updateResponsibleStatus,
  getPlayerBankRequest,
  addPlayerBankDetail,
  updatePlayerInfo,
  getPlayerCasinoRequest,
  addFavActivityLog,
  getCommsLogs,
  getActivityTable,
  getReferralDetails,
  updatePlayerPwd,
  updateRemovePwLock,
  updateSocialSecurity,
  addComments,
  playerForceLogout,
  uploadUserDocs,
  getUserTickets,
  assignTicket,
  resolveTicket,
  getAgents,
  getRules,
  verifyOtp,
  generate2FA,
  verify2FA,
  disable2FA,
  getPlayerGroups,
  createPlayerGroup,
  createRule,
  updateRuleStatus,
  getAdminsForAlert,
  getContentPages,
  createContentPage,
  updateContentPage,
  deleteContentPage,
  getContentPageDetails,
  updateSeoDetails,
  addPageAsset,
  updatePageAsset,
  deleteAsset,
  getIps,
  createIP,
  deleteIP,
  paymentRefund,
  getKYCHistory,
  updateUSerKYC,
  checkManualLexisNexis,
  createAffiliatePassword,
  AffiliateLogin,
  getAffiliatesPlayerList,
  ChangeAffiliatePassword,
  getAffiliateDetails,
  deleteAffiliate,
  updateAffiliateProfile,
  getCasinoAggregators,
  updateAggregatorsStatus,
  fetchWithdrawRequests,
  getAllTournamentsList,
  createTournament,
  deleteTournament,
  updateStatusTournament,
  updateStatusTier,
  getGamesIdsRequest,
  updateTournaments,
  getAllTournamentsListParams,
  createTournamentCron,
  updateTournamentsCron,
  resetUserResponsibleSetting,
  getRaffle,
  createRaffle,
  getRaffleDetail,
  updateRaffle,
  updateStatusRaffle,
  getRafflePayout,
  getPayoutUser,
  getPayoutUserSearch,
  rafflePayout,
  getPromotionBonus,
  createPromotionBonus,
  getPromotionBonusDetail,
  updatePromotion,
  updateStatusPromotion,
  deletePromotion,
  getUserWithdrawRequests,
  getUserAllCasinoProviders,
  getRandomPromoCode,
  getSpinWheel,
  updateSpinWheel,
  createPromoCode,
  getPromoCode,
  updatePromoCode,
  reusePromocodeRequest,
  deletePromoCode,
  getPromoCodeHistory,
  getPackageDetails,
  getPackageHistory,
  createReferralBonus,
  update2FaAuthStatus,
  getPackageUserFilter,
  reorderFtpBonus,
  getUserBanReasonRequest,
  getUserBanStatusRequest,
  getUserBanReasonDetail,
  setUserBanReason,
  updateUserBanReason,
  updateUserActive,
  deleteUserBanReason,
  updateUserBanStatus,
  deleteUsername,
  getVaultData,
  getRedeemMoreDetail,
  getRedeemRuleDetail,
  updateRedeemRule,
  deleteRedeemRule,
  approveRedeemRequests,
  getSkrillBalance,
  getAllowedStateListing,
  updateAllowedStates,
  getCrmPromoBonus,
  createCRMPromotionBonus,
  getCRMPromoBonusHistory,
  updateCRMPromotion,
  deleteCRMPromoCode,
  updateStatusCRMPromotion,
  getCRMbonusDetail,
  getAllCSVExportData,
  getAmoeData,
  getAmoeDashboardData,
  updateAmoeBonusTime,
  getBlockedUser,
  createBlockingDomain,
  deleteBlockedDomain,
  updateBlockedDomain,
  fetchPromocodeBlocked,
  updatePromocodeBlocked,
  getCrmPromoCode,
  getCRMPromoCodeHistory,
  getCRMPromoBonusEditHistory,
  getCRMBonusUserDetailsapi,
  uploadPromocodeCsv,
  reusePackageRequest,
  getArchivePackageHistory,
  getPayoutTournamentsData,
  getTournamentGames,
  getTournamentDashboard,
  addFreeEntryOfPlayer,
  payoutTournamentPlayers,
  reorderTournaments,
  cancelTournament,
  bootPlayerTournament,
  getTournamentStatisticsData,
  getAllTournamentProviders,
  getAllTournamentSubCategories,
  getTournamentDashboardGameIds,
  getTournamentDashboardTotalPlayer,
  getTournamentDashboardWinnerBootedSummary,
  getTournamentUserList,
  getWhaleTestNotification,
  getGamesPayment,
  getAllNotifications,
  markNotificationStatus,
  markAllNotificationStatus,
  getNotificationCenterSettings,
  setNotificationCenterSettings,
  dailyBonusStreak,
  getDailyBonusStreak,
  updateDailyBonusStreak,
  getAllTierTournamentList,
  uploadTournamentCsv,
  packageAutomationRequest,
  getAutomationPackageDetails,
  uploadPackageCsv,
  getAdminAddedCoins,
  getUserCreditedByAdmin,
  getVipPlayerListing,
  getVipPlayerDetails,
  getVipPlayerReport,
  getBiggestWinnerAndLooser,
  getVipDashboardReport,
  getVipLoyaltyTier,
  getVipUserQuestions,
  // updateVipUserQuestionnaire,
  getVipUserAnswers,
  updateVipUserAnswers,
  updateUserVipStatus,
  updateLoyaltyTier,
};
