import * as Yup from 'yup';
const today = new Date(Date.now());
export const uploadBannerSchema = (type, t,isNavigate) =>
  Yup.object().shape({
    // visibility: Yup.string().required(t('casinoBannerManagement.inputField.visibility.errors.required')),
    pageRoute: Yup.string().required("Page route required"),
    //name: Yup.string().required(t('casinoBannerManagement.inputField.name.errors.required')),
    bannerImage:
      type === 'Create'
        ? Yup.mixed().required(t('casinoBannerManagement.inputField.desktopImage.errors.required'))
          .test('File Size', t('casinoBannerManagement.inputField.desktopImage.errors.max'),
            (value) => !value || (value && value.size <= 1024 * 1024)
          )
          .test('FILE_FORMAT', t('casinoBannerManagement.inputField.desktopImage.errors.invalidFormat'),
            (value) =>
              !value ||
              (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type)))
        : Yup.mixed().test('File Size', t('casinoBannerManagement.inputField.desktopImage.errors.max'),
          (value) => !value || (value && value.size <= 1024 * 1024))
          .test('FILE_FORMAT', t('casinoBannerManagement.inputField.desktopImage.errors.invalidFormat'),
            (value) =>
              !value ||
              (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type)))
          .nullable(),
  //   mobileImage:
  // type === 'Create'
  //   ? Yup.mixed().required(t('casinoBannerManagement.inputField.mobileImage.errors.required'))
  //     .test('File Size', t('casinoBannerManagement.inputField.mobileImage.errors.max'),
  //       (value) => !value || (value && value.size <= 1024 * 1024)
  //     )
  //     .test('FILE_FORMAT', t('casinoBannerManagement.inputField.mobileImage.errors.invalidFormat'),
  //       (value) =>
  //         !value ||
  //         (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type)))
  //   : Yup.mixed().test('File Size', t('casinoBannerManagement.inputField.mobileImage.errors.max'),
  //     (value) => !value || (value && value.size <= 1024 * 1024))
  //     .test('FILE_FORMAT', t('casinoBannerManagement.inputField.mobileImage.errors.invalidFormat'),
  //       (value) =>
  //         !value ||
  //         (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type)))
  //     .nullable(),
    textOne: Yup.string().max(100, t("casinoBannerManagement.inputField.textOne.max")).nullable(),
    textTwo: Yup.string().max(100, t("casinoBannerManagement.inputField.textTwo.max")).nullable(),
    textThree: Yup.string().max(100, t("casinoBannerManagement.inputField.textThree.max")).nullable(),
    btnText: Yup.string().max(50, t("casinoBannerManagement.inputField.btnText.max")).nullable(),
 
  });
  // mobileImage:
  // type === 'Create'
  //   ? Yup.mixed().required(t('casinoBannerManagement.inputField.mobileImage.errors.required'))
  //     .test('File Size', t('casinoBannerManagement.inputField.mobileImage.errors.max'),
  //       (value) => !value || (value && value.size <= 1024 * 1024)
  //     )
  //     .test('FILE_FORMAT', t('casinoBannerManagement.inputField.mobileImage.errors.invalidFormat'),
  //       (value) =>
  //         !value ||
  //         (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type)))
  //   : Yup.mixed().test('File Size', t('casinoBannerManagement.inputField.mobileImage.errors.max'),
  //     (value) => !value || (value && value.size <= 1024 * 1024))
  //     .test('FILE_FORMAT', t('casinoBannerManagement.inputField.mobileImage.errors.invalidFormat'),
  //       (value) =>
  //         !value ||
  //         (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type)))
  //     .nullable(),