// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
  padding: 0 4px;
  border-radius: 5px;
  display: inline-block;
  color: #ffffff; }
  .status.completed {
    background-color: #4CAF50;
    border: 2px solid #388E3C; }
  .status.pending {
    background-color: #FFC107;
    border: 2px solid #FFA000;
    color: #000000; }
  .status.failed {
    background-color: #F44336;
    border: 2px solid #D32F2F; }
`, "",{"version":3,"sources":["webpack://./src/pages/ExportCenter/exportCenter.scss"],"names":[],"mappings":"AAAA;EACI,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,cAAc,EAAA;EAJlB;IAOQ,yBAAyB;IACzB,yBAAyB,EAAA;EARjC;IAYQ,yBAAyB;IACzB,yBAAyB;IACzB,cAAc,EAAA;EAdtB;IAkBQ,yBAAyB;IACzB,yBAAyB,EAAA","sourcesContent":[".status {\n    padding: 0 4px;        \n    border-radius: 5px;               \n    display: inline-block;    \n    color: #ffffff;         \n    // margin-top: 5px;\n    &.completed {\n        background-color: #4CAF50; \n        border: 2px solid #388E3C; \n    }\n\n    &.pending {\n        background-color: #FFC107; \n        border: 2px solid #FFA000; \n        color: #000000;           \n    }\n\n    &.failed {\n        background-color: #F44336; \n        border: 2px solid #D32F2F; \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
