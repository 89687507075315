import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getVipLoyaltyTier, getVipPlayerListing } from '../../../utils/apiCalls';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useUpdateUserVipStatus, useUpdateVipUserLoyaltyTier } from '../../../reactQuery/hooks/customMutationHook';
import { toast } from '../../../components/Toast';

const useVipPlayerListing = (ratingMin, ratingMax, vipStatusSearch) => {
  const queryClient = useQueryClient();
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [orderBy, setOrderBy] = useState('userId');
  const [sort, setSort] = useState('desc');
  const [tierUpdateModal, setTierUpdateModal] = useState(false);
  const [userTier, setUserTier] = useState('');
  const [userId, setUserId] = useState('');

  const {
    data: vipPlayerListing,
    isLoading,
    refetch: refetchPlayerList,
  } = useQuery({
    queryKey: ['vipPlayerListing', limit, page, orderBy, sort, debouncedSearch],
    queryFn: ({ queryKey }) => {
      const params = { limit: queryKey[1], pageNo: queryKey[2], orderBy: queryKey[3], sort: queryKey[4] };
      if (queryKey[5]) params.userNameSearch = queryKey[5];
      if (vipStatusSearch) params.vipStatusSearch = vipStatusSearch;
      if (ratingMin === 0 && ratingMax === 3) {
        (params.ratingMin = ratingMin), (params.ratingMax = ratingMax);
      }
      return getVipPlayerListing(params);
    },
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0,
  });

  const totalPages = Math.ceil(vipPlayerListing?.users?.count / limit);

  const { mutate: updateVipStatus, isLoading: isVipStatusUpdating } = useUpdateUserVipStatus({
    onSuccess: (res) => {
      toast(res?.data?.message, 'success');
      queryClient.invalidateQueries({
        queryKey: ['vipPlayerListing'],
      });
    },
    onError: (err) => {
      const error = err?.response?.data?.errors?.[0];
      console.log(error);
      toast(error?.description, 'error');
    },
  });

  const { mutate: updateLoyaltyTier, isLoading: isVipLoyaltyTierLoading } = useUpdateVipUserLoyaltyTier({
    onSuccess: (res) => {
      toast(res?.data?.message, 'success');
      queryClient.invalidateQueries({
        queryKey: ['vipLoyaltyTierUPdate'],
      });
      setTierUpdateModal(false);
      refetchPlayerList();
    },
    onError: (err) => {
      const error = err?.response?.data?.errors?.[0];
      console.log(error);
      toast(error?.description, 'error');
    },
  });

  const { data: vipLoyalTierData, isLoading: isVipLoyaltyTierDataLoading } = useQuery({
    queryKey: ['vipLoyalTierData'],
    queryFn: () => {
      return getVipLoyaltyTier();
    },
    select: (res) => res?.data,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleUpdateTier = (data) => {
    updateLoyaltyTier(data);
  };

  return {
    vipPlayerListing,
    isLoading,
    setLimit,
    setPage,
    page,
    setSearch,
    setOrderBy,
    setSort,
    totalPages,
    limit,
    updateVipStatus,
    isVipStatusUpdating,
    tierUpdateModal,
    setTierUpdateModal,
    updateLoyaltyTier,
    isVipLoyaltyTierLoading,
    vipLoyalTierData,
    isVipLoyaltyTierDataLoading,
    handleUpdateTier,
    userTier,
    setUserTier,
    userId,
    setUserId,
    search,
  };
};

export default useVipPlayerListing;
