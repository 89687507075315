export const tableHeaders = [
  { labelKey: "userId", value: "userId" },
  { labelKey: "email", value: "email" },
  { labelKey: "username", value: "username" },
  { labelKey: "name", value: "firstName" },
  { labelKey: "promocode", value: "isAvailPromocodeBlocked" },
  { labelKey: "actions", value: "actions" },
];

export const initialSet = {
  idSearch: null,
  emailSearch: "",
  firstNameSearch: "",
  lastNameSearch: "",
  userNameSearch: "",
};
