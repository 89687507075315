import React, { useState } from "react";
import { Button, Form, Row, Col, Table } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowCircleUp,
  faArrowCircleDown,
  faEdit,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import PaginationComponent from "../../components/Pagination";
import {
  hotOptions,
  isVisibleInStoreOptions,
  statusOptions,
  tableHeaders,
} from "./constants";
import usePackagesListing from "./hooks/usePackagesListing";
import Trigger from "../../components/OverlayTrigger";
import { AdminRoutes } from "../../routes";
import useCheckPermission from "../../utils/checkPermission";
import useEditPackage from "./hooks/useEditPackage";
import { toast } from "../../components/Toast";
import PackageUserModal from "../../components/PackageUserModal";
import packageTreeIcon from "../../assets/img/icons/package_tree.png";
import {
  ConfirmationModal,
  DeleteConfirmationModal,
} from "../../components/ConfirmationModal";
import { searchRegEx } from "../../utils/helper";
import Preloader, { InlineLoader } from "../../components/Preloader";

const Packages = () => {
  const [showPackageUser, setShowPackageUser] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState(null);

  const {
    loading,
    navigate,
    limit,
    setLimit,
    deleteLoading,
    page,
    setPage,
    setOrderBy,
    sort,
    setSort,
    setSearch,
    search,
    show,
    setShow,
    over,
    setOver,
    data,
    totalPages,
    handleYes,
    selected,
    active,
    setHot,
    setIsActive,
    setIsVisibleInStore,
    fetchData,
    t,
    handleDeleteModal,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
  } = usePackagesListing();

  const onSuccess = (res) => {
    if (res?.data?.updatedPackage[0]) {
      fetchData();
      toast("Status changed", "success", "packageUpdate");
    }
  };
  const { editPackage } = useEditPackage(onSuccess);

  const { isHidden } = useCheckPermission();

  const handleStatusChanged = (data) => {
    const body = {
      ...data,
      amount: data.amount.toString(),
      isActive: (!data.isActive).toString(),
      hot: data.hot.toString(),
      currency: "USD",
      isVisibleInStore: data.isVisibleInStore.toString(),
    };
    if (data) editPackage(body);
  };

  return (
    <>
      <Row>
        <Col>
          <h3>{t("title")}</h3>
        </Col>

        <Col>
          <div className="d-flex justify-content-end">
            {/* <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>{t('filters.hot')}</Form.Label>
            <Form.Select size='sm' style={{ marginRight: '15px', minWidth: '120px', maxWidth: '150px' }} onChange={(event) => {
              setPage(1)
              setHot(event.target.value)
            }}>
              {hotOptions.map((status, idx) => (
                <option key={status.label} defaultValue={idx === 0} value={status.value}>{status.label}</option>
              ))}
            </Form.Select> */}
            <Form.Label
              style={{
                marginBottom: "0",
                marginRight: "15px",
                marginTop: "5px",
              }}
            >
              {t("filters.status")}
            </Form.Label>
            <Form.Select
              size="sm"
              defaultValue="true"
              style={{
                marginRight: "15px",
                minWidth: "120px",
                maxWidth: "150px",
              }}
              onChange={(event) => {
                setPage(1);
                setIsActive(event.target.value);
              }}
            >
              {statusOptions.map((status, idx) => (
                <option key={status.label} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Form.Select>
            <Form.Label
              style={{
                marginBottom: "0",
                marginRight: "15px",
                marginTop: "5px",
                overflow: "visible",
                whiteSpace: "nowrap",
              }}
            >
              {t("filters.isVisibleInStore")}
            </Form.Label>
            <Form.Select
              size="sm"
              style={{
                marginRight: "15px",
                minWidth: "120px",
                maxWidth: "150px",
              }}
              onChange={(event) => {
                setPage(1);
                setIsVisibleInStore(event.target.value);
              }}
            >
              {isVisibleInStoreOptions.map((status, idx) => (
                <option
                  key={status.label}
                  defaultValue={idx === 0}
                  value={status.value}
                >
                  {status.label}
                </option>
              ))}
            </Form.Select>
            <Form.Label
              style={{
                marginBottom: "0",
                marginRight: "15px",
                marginTop: "8px",
              }}
            >
              {t("filters.search")}
            </Form.Label>

            <Form.Control
              type="search"
              placeholder="Search by Amount & coins"
              value={search}
              size="sm"
              style={{
                minWidth: "190px",
                maxWidth: "230px",
                marginRight: "10px",
                maxHeight: "15px",
                marginTop: "5px",
              }}
              onChange={(event) => {
                const mySearch = event.target.value.replace(searchRegEx, "");
                setPage(1);
                setSearch(mySearch);
              }}
            />
            {/* Button to create new admin */}
            <Button
              variant="success"
              className="mb-2 m-1"
              size="sm"
              onClick={() => navigate(AdminRoutes.CreatePackage)}
              hidden={isHidden({ module: { key: "Package", value: "C" } })}
            >
              {t("createButton")}
            </Button>
            <Button
              variant="success"
              className="mb-2 m-1"
              size="sm"
              hidden={isHidden({ module: { key: "Package", value: "U" } })}
              onClick={() => navigate(AdminRoutes.ReorderPackage)}
            >
              {t("reorderButton")}
            </Button>
            <Button
              variant="warning"
              className="mb-2 m-1"
              size="sm"
              hidden={isHidden({ module: { key: "Package", value: "U" } })}
              onClick={() => navigate(AdminRoutes.UnarchivePackage)}
            >
              Restore
            </Button>
          </div>
        </Col>
      </Row>

      {
        <Table
          bordered
          striped
          responsive
          hover
          size="sm"
          className="text-center mt-4"
        >
          <thead className="thead-dark">
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  onClick={() =>
                    h.value !== "action" &&
                    h.value !== "PackageUsers" &&
                    h.value !== "claimedCount" &&
                    h.value !== "packageType" &&
                    setOrderBy(h.value)
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  className={selected(h) ? "border-3 border border-blue" : ""}
                >
                  {t(h.labelKey)}{" "}
                  {selected(h) &&
                    (sort === "asc" ? (
                      <FontAwesomeIcon
                        style={over ? { color: "red" } : {}}
                        icon={faArrowCircleUp}
                        onClick={() => setSort("desc")}
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={over ? { color: "red" } : {}}
                        icon={faArrowCircleDown}
                        onClick={() => setSort("asc")}
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                      />
                    ))}
                </th>
              ))}
            </tr>
          </thead>
          {loading ? (
            <tr>
              <td colSpan={10} className="text-center">
                <InlineLoader />
              </td>
            </tr>
          ) : (
            <tbody>
              {data && data.rows && data.rows.length > 0 ? (
                data?.rows?.map(
                  ({
                    packageId,
                    packageName,
                    orderId,
                    amount,
                    gcCoin,
                    scCoin,
                    isActive,
                    hot,
                    packageType,
                    isVisibleInStore,
                    claimedCount,
                    welcomePurchaseBonusApplicable,
                    isSpecialPackage,
                    firstPurchaseApplicable,
                    bonusGc,
                    bonusSc,
                    purchaseNo,
                  }) => {
                    return (
                      <tr key={packageId}>
                        <td>
                          {packageId}{" "}
                          {welcomePurchaseBonusApplicable && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </td>
                        <td>{orderId ? orderId : "-"}</td>
                        <td>{packageName ? packageName : "-"}</td>
                        <td>
                          <span>
                            {amount
                              ? Number.isInteger(amount)
                                ? amount
                                : amount.toFixed(2)
                              : amount}
                          </span>
                        </td>
                        <td>
                          <span>
                            {gcCoin} + {bonusGc}
                          </span>
                        </td>
                        <td>
                          {scCoin} + {bonusSc}
                        </td>
                        <td>
                          {isActive ? (
                            <span className="text-success">
                              {t("activeStatus")}
                            </span>
                          ) : (
                            <span className="text-danger">
                              {t("inActiveStatus")}
                            </span>
                          )}
                        </td>
                        {/* <td>{packageType === 0 ? 'Regular' : packageType === 1 ? 'Best' : packageType === 2 && 'Popular'}</td> */}
                        {/* <td>{packageType}</td> */}
                        {/* <td>{isVisibleInStore ? 'Yes' : 'No'}</td> */}
                        <td>
                          {welcomePurchaseBonusApplicable
                            ? "Welcome Purchase Package"
                            : purchaseNo>0 && isSpecialPackage
                            ? "Special Purchase Package"
                            : firstPurchaseApplicable
                            ? "Basic Package"
                            : isSpecialPackage
                            ? "Special Package"
                            : purchaseNo>0 ?"Purchase Package":"Basic Package"
                            
                            }
                        </td>
                        <td>{claimedCount || 0}</td>
                        <td>{purchaseNo || 0}</td>
                        {!isHidden({
                          module: { key: "Package", value: "U" },
                        }) ||
                        !isHidden({
                          module: { key: "Package", value: "T" },
                        }) ? (
                          <td>
                            <>
                              <Trigger
                                message={"View"}
                                id={packageId + "view"}
                              />
                              <Button
                                id={packageId + "view"}
                                className="m-1"
                                size="sm"
                                variant="info"
                                onClick={() =>
                                  navigate(
                                    `${AdminRoutes.ViewPackages.split(
                                      ":"
                                    ).shift()}${packageId}`
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Button>

                              <Trigger message="Edit" id={packageId + "edit"} />
                              <Button
                                id={packageId + "edit"}
                                className="m-1"
                                size="sm"
                                variant="warning"
                                onClick={() =>
                                  navigate(
                                    `${
                                      AdminRoutes.EditPackageDetails.split(
                                        ":"
                                      )[0]
                                    }${packageId}`
                                  )
                                }
                                hidden={isHidden({
                                  module: { key: "Package", value: "U" },
                                })}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                              <>
                                <Trigger
                                  message={"Delete"}
                                  id={packageId + "delete"}
                                />
                                <Button
                                  id={packageId + "delete"}
                                  className="m-1"
                                  size="sm"
                                  variant="danger"
                                  hidden={isHidden({
                                    module: { key: "Package", value: "D" },
                                  })}
                                  onClick={() => handleDeleteModal(packageId)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </>
                            </>
                          </td>
                        ) : (
                          "NA"
                        )}
                      </tr>
                    );
                  }
                )
              ) : (
                <tr>
                  <td colSpan={9} className="text-danger text-center">
                    {t("noDataFound")}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
      }
      {data?.count !== 0 && (
        <PaginationComponent
          page={data?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
        />
      )}

      {showPackageUser && (
        <PackageUserModal
          setShow={setShowPackageUser}
          show={showPackageUser}
          packageDetail={data?.rows?.find(
            (ele) => ele.packageId === selectedPackageId
          )}
          navigate={navigate}
        />
      )}
      {deleteModalShow && (
        <DeleteConfirmationModal
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
          handleDeleteYes={handleDeleteYes}
          loading={deleteLoading}
        />
      )}
    </>
  );
};

export default Packages;
