import React, { useState } from 'react';
import { Accordion, Card, Col, Row } from '@themesberg/react-bootstrap';
import './_vip.scss';
import { PieChart } from '../charts/PieChart';
import { VerticalBarChart } from '../charts/VerticalBarChart';
import SearchBar from './SearchBar';
import useVipDashboard from '../hooks/useVipDashboard';
import GlobalSearchBar from './GlobalSearch';

const VipDashboard = () => {
  const [search, setSearch] = useState('');
  const { biggestWinnerLooser, isLoading, vipDashboardReport, vipDashboardReportLoading } = useVipDashboard();

  return (
    <div>
      <Row className='d-flex justify-content-between'>
        <h3>VIP Dashboard</h3>
      </Row>
      <Row className='mt-3'>
        <Col sm={6} lg={4}>
          <GlobalSearchBar search={search} setSearch={setSearch} />
        </Col>
      </Row>
      {!vipDashboardReportLoading && (
        <>
          <Row className='mt-3' spacing={1}>
            <Col>
              <Card className='vip-card light-blue'>
                <img src='/svg/pending-vip.svg' />
                <div className='card-text'>
                  <p>Total Pending-VIP</p>
                  <h5>{vipDashboardReport?.PENDING_VIP_COUNT?.TILL_DATE}</h5>
                </div>
              </Card>
            </Col>
            <Col>
              <Card className='vip-card light-green'>
                <img src='/svg/approved-vip.svg' />
                <div className='card-text'>
                  <p>Total Approved-VIP</p>
                  <h5>{vipDashboardReport?.APPROVED_VIP_COUNT?.TILL_DATE}</h5>
                </div>
              </Card>
            </Col>
            <Col>
              <Card className='vip-card light-yellow'>
                <img src='/svg/vip-ggr.svg' />
                <div className='card-text'>
                  <p>GGR</p>
                  <h5>{vipDashboardReport?.SC_GGR_TOTAL?.TILL_DATE} SC </h5>
                </div>
              </Card>
            </Col>
            <Col>
              <Card className='vip-card light-purple'>
                <img src='/svg/vip-ngr.svg' />
                <div className='card-text'>
                  <p>NGR</p>
                  <h5>
                    {vipDashboardReport?.PURCHASE_AMOUNT_SUM?.TILL_DATE -
                      (vipDashboardReport?.APPROVAL_REDEMPTION_AMOUNT_SUM.TILL_DATE +
                        vipDashboardReport?.PENDING_REDEMPTION_AMOUNT_SUM?.TILL_DATE +
                        vipDashboardReport?.CURRENT_SC_BALANCE?.TILL_DATE +
                        vipDashboardReport?.VAULT_SC_BALANCE?.TILL_DATE)}{' '}
                    SC
                  </h5>
                </div>
              </Card>
            </Col>
            <Col>
              <Card className='vip-card light-red'>
                <img src='/svg/vip-total-win.svg' />
                <div className='card-text'>
                  <p>Total win</p>
                  <h5>{vipDashboardReport?.TOTAL_SC_WIN_AMOUNT_SUM?.TILL_DATE} SC </h5>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className='mt-4' spacing={1}>
            <Col sm={12} md={6}>
              <Accordion defaultActiveKey='0'>
                <Accordion.Item className='light-pink' eventKey='0'>
                  <Accordion.Header>Total Purchase</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/total-icon.svg' />
                          <h4 className='pink-text'> {vipDashboardReport?.PURCHASE_AMOUNT_SUM?.YEAR_TO_DATE} SC</h4>
                          <p>YTD Total Purchase</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/average-icon.svg' />
                          <h4 className='blue-text'>{vipDashboardReport?.PURCHASE_AMOUNT_SUM?.MONTHLY_AVERAGE} SC </h4>
                          <p>Average Monthly Total Purchase</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/calendar-icon.svg' />
                          <h4 className='green-text'> {vipDashboardReport?.PURCHASE_AMOUNT_SUM?.MONTH_TO_DATE} SC</h4>
                          <p>MTD Total Purchase</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/last-day-icon.svg' />
                          <h4 className='yellow-text'> {vipDashboardReport?.PURCHASE_AMOUNT_SUM?.LAST_WEEK} SC</h4>
                          <p>Last 7 Days Total Purchase</p>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col sm={12} md={6}>
              <Accordion defaultActiveKey='1'>
                <Accordion.Item className='light-purple' eventKey='1'>
                  <Accordion.Header>Total Redemption</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/total-icon.svg' />
                          <h4 className='pink-text'>
                            {vipDashboardReport?.APPROVAL_REDEMPTION_AMOUNT_SUM?.YEAR_TO_DATE} SC
                          </h4>
                          <p>YTD Total Redemption</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/average-icon.svg' />
                          <h4 className='blue-text'>
                            {vipDashboardReport?.APPROVAL_REDEMPTION_AMOUNT_SUM?.MONTHLY_AVERAGE} SC
                          </h4>
                          <p>Average Monthly Total Redemption</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/calendar-icon.svg' />
                          <h4 className='green-text'>
                            {vipDashboardReport?.APPROVAL_REDEMPTION_AMOUNT_SUM?.MONTH_TO_DATE} SC
                          </h4>
                          <p>MTD Total Redemption</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/last-day-icon.svg' />
                          <h4 className='yellow-text'>
                            {vipDashboardReport?.APPROVAL_REDEMPTION_AMOUNT_SUM?.LAST_WEEK} SC
                          </h4>
                          <p>Last 7 Days Total Redemption</p>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Row className='mt-4' spacing={1}>
            <Col sm={12} md={6}>
              <Accordion defaultActiveKey='0'>
                <Accordion.Item className='light-blue' eventKey='0'>
                  <Accordion.Header>Total Bets</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/total-icon.svg' />
                          <h4 className='pink-text'> {vipDashboardReport?.TOTAL_SC_BET_AMOUNT_SUM?.YEAR_TO_DATE} SC</h4>
                          <p>YTD Total Bets</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/average-icon.svg' />
                          <h4 className='blue-text'>
                            {vipDashboardReport?.TOTAL_SC_BET_AMOUNT_SUM?.MONTHLY_AVERAGE} SC
                          </h4>
                          <p>Average Monthly Total Bets</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/calendar-icon.svg' />
                          <h4 className='green-text'>
                            {' '}
                            {vipDashboardReport?.TOTAL_SC_BET_AMOUNT_SUM?.MONTH_TO_DATE} SC
                          </h4>
                          <p>MTD Total Bets</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/last-day-icon.svg' />
                          <h4 className='yellow-text'> {vipDashboardReport?.TOTAL_SC_BET_AMOUNT_SUM?.LAST_WEEK} SC</h4>
                          <p>Last 7 Days Total Bets</p>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col sm={12} md={6}>
              <Accordion defaultActiveKey='1'>
                <Accordion.Item className='light-pink' eventKey='1'>
                  <Accordion.Header>Gross Gaming Revenue (GGR)</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/total-icon.svg' />
                          <h4 className='pink-text'> {vipDashboardReport?.SC_GGR_TOTAL?.YEAR_TO_DATE} SC</h4>
                          <p>YTD Total GGR</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/average-icon.svg' />
                          <h4 className='blue-text'> {vipDashboardReport?.SC_GGR_TOTAL?.MONTHLY_AVERAGE} SC</h4>
                          <p>Average Monthly Total GGR</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/calendar-icon.svg' />
                          <h4 className='green-text'> {vipDashboardReport?.SC_GGR_TOTAL?.MONTH_TO_DATE} SC</h4>
                          <p>MTD Total GGR</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/last-day-icon.svg' />
                          <h4 className='yellow-text'> {vipDashboardReport?.LAST_WEEK} SC </h4>
                          <p>Last 7 Days Total GGR</p>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Row className='mt-4' spacing={1}>
            <Col sm={12} md={6}>
              <Accordion defaultActiveKey='0'>
                <Accordion.Item className='light-yellow' eventKey='0'>
                  <Accordion.Header>Net Gaming Revenue (NGR)</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/total-icon.svg' />
                          <h4 className='pink-text'>
                            {vipDashboardReport?.PURCHASE_AMOUNT_SUM?.YEAR_TO_DATE -
                              (vipDashboardReport?.APPROVAL_REDEMPTION_AMOUNT_SUM.YEAR_TO_DATE +
                                vipDashboardReport?.PENDING_REDEMPTION_AMOUNT_SUM?.YEAR_TO_DATE +
                                vipDashboardReport?.CURRENT_SC_BALANCE?.YEAR_TO_DATE +
                                vipDashboardReport?.VAULT_SC_BALANCE?.YEAR_TO_DATE)}{' '}
                            SC
                          </h4>
                          <p>YTD Total NGR</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/average-icon.svg' />
                          <h4 className='blue-text'>
                            {vipDashboardReport?.PURCHASE_AMOUNT_SUM?.MONTHLY_AVERAGE -
                              (vipDashboardReport?.APPROVAL_REDEMPTION_AMOUNT_SUM.MONTHLY_AVERAGE +
                                vipDashboardReport?.PENDING_REDEMPTION_AMOUNT_SUM?.MONTHLY_AVERAGE +
                                vipDashboardReport?.CURRENT_SC_BALANCE?.MONTHLY_AVERAGE +
                                vipDashboardReport?.VAULT_SC_BALANCE?.MONTHLY_AVERAGE)}{' '}
                            SC
                          </h4>
                          <p>Average Monthly Total NGR </p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/calendar-icon.svg' />
                          <h4 className='green-text'>
                            {vipDashboardReport?.PURCHASE_AMOUNT_SUM?.MONTH_TO_DATE -
                              (vipDashboardReport?.APPROVAL_REDEMPTION_AMOUNT_SUM.MONTH_TO_DATE +
                                vipDashboardReport?.PENDING_REDEMPTION_AMOUNT_SUM?.MONTH_TO_DATE +
                                vipDashboardReport?.CURRENT_SC_BALANCE?.MONTH_TO_DATE +
                                vipDashboardReport?.VAULT_SC_BALANCE?.MONTH_TO_DATE)}{' '}
                            SC
                          </h4>
                          <p>YMTD Total NGR</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/last-day-icon.svg' />
                          <h4 className='yellow-text'>
                            {vipDashboardReport?.PURCHASE_AMOUNT_SUM?.LAST_WEEK -
                              (vipDashboardReport?.APPROVAL_REDEMPTION_AMOUNT_SUM.LAST_WEEK +
                                vipDashboardReport?.PENDING_REDEMPTION_AMOUNT_SUM?.LAST_WEEK +
                                vipDashboardReport?.CURRENT_SC_BALANCE?.LAST_WEEK +
                                vipDashboardReport?.VAULT_SC_BALANCE?.LAST_WEEK)}{' '}
                            SC
                          </h4>
                          <p>Last 7 Days Total NGR</p>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col sm={12} md={6}>
              <Accordion defaultActiveKey='1'>
                <Accordion.Item className='light-green' eventKey='1'>
                  <Accordion.Header>Hold Percentage</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/total-icon.svg' />
                          <h4 className='pink-text'> {vipDashboardReport?.HOLD_PERCENTAGE?.YEAR_TO_DATE} %</h4>
                          <p>YTD Total Hold</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/average-icon.svg' />
                          <h4 className='blue-text'> {vipDashboardReport?.HOLD_PERCENTAGE?.MONTHLY_AVERAGE} %</h4>
                          <p>Average Monthly Total Hold</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/calendar-icon.svg' />
                          <h4 className='green-text'> {vipDashboardReport?.MONTH_TO_DATE} %</h4>
                          <p>MTD Total Hold</p>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className='accordion-card'>
                          <img src='/svg/last-day-icon.svg' />
                          <h4 className='yellow-text'> {vipDashboardReport?.LAST_WEEK} %</h4>
                          <p>Last 7 Days Total Hold</p>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col sm={12} md={6}>
              <PieChart redemptionToPurchaseRatio={vipDashboardReport?.REDEMPTION_TO_PURCHASE_RATIO} />
            </Col>
            <Col sm={12} md={6}>
              <VerticalBarChart reinvestmentPercentage={vipDashboardReport?.REINVESTMENT_PERCENTAGE} />
            </Col>
          </Row>
        </>
      )}

      {!isLoading && (
        <>
          <Row className='mt-4'>
            <Accordion defaultActiveKey='2'>
              <Accordion.Item eventKey='2'>
                <Accordion.Header>Biggest Winner</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {biggestWinnerLooser.topWinners.map((winners) => {
                      return (
                        <Col key={winners.userId}>
                          <h5>
                            {winners?.firstName && winners?.lastName
                              ? `${winners?.firstName} ${winners?.lastName}`
                              : 'NA'}
                          </h5>
                          <h5>{winners.gameName}</h5>
                          {/* <div className='d-flex align-items-center'>
                            <img src='/entry-amount-gc.png' alt='gc' />
                            <h6 className='ms-3 gc-text'>{winners.amount} GC</h6>
                          </div> */}
                          <div className='d-flex align-items-center'>
                            <img src='/entry-amount-sc.png' alt='sc' />
                            <h6 className='ms-3 sc-text'>{winners.amount} SC</h6>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
          <Row className='mt-4'>
            <Accordion defaultActiveKey='2'>
              <Accordion.Item eventKey='2'>
                <Accordion.Header>Biggest Loser</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {biggestWinnerLooser.topLooser.map((looser, index) => {
                      return (
                        <Col key={looser?.userId}>
                          <h5>
                            {looser?.firstName && looser?.lastName ? `${looser?.firstName} ${looser?.lastName}` : 'NA'}
                          </h5>
                          <h5>{looser.gameName}</h5>
                          {/* <div className='d-flex align-items-center'>
                            <img src='/entry-amount-gc.png' alt='gc' />
                            <h6 className='ms-3 gc-text'>{looser.amount} GC</h6>
                          </div> */}
                          <div className='d-flex align-items-center'>
                            <img src='/entry-amount-sc.png' alt='sc' />
                            <h6 className='ms-3 sc-text'>{looser.amount} SC</h6>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        </>
      )}
    </div>
  );
};
export default VipDashboard;
