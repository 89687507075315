import React from 'react';
import { Button, Table, Row, Col } from '@themesberg/react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { tableHeaders } from '../constants';
import SearchBar from './SearchBar';
import { InlineLoader } from '../../../components/Preloader';
import useVipPlayerListing from '../hooks/useVipPlayerListing';
import PaginationComponent from '../../../components/Pagination';
import { AdminRoutes } from '../../../routes';
import './_vip.scss';

const PendingVipPlayers = () => {
  const navigate = useNavigate();
  const vipStatusSearch = 'pending';
  const ratingMin = 0;
  const ratingMax = 3;
  const { vipPlayerListing, isLoading, limit, setLimit, setPage, page, totalPages, setSearch, search } =
    useVipPlayerListing(ratingMin, ratingMax, vipStatusSearch);

  const handleNavigatePlayerDetails = (userId) => {
    navigate(`${AdminRoutes.VipPlayerDetails.split(':').shift()}${userId}`);
  };
  return (
    <>
      <Row className='d-flex justify-content-between align-items-center'>
        <Col sm={6} lg={2}>
          <h3>Pending-VIP</h3>
        </Col>
        <Col sm={6} lg={2}>
          <SearchBar search={search} setSearch={setSearch} />
        </Col>
      </Row>
      <Table bordered striped responsive hover size='sm' className='text-center mt-3'>
        <thead className='thead-dark'>
          <tr style={{ boxShadow: '0 4px 4px 0 #797979', borderRadius: '7px' }}>
            {tableHeaders.map((header, index) => (
              <th key={header.value}>{header.labelKey}</th>
            ))}
          </tr>
        </thead>
        {isLoading ? (
          <tr>
            <td colSpan={10} className='text-center'>
              <InlineLoader />
            </td>
          </tr>
        ) : (
          <tbody>
            {vipPlayerListing && vipPlayerListing?.users?.rows.length > 0 ? (
              vipPlayerListing?.users?.rows.map(
                ({ userId, username, email, UserReport, UserInternalRating, userWallet }) => {
                  const ngr =
                    +UserReport?.totalPurchaseAmount -
                    (+UserReport?.totalRedemptionAmount +
                      +UserReport?.totalPendingRedemptionAmount +
                      +userWallet?.currentScBalance +
                      +userWallet?.vaultScBalance);
                  return (
                    <tr key={userId}>
                      <td
                        className='text-link'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleNavigatePlayerDetails(userId)}
                      >
                        {userId || 'NA'}
                      </td>
                      <td
                        className='text-link'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleNavigatePlayerDetails(userId)}
                      >
                        {username || 'NA'}
                      </td>
                      <td
                        className='text-link'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleNavigatePlayerDetails(userId)}
                      >
                        {email || 'NA'}
                      </td>
                      <td>{UserReport?.totalGgr}</td>
                      <td>{ngr}</td>
                      <td>{UserReport?.totalPurchaseAmount}</td>
                      <td>{UserReport?.totalRedemptionAmount}</td>
                      <td>{UserInternalRating?.rating}</td>
                      <td>
                        <Button
                          onClick={() => handleNavigatePlayerDetails(userId)}
                          // variant='success'
                          style={{
                            width: '6rem',
                            padding: '5px 10px',
                            backgroundColor: '#219653',
                            color: '#FFFFFF',
                            border: 'none',
                          }}
                        >
                          Profile
                        </Button>
                      </td>

                      <td>Not available</td>
                    </tr>
                  );
                },
              )
            ) : (
              <tr>
                <td colSpan={10} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        )}
      </Table>
      {vipPlayerListing?.users?.count !== 0 && (
        <PaginationComponent
          page={vipPlayerListing?.users?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </>
  );
};
export default PendingVipPlayers;
