export const tableHeaders =  [
    { labelKey: 'Sr. No.', value: '' },
    { labelKey: 'Staff Email', value: 'email' },
    { labelKey: 'Name', value: 'name' },
    { labelKey: 'Total SC Credited', value: 'totalScAdded' },
    { labelKey: 'Total SC Deducted', value: 'totalScRemoved' },
    { labelKey: 'Action', value: '' },
   ]

   export const AdminCoinsUserListHeader =  [
    { labelKey: 'Sr. No.', value: '' },
    { labelKey: 'User ID', value: 'userId' },
    { labelKey: 'Name', value: 'name' },
    { labelKey: 'Email', value: 'userEmail' },
    { labelKey: 'SC Amount', value: 'amount' },
    { labelKey: 'Transaction Type', value: 'transactionTyep' },
    { labelKey: 'Created At', value: 'createdAt' },
   ]


