// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-bonus-days {
  background: #f4f7fa;
  border-radius: 0.5rem;
  border: 1px solid #dde1e5; }
`, "",{"version":3,"sources":["webpack://./src/pages/ReferralBonus/bonus.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,qBAAqB;EACrB,yBAAyB,EAAA","sourcesContent":[".create-bonus-days{\n    background: #f4f7fa;\n    border-radius: 0.5rem;\n    border: 1px solid #dde1e5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
