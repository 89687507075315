// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-btn {
  padding: 0;
  width: 32px;
  background: transparent;
  border: none; }
  @media screen and (min-width: 768px) {
    .menu-btn {
      display: none; } }

.site-logo {
  max-width: 75px; }
`, "",{"version":3,"sources":["webpack://./src/components/RouteWithSidebar/routewithsidebar.scss"],"names":[],"mappings":"AAAA;EACI,UAAU;EACV,WAAW;EACX,uBAAuB;EACvB,YAAY,EAAA;EAEZ;IANJ;MAOQ,aAAa,EAAA,EAEpB;;AAED;EACI,eAAe,EAAA","sourcesContent":[".menu-btn{\n    padding: 0;\n    width: 32px;\n    background: transparent;\n    border: none;\n\n    @media screen and (min-width:768px) {\n        display: none;\n    }\n}\n\n.site-logo{\n    max-width: 75px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
