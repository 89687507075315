// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leafNodeBase {
  /* width: 200px !important; */ }

.tree-container {
  display: flex;
  flex-direction: column; }

.tree-name {
  text-align: left;
  position: relative; }
`, "",{"version":3,"sources":["webpack://./src/components/Hierarchy/Hierarchy.scss"],"names":[],"mappings":"AAAA;EACA,6BAAA,EAA8B;;AAE9B;EACA,aAAa;EACb,sBAAsB,EAAA;;AAGtB;EACA,gBAAgB;EAChB,kBAAkB,EAAA","sourcesContent":[".leafNodeBase{\n/* width: 200px !important; */\n}\n.tree-container{\ndisplay: flex;\nflex-direction: column;\n}\n\n.tree-name{\ntext-align: left;\nposition: relative;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
