import React, { useState } from 'react';
import { Row, Accordion, Button, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import useVipQuestions from '../hooks/useVipQuestions';

const VipFAQ = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const {
    vipQuestionsList,
    isVipQuestionsLoading,
    vipAnswersList,
    isVipAnswerLoading,
    updateVipUserAnswers,
    isVipAnswerUpdating,
    userId,
  } = useVipQuestions(accordionOpen);

  const [editingIndex, setEditingIndex] = useState(null);
  const [editedAnswer, setEditedAnswer] = useState('');

  const handleEditClick = (index, answer) => {
    setEditingIndex(index);
    setEditedAnswer(answer);
  };

  const handleSave = (questionnaireId) => {
    updateVipUserAnswers({ userId, questionnaireId, answer: editedAnswer });
    setEditingIndex(null);
  };

  const MergedQuestionAnswers =
    !isVipAnswerLoading &&
    !isVipQuestionsLoading &&
    vipQuestionsList?.map((question) => ({
      ...question,
      answer:
        vipAnswersList?.find((answer) => answer?.questionnaireId === question?.questionnaireId)?.answer ||
        'No Answer Provided',
    }));

  return (
    <>
      <Accordion defaultActiveKey={null} className='mt-3' onClick={() => setAccordionOpen(!accordionOpen)}>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>Questions and Answers</Accordion.Header>
          <Accordion.Body>
            {!isVipQuestionsLoading &&
              !isVipAnswerLoading &&
              MergedQuestionAnswers.map((queAndAns, index) => (
                <Row className='mt-2' spacing={1} key={index}>
                  <Accordion defaultActiveKey='' className='questionnaire-accordion'>
                    <Accordion.Item eventKey='1'>
                      <Accordion.Header className='p-1'>
                        {queAndAns?.question}
                        <FontAwesomeIcon
                          icon={faPen}
                          className='ms-2 small-icon'
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(index, queAndAns.answer);
                          }}
                        />
                      </Accordion.Header>
                      <Accordion.Body>
                        {editingIndex === index ? (
                          <div>
                            <Form.Control
                              as='textarea'
                              rows={3}
                              value={editedAnswer}
                              onChange={(e) => setEditedAnswer(e.target.value)}
                              placeholder='Edit answer'
                            />
                            <Button
                              variant='primary'
                              className='mt-2'
                              onClick={() => handleSave(queAndAns?.questionnaireId)}
                            >
                              Save
                            </Button>
                          </div>
                        ) : (
                          queAndAns?.answer
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Row>
              ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default VipFAQ;
