export const tableHeaders = [
  { labelKey: 'headers.id', value: 'packageId' },
  { labelKey: 'headers.orderId', value: 'orderId' },
  { labelKey: 'Package Name', value: 'packageName' },
  { labelKey: 'headers.amount', value: 'amount' },
  { labelKey: 'headers.gcCoin', value: 'gcCoin' },
  { labelKey: 'headers.scCoin', value: 'scCoin' },
  { labelKey: 'headers.status', value: 'isActive' },
  // { labelKey: 'headers.type', value: 'action' },
  // { labelKey: 'headers.visibleInStore', value: 'isVisibleInStore' },
  // { labelKey: 'headers.visibleInStore', value: 'isVisibleInStore' },
  { labelKey: 'headers.packageType', value: 'packageType'},
  { labelKey: 'headers.purchaseCount', value: 'claimedCount'},
  { labelKey: 'Purchase Number', value: 'purchaseNo'},
  { labelKey: 'headers.action', value: 'action' },
]

export const hotOptions = [
  { label: 'All', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }
]
export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'true' },
  { label: 'In Active', value: 'false' }
]
export const isVisibleInStoreOptions = [
  { label: 'All', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }
]

export const PACKAGE_USER_FILTER = {
  NGR: 'ngr',
  GGR: 'ggr',
  RTP: 'rtp',
  DEPOSIT: 'deposit',
  HOUSE_EDGE: 'house-edge'
};

