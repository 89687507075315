// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-basic-info {
  background: #f1f1f1;
  padding: .75rem 0.375rem;
  border-radius: .25rem; }

.react-select__control {
  min-height: 43px !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/PlayerDetails/playerdetails.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,wBAAwB;EACxB,qBAAqB,EAAA;;AAIrB;EACI,2BAA2B,EAAA","sourcesContent":[".player-basic-info{\n    background: #f1f1f1;\n    padding:.75rem 0.375rem ;\n    border-radius: .25rem;\n}\n\n.react-select{ \n    &__control {\n        min-height: 43px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
