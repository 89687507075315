import React, { useState } from 'react';
import { Row, Col, Accordion, Dropdown } from '@themesberg/react-bootstrap';
import SearchBar from './SearchBar';
import useVipPlayerDetails from '../hooks/useVipPlayerDetails';
import Preloader from '../../../components/Preloader';
import { daysOptions } from '../constants';
import { getDateDaysAgo } from '../../../utils/dateFormatter';
import DateRangePicker from './DateRangePicker';
import useVipPlayerReport from '../hooks/useVipPlayerReport';
import VipFAQ from './VipFAQ';
import GlobalSearchBar from './GlobalSearch';

const VipPlayerDetails = () => {
  const { vipPlayerDetails, vipPlayerDataLoading } = useVipPlayerDetails();

  const [selectedDays, setSelectedDays] = useState('Select Days');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState({ startDate: getDateDaysAgo(7), endDate: new Date() });

  const { vipPlayerReport, vipPlayerReportLoading, startDate, setStartDate, endDate, setEndDate } =
    useVipPlayerReport();

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
    setSelectedDays('Select Days');
  };

  const handleSelect = (eventKey) => {
    const label =
      eventKey === 'custom' ? 'Custom Range' : daysOptions.find(({ value }) => value === +eventKey)?.label || '';
    if (eventKey === 'custom') {
      setShowDatePicker(true);
    } else {
      setStartDate(getDateDaysAgo(eventKey));
      setEndDate(new Date());
      setShowDatePicker(false);
    }

    setSelectedDays(label);
  };
  const handlePickerClose = () => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
  };
  return (
    <>
      {vipPlayerDataLoading ? (
        <Preloader />
      ) : (
        <div>
          <Row className='mt-1'>
            <Col sm={6} lg={4}>
              <GlobalSearchBar />
            </Col>
          </Row>
          <Row className='mt-1'>
            <Accordion defaultActiveKey='1'>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>VIP Player Details</Accordion.Header>
                <Accordion.Body>
                  <Row className='ps-6'>
                    <Col>
                      <img src='/svg/user-profile.svg' />
                      <h3>
                        {vipPlayerDetails?.user?.firstName && vipPlayerDetails?.user?.lastName
                          ? `${vipPlayerDetails?.user?.firstName} ${vipPlayerDetails?.user?.lastName}`
                          : 'NA'}
                      </h3>
                      <p>{vipPlayerDetails?.user?.email}</p>
                    </Col>

                    <Col>
                      <p>
                        <b>Address : </b> {vipPlayerDetails?.user?.addressLine_1 || 'NA'}
                      </p>
                      <p>
                        <b>Date of Birth :</b> {vipPlayerDetails?.user?.dateOfBirth || 'NA'}
                      </p>
                      <p>
                        <b>Time Zone : </b>EST (Eastern Standard Time)
                      </p>{' '}
                      <p>
                        <b>phone No :</b> {vipPlayerDetails?.user?.phone}
                      </p>{' '}
                      <p>
                        <b>Last Online :</b> {vipPlayerDetails?.user?.lastLoginDate}
                      </p>{' '}
                      <p>
                        <b>Last Big Win :</b> {vipPlayerDetails?.user?.UserReport?.biggestWin} SC
                      </p>
                      <p>
                        <b> Exclusion : </b> {vipPlayerDetails?.user?.selfExclusion || 'No'}
                      </p>
                      <p>
                        <b> Loyalty Tier :</b> {vipPlayerDetails?.user?.UserTier?.tierName}
                      </p>
                      <p>
                        <b>KYC status : </b>
                        {vipPlayerDetails?.user?.kycStatus}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <b>Account Managed by : </b>Money Factory
                      </p>
                      <p>
                        <b>Churn Warning Indicator : </b> --------------
                      </p>
                      <p>
                        {' '}
                        <b>Registration Date :</b> {vipPlayerDetails?.user?.createdAt}
                      </p>
                      <p>
                        <b>Currently Online: </b>
                        {vipPlayerDetails?.user?.currentlyOnline}
                      </p>
                      <p>
                        <b>Most played game type : </b>Adventure
                      </p>
                      <p>
                        <b> Most played game :</b>
                        {vipPlayerDetails?.user?.UserReport?.mostPlayedGameName}
                      </p>
                      <p>
                        <b> Take a break (how long) :</b>1 Month{' '}
                      </p>

                      <p>
                        <b>Internal Rating : </b> {vipPlayerDetails?.user?.UserInternalRating?.rating}
                      </p>
                      <p>
                        <b>Vip Status : </b> {vipPlayerDetails?.user?.UserInternalRating?.vipStatus}
                      </p>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        </div>
      )}
      <VipFAQ />
      {!vipPlayerReportLoading && (
        <Row className='mt-3' spacing={1}>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <div className='d-flex justify-content-between w-100 align-items-center'>
                  <span>Date-Specific Metrics</span>

                  <div className='d-flex align-items-center'>
                    {showDatePicker && <DateRangePicker onChange={handleDateChange} onClose={handlePickerClose} />}

                    <Dropdown
                      onClick={(e) => e.stopPropagation()}
                      onSelect={(eventKey) => handleSelect(eventKey)}
                      className='ms-2'
                    >
                      <Dropdown.Toggle variant='primary'>{selectedDays}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        {daysOptions.map(({ value, label }) => (
                          <Dropdown.Item key={value} eventKey={value}>
                            {label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col sm={3}>
                    <div className='vip-member-accordion-card'>
                      <img src='/svg/vip-player-purchase.svg' />
                      <div>
                        <h4 className='pink-text'> {vipPlayerReport?.data?.totalPurchaseAmountSum} SC</h4>
                        <p>Total Purchase</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className='vip-member-accordion-card'>
                      <img src='/svg/vip-player-redemption.svg' />
                      <div>
                        <h4 className='red-text'>{vipPlayerReport?.data?.approvedRedemptionAmountSum} SC</h4>
                        <p>Total Redemption</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className='vip-member-accordion-card'>
                      <img src='/svg/vip-player-redemption-to-ratio.svg' />
                      <div>
                        <h4 className='green-text'> {vipPlayerReport?.data?.redemptionToPurchaseRatio} </h4>
                        <p>Redemption to Purchase Ratio</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className='vip-member-accordion-card'>
                      <img src='/svg/vip-player-total-bets.svg' />
                      <div>
                        <h4 className='light-yellow-text'> {vipPlayerReport?.data?.totalScBetAmountSum} SC</h4>
                        <p>Total Bets</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col sm={3}>
                    <div className='vip-member-accordion-card'>
                      <img src='/svg/vip-ggr.svg' />
                      <div>
                        <h4 className='yellow-text'> {vipPlayerReport?.data?.totalGgr} SC</h4>
                        <p>Gross Gaming Revenue (GGR)</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className='vip-member-accordion-card'>
                      <img src='/svg/vip-ngr.svg' />
                      <div>
                        <h4 className='dark-blue-text'>
                          {vipPlayerReport?.data?.totalPurchaseAmountSum -
                            (vipPlayerReport?.data?.approvedRedemptionAmountSum +
                              vipPlayerReport?.data?.pendingRedemptionAmountSum +
                              +vipPlayerReport?.data?.currentScBalance +
                              +vipPlayerReport?.data?.vaultScBalance)}{' '}
                          SC
                        </h4>
                        <p>Net Gaming Revenue (NGR)</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className='vip-member-accordion-card'>
                      <img src='/svg/vip-player-hold-percentage.svg' />
                      <div>
                        <h4 className='blue-text'> {vipPlayerReport?.data?.holdPercentage} %</h4>
                        <p>Hold Percentage</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className='vip-member-accordion-card'>
                      <img src='/svg/vip-player-reinvestment-percentage.svg' />
                      <div>
                        <h4 className='pink-text'> {vipPlayerReport?.data?.reinvestmentPercentage} %</h4>
                        <p>Reinvestment Percentage</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      )}
    </>
  );
};
export default VipPlayerDetails;
