import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { getEditEmailCenter, getEmailCenter, getRedeemRuleDetail } from "../../../utils/apiCalls";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { editRulesSchema } from "../schemas";

const useRedeemRuleDetail = () => {
  const navigate = useNavigate();

  // const [over, setOver] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [templateid, setTemplateID] = useState("");
  const queryClient = useQueryClient();
  const { ruleId } = useParams();

  //update
  const {
    isLoading: updateloading,
    data: editRedeemRuleData,
    refetch: editfetch,
  } = useQuery({
    queryKey: ["EditTemplateList", ruleId],
    queryFn: () => getRedeemRuleDetail({ ruleId }),
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
    
  });
  const ediRuleData = editRedeemRuleData?.redeemRules?.rows[0]

  return {
   updateloading,ediRuleData,editfetch
  };
};

export default useRedeemRuleDetail;
