import { Card } from '@themesberg/react-bootstrap'
import React from 'react'
import RouteWithSidebar from '../RouteWithSidebar'
import usePrivateRoute from './usePrivateRoute'
import { Navigate, useNavigate } from 'react-router-dom'
import { AdminRoutes } from '../../routes'
import { useEffect } from 'react'
import { getLoginToken } from '../../utils/storageUtils'
import { useUserStore } from '../../store/store'
import { whaleAlertSocket } from '../../utils/socket'
import { NotificationToast } from '../Notifications/NotificationToast'

const PrivateRoute = ({ isWithoutCard = false, children, module }) => {
  const { userDetails, loading, permissions } = usePrivateRoute()
  const navigate = useNavigate()

  const notificationsSocketConnection = useUserStore(
    (state) => state.notificationsSocketConnection
  );

  useEffect(() => {
    if (!getLoginToken()) navigate(AdminRoutes.AdminSignin)
  }, [getLoginToken()])

  useEffect(()=> {
    if (notificationsSocketConnection) {
      whaleAlertSocket.on('ADMIN_NOTIFICATIONS', (data )=> {
        let title = data?.data?.title
        let message = data?.data?.message
        NotificationToast(title, message)
      })
    }
  }, [notificationsSocketConnection])

  if (!permissions) {
    return <></>
  }
  return (
    userDetails && !loading &&
    ((!module || permissions[Object.keys(module)?.[0]]?.includes(module[Object.keys(module)?.[0]]))
      ? (
        <RouteWithSidebar key={children}>
          {isWithoutCard
            ? children
            :
            <div className='px-3'>
              <Card className='p-3 '>{children}</Card>
            </div>
          }
        </RouteWithSidebar>)
      : <Navigate replace to={AdminRoutes.Profile} />)
  )
}

export default PrivateRoute
