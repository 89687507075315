// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-container {
  padding-top: 60px !important; }

.site-logo {
  max-width: 75px; }
`, "",{"version":3,"sources":["webpack://./src/components/RouteWithSidebar/routewithsidebar.scss"],"names":[],"mappings":"AAAA;EACI,4BAA4B,EAAA;;AAGhC;EACI,eAAe,EAAA","sourcesContent":[".component-container {\n    padding-top: 60px !important;\n}\n\n.site-logo{\n    max-width: 75px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
