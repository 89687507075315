import React, { useEffect, useState } from 'react';
import Datetime from "react-datetime";
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import useAmoeDashboard from '../../pages/PlayerDetails/hooks/useAmoeDashboard';
import AmoeClaimedDetailsChart from './AmoeClaimedDetailsChart';
import AmoeScannedDetailsChart from './AmoeScannedDetailChart';
import { formatPriceWithCommas } from '../../utils/helper';

const formatScannedDetailsChartData = (responseData = []) => {
  const labels = [];
  const scannedData = [];

  responseData?.forEach(({ scannedDate, count }) => {
    labels.push(scannedDate);
    scannedData.push(count);
  });
  return { labels, scannedData };
};

const formatClaimedDetailsChartData = (responseData = []) => {
  const labels = [];
  const claimedData = [];

  responseData?.forEach(({ claimedDate, count }) => {
    labels.push(claimedDate);
    claimedData.push(count);
  });
  return { labels, claimedData };
};

const AmoeDashboard = ({ amoeData }) => {
  const { 
    amoeDashboardData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isLoadingAmoeData,
  } = useAmoeDashboard({ isUTC: false });


  const [scannedDetailsChart, setScannedDetailsChart] = useState({
    labels: [],
    scannedData: [],
  });
  const [claimedDetailsChart, setClaimedDetailsChart] = useState({
    labels: [],
    claimedData: [],
  });

  useEffect(() => {
    if (amoeDashboardData) {
      const formattedScannedDetailsData = formatScannedDetailsChartData(
        amoeDashboardData?.scannedDetails
      );
      setScannedDetailsChart(formattedScannedDetailsData);

      const formattedClaimedDetailsData = formatClaimedDetailsChartData(
        amoeDashboardData?.claimedDetails
      );
      setClaimedDetailsChart(formattedClaimedDetailsData);
    }
  }, [amoeDashboardData]);

  return (
    <>
      <Row className='ps-0'>
        <div className='tournament-detail-wrap mt-4'>
          <Card className=' tournament-card p-2'>
            <div>
              <h3>Total GC Claimed Amount</h3>
              <h3>
                {`${formatPriceWithCommas(
                  amoeDashboardData?.totalclaimedDetails?.totalGcAmountClaimed
                )} GC`}
              </h3>
            </div>
            <div>
              <img src='/entry-amount-gc.png' alt='ggr' />
            </div>
          </Card>
          <Card className=' tournament-card p-2'>
            <div>
              <h3>Total SC Claimed Amount</h3>
              <h3>
                {`${formatPriceWithCommas(
                  amoeDashboardData?.totalclaimedDetails?.totalScAmountClaimed
                )} SC`}
              </h3>
            </div>
            <div>
              <img src='/total-sc-amoe.png' alt='ggr' />
            </div>
          </Card>
          <Card className='tournament-card p-2 '>
            <div>
              <h3>Total Claimed Count</h3>
              <h3>
                {formatPriceWithCommas(
                  amoeDashboardData?.totalclaimedDetails?.totalClaimedcount
                )}
              </h3>
            </div>
            <div>
              <img src='/claimed-count.png' alt='ggr' />
            </div>
          </Card>
          <Card className='tournament-card p-2 '>
            <div>
              <h3>Unique User Count</h3>
              <h3>
                {formatPriceWithCommas(
                  amoeDashboardData?.totalclaimedDetails?.uniqueUserCount
                )}
              </h3>
            </div>
            <div>
              <img src='/user-count.png' alt='ggr' />
            </div>
          </Card>
        </div>
      </Row>


      <Row style={{ marginTop: "50px" }}>

        <Col sm={6} lg={2} style={{ marginBottom: "1rem" }}>
          <label>
            Start Date:
            <Datetime
              value={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MM-DD-YYYY"
              timeFormat={false}
              inputProps={{
                placeholder: "MM-DD-YYYY",
                closeOnSelect: true,
              }}
            />
          </label>
        </Col>
        <Col sm={6} lg={2}>
          <label>
            End Date:
            <Datetime
              value={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="MM-DD-YYYY"
              timeFormat={false}
              inputProps={{
                placeholder: "MM-DD-YYYY",
                closeOnSelect: true,
              }}
            />
          </label>
        </Col>
       
       
      </Row>

      <Row className='mt-0'>
        <Col md={6} sm={6} className='mt-5'>
          <Card className=' tournament-card p-2'>
            <AmoeScannedDetailsChart
              labels={scannedDetailsChart?.labels}
              scannedData={scannedDetailsChart?.scannedData}
              isLoadingAmoeData={isLoadingAmoeData}
            />
          </Card>
        </Col>
        <Col md={6} sm={6} className='mt-5'>
          <Card className=' tournament-card p-2'>
            <AmoeClaimedDetailsChart
              labels={claimedDetailsChart?.labels}
              claimedData={claimedDetailsChart?.claimedData}
              isLoadingAmoeData={isLoadingAmoeData}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AmoeDashboard;
