import React, { useState, useEffect } from 'react'
import useNotificationsCenter from './hooks/useNotificationsCenter';
import { Formik, Form, ErrorMessage } from "formik";
import { Row, Col, Form as BForm, OverlayTrigger, Tooltip, Button, Spinner } from '@themesberg/react-bootstrap'
import { notificationSettingsSchema } from './schemas';

const NotificationCenter = () => {

  const { notificationSettings, setNotificationSettings, settingsLoading } = useNotificationsCenter()
  const [initialValues, setInitialValues] = useState({
    MinWin: 0,
    SlotsMinBet: 0,
    TableMinBet: 0,
    PackageActivation: false,
    TournamentActivation: false,
    GiveawayActivation: false
  })

  useEffect(() => {
    const mappedValues = notificationSettings?.data?.settings.reduce((acc, setting) => {
      switch (setting.key) {
        case "ADMIN_NOTIFICATION_MIN_WIN":
          acc.MinWin = Number(setting.value);
          break;
        case "ADMIN_NOTIFICATION_MIN_BET_SLOTS":
          acc.SlotsMinBet = Number(setting.value);
          break;
        case "ADMIN_NOTIFICATION_MIN_BET_TABLE_GAMES":
          acc.TableMinBet = Number(setting.value);
          break;
        case "ADMIN_NOTIFICATION_PACKAGE_ACTIVATION":
          acc.PackageActivation = setting.value === "true";
          break;
        case "ADMIN_NOTIFICATION_TOURNAMENT_ACTIVATION":
          acc.TournamentActivation = setting.value === "true";
          break;
        case "ADMIN_NOTIFICATION_GIVEAWAY_ACTIVATION":
          acc.GiveawayActivation = setting.value === "true";
          break;
        default:
          break;
      }
      return acc;
    }, {});

    setInitialValues(prev => ({ ...prev, ...mappedValues }));
  }, [notificationSettings]);

  return (
    <>
      <Row>
        <Col>
          <h3>Notification Center</h3>
        </Col>
      </Row>
      <Formik
        enableReinitialize={true} 
        initialValues={initialValues}
        validationSchema={notificationSettingsSchema}
        onSubmit={(formValues, { resetForm }) => {
          const data = {
            ADMIN_NOTIFICATION_MIN_WIN: parseInt(formValues.MinWin),
            ADMIN_NOTIFICATION_MIN_BET_SLOTS: parseInt(formValues.SlotsMinBet),
            ADMIN_NOTIFICATION_MIN_BET_TABLE_GAMES: parseInt(formValues.TableMinBet),
            ADMIN_NOTIFICATION_PACKAGE_ACTIVATION: formValues.PackageActivation,
            ADMIN_NOTIFICATION_TOURNAMENT_ACTIVATION: formValues.TournamentActivation,
            ADMIN_NOTIFICATION_GIVEAWAY_ACTIVATION: formValues.GiveawayActivation

          };
          setNotificationSettings(data)
          // resetForm();
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <Form>
            <Row>
              <Col>
                <BForm.Label>Max Win
                  <span className="text-danger"> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">
                      Enter zero if you do not want to add a limit.
                    </Tooltip>
                  }
                >
                  <BForm.Control
                    type="number"
                    name="MinWin"
                    placeholder="Enter Min Bet"
                    min="0"
                    max="99999"
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    value={values.MinWin}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value) && Number(value) <= 100000) {
                        handleChange(e);
                      }
                    }}
                  />
                </OverlayTrigger>
                <ErrorMessage
                  component="div"
                  name="MinWin"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Slots Max Bet
                  <span className="text-danger"> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">
                      Enter zero if you do not want to add a limit.
                    </Tooltip>
                  }
                >
                  <BForm.Control
                    type="number"
                    name="SlotsMinBet"
                    placeholder="Enter Slots Min Bet"
                    min="0"
                    max="99999"
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    value={values.SlotsMinBet}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value) && Number(value) <= 100000) {
                        handleChange(e);
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </OverlayTrigger>
                <ErrorMessage
                  component="div"
                  name="SlotsMinBet"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Table Max Bet
                  <span className="text-danger"> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">
                      Enter zero if you do not want to add a limit.
                    </Tooltip>
                  }
                >
                  <BForm.Control
                    type="number"
                    name="TableMinBet"
                    placeholder="Enter Tables Min Bet"
                    min="0"
                    max="99999"
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    value={values.TableMinBet}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value) && Number(value) <= 100000) {
                        handleChange(e);
                      }
                    }}
                  />
                </OverlayTrigger>
                <ErrorMessage
                  component="div"
                  name="TableMinBet"
                  className="text-danger"
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <BForm.Label>Package Notifications</BForm.Label>
                <BForm.Check
                  type="switch"
                  name="PackageActivation"
                  checked={values.PackageActivation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col>
                <BForm.Label>Tournament Notifications</BForm.Label>
                <BForm.Check
                  type="switch"
                  name="TournamentActivation"
                  checked={values.TournamentActivation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col>
                <BForm.Label>Giveaway Notifications</BForm.Label>
                <BForm.Check
                  type="switch"
                  name="GiveawayActivation"
                  checked={values.GiveawayActivation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
            </Row>
            <div className="mt-4 d-flex justify-content-between align-items-center">
              <Button
                variant="success"
                onClick={handleSubmit}
                disabled={settingsLoading}
              >
                Submit
                {settingsLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

    </>
  )
}

export default NotificationCenter