import { getBiggestWinnerAndLooser, getVipDashboardReport } from '../../../utils/apiCalls';
import { useQuery } from '@tanstack/react-query';

const useVipDashboard = () => {
  const { data: biggestWinnerLooser, isLoading } = useQuery({
    queryKey: ['vipBiggestWinLose'],
    queryFn: () => {
      return getBiggestWinnerAndLooser();
    },
    select: (res) => res?.data?.data,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: vipDashboardReport, isLoading: vipDashboardReportLoading } = useQuery({
    queryKey: ['vipDashboardReport'],
    queryFn: () => {
      return getVipDashboardReport();
    },
    select: (res) => res?.data,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  return {
    biggestWinnerLooser,
    isLoading,
    vipDashboardReport,
    vipDashboardReportLoading,
  };
};

export default useVipDashboard;
