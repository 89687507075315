export const AdminRoutes = {
  // pages
  DefaultRoute: "/",
  AdminRoute: "/admin",
  AdminSignin: "/admin/signin",
  Dashboard: "/admin/dashboard",
  Profile: "/admin/profile",
  Players: "/admin/players",
  Packages: "/admin/packages",
  Tournament: "/admin/tournament",
  Tier: "/admin/tier",
  CreatePackage: "/admin/packages/create-package",
  ReorderPackage: "/admin/reorder-package",
  UnarchivePackage: "/admin/unarchive-package",
  ReorderFtpBonus:
    "/admin/reorder-ftpBonus/:packageId/:firstPurchaseApplicable",
  EditPackageDetails: "/admin/edit-package/:packageId",
  PlayerDetails: "/admin/player-details/:userId",
  CasinoProviders: "/admin/casino-management/casino-providers",
  Aggregators: "/admin/casino-management/Aggregators",
  RestrictedProviderCountries: "/admin/restrict-providers-countries/:itemId",
  BannerManagement: "/admin/banner-management",
  CasinoCategories: "/admin/casino-management/casino-categories",
  ReorderCasinoCategories: "/admin/casino-management/reorder-categories",
  ReorderCasinoProviders: "/admin/casino-management/reorder-providers",
  CasinoGames: "/admin/casino-management/casino-games",
  CasinoSubCategories: "/admin/casino-management/casino-sub-categories",
  ReorderCasinoSubCategories: "/admin/casino-management/reorder-sub-categories",
  AddSubCategoryGames: "/admin/add-games/:masterGameSubCategoryId",
  ReorderGames: "/admin/casino-management/reorder-games",
  CmsListing: "/admin/cms",
  CmsDetails: "/admin/cms-details/:cmsPageId",
  CmsEdit: "/admin/edit-cms/:cmsPageId",
  CmsCreate: "/admin/create-cms/",
  // ContentPagesListing: '/admin/cms/pages',
  ContentPageDetails: "/admin/content-page-details/:pageId",
  BonusListing: "/admin/bonus",
  BonusCreate: "/admin/create-bonus/",
  BonusEdit: "/admin/edit-bonus/:bonusId",
  BonusDetails: "/admin/bonus-details/:bonusId",
  EmailCenter: "/admin/email-center",
  EmailCreate: "/admin/create-email/",
  EmailEdit: "/admin/edit-email/:emailTemplateId",
  EmailSend: "/admin/send-mail",
  ReferralBonusListing: "/admin/referralbonus",
  ReferralBonusCreate: "/admin/referral-create-bonus/",
  ReferralBonusEdit: "/admin/referral-edit-bonus/:bonusId",
  ReferralBonusDetails: "/admin/referral-bonus-details/:bonusId",

  ImageGallery: "/admin/image-gallery",
  Staff: "/admin/staff",
  Countries: "/admin/countries",
  CountriesRestrictedProviders: "/admin/restricted-providers/:countryId",
  CountriesRestrictedGames: "/admin/restricted-games/:countryId",
  CreateAdmin: "/admin/create-admin",
  EditAdmin: "/admin/edit-admin/:adminId",
  AdminDetails: "/admin/admin-details/:adminId",
  EmailTemplates: "/admin/email-templates",
  CreateEmailTemplate: "/admin/create-email-template",
  EditManualTemplate: "/admin/edit-manual-template/:emailTemplateId",
  EditEmailTemplates: "/admin/edit-email-template/:emailTemplateId",
  CasinoTransactions: "/admin/payment/casino-transactions",
  BankingTransactions: "/admin/transactions-banking",
  WithdrawRequest: "/admin/withdraw-transactions",
  VaultRequest: "/admin/vault-transactions",
  AmoeRequest: "/admin/alternative-method-of-entry-bonus",
  RedeemReqRuleConfig: "/admin/withdraw-transactions/view-rule-config",
  CreateRedeemReqRuleConfig: "/admin/withdraw-transactions/create-rule-config",
  EditRedeemReqRuleConfig:
    "/admin/withdraw-transactions/edit-rule-config/:ruleId",
  Affiliate: "/affiliates-list",
  ExportCenter: "/export-center",
  CreateAffiliate: "/create/affiliates",
  AffiliateDetail: "/edit/affiliates",
  tournamentCreate: "/admin/create-tournaments/",
  TournamentEdit: "/admin/edit-tournament/:tournamentId",
  TournamentDetails: "/admin/tournament-details/:tournamentId",
  tierCreate: "/admin/create-tier/",
  tierEdit: "/admin/edit-tier/:tierId",
  tierDetails: "/admin/tier-details/:tierId",
  Raffle: "/admin/raffle",
  RaffleCreate: "/admin/create-raffle/",
  RaffleEdit: "/admin/edit-raffle/:raffleId",
  RafflePayout: "/admin/raffle-payout/:raffleId",
  RaffleView: "/admin/raffle-view/:raffleId",
  PromotionBonus: "/admin/promotion-bonus/affiliate-promo-code",
  PromotionBonusCreate: "/admin/create-promotion-bonus/",
  PromotionBonusEdit: "/admin/edit-promotion-bonus/:promocodeId",
  PromotionBonusView: "/admin/promotion-bonus-view/:promocodeId",
  PromoCodeBonus: "/admin/promotion-bonus/purchase-promo-code",
  PromoCodeCreate: "/admin/promotion-bonus/create-promo-code/",
  PromoCodeEdit: "/admin/promotion-bonus/edit-promo-code/:promocodeId",
  PromoCodeView: "/admin/promotion-bonus/view-promo-code/:promocodeId",
  ArchivedPromoCodeView: "/admin/promotion-bonus/view-archived-promo-code/:promocodeId",
  PromocodeArchived: "/admin/promotion-bonus/archived-promo-code",
  CRMPromoBonus: "/admin/crm-promotion/crm-promo-bonus",
  CrmPromoBonusCreate: "/admin/crm-promotion/create-crm-promo-bonus/",
  CrmPromoBonusEdit:
    "/admin/crm-promotion/edit-crm-promo-bonus/:crmPromotionId",
  CrmPromoBonusView: "/admin/crm-promotion/view-crm-promo-bonus/:promocode",
  CrmPromoBonusViewMore:
    "/admin/crm-promotion/viewmore-crm-promo-bonus/:crmPromotionId",
  CRMPromoCode: "/admin/crm-promotion/crm-promo-code",
  CrmPromoCodeCreate: "/admin/crm-promotion/create-crm-promo-code/",
  CrmPromoCodeEdit: "/admin/crm-promotion/edit-crm-promo-code/:crmPromotionId",
  CrmPromoCodeView: "/admin/crm-promotion/view-crm-promo-code/:promocode",
  PromoCodeBlocking: "/admin/promocode-blocking", //restrict players from using promocodes
  ViewPackages: "/admin/packages/view-package/:packageId",
  ViewArchivePackages:'/admin/packages/view-archive-package/:packageId',
  SpinWheel: "/admin/spin-wheel",
  SpinWheelEdit: "/admin/spin-wheel/:wheelDivisionId",
  GeoBlocking: "/admin/geo-blocking",
  DomainBlock: "/admin/domain-blocking",
  DomainBlockCreate: "/admin/domain-blocking/create-domain/",
  DomainBlockEdit: "/admin/domain-blocking/edit-domain/:domainId",
  ReordertournamentList: '/admin/reorder-tournaments',
  NotFound: "/404",
  ServerError: "/500",
  NotificationCenter: "/admin/notification-center",
};

export const AffiliateRoute = {
  DefaultRoute: "/",
  AffiliateSignIn: "/affiliate/signin",
  AffiliateDashboard: "/affiliate-admin/dashboard",
  AffiliateProfile: "/affiliate-admin/profile",
  AffiliatePlayers: "/affiliate-admin/players",
  AffiliatePlayerDetails: "/affiliate-admin/player-details/:userId",
  AffiliateCreatePassword: "/affiliates/set-Password",
  AffiliateTransitions: "/affiliate/transitions",
  AffiliateCasinoTransitions: "/affiliate/casinoTransition",
  AffiliateCommission: "/affiliate/commission",
  NotFound: "/404",
  ServerError: "/500",
};
