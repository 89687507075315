import React, { useEffect, useState } from 'react'
import useCheckPermission from '../../utils/checkPermission'
import {
    Button,
    Form,
    Row,
    Col,
    Table,
    Form as BForm,
} from '@themesberg/react-bootstrap'
import { AdminRoutes } from '../../routes'
import Preloader, { InlineLoader } from '../../components/Preloader'
import { formatDateMDY } from '../../utils/dateFormatter'
import Trigger from '../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faEdit, faEye, faCancel, faWindowClose, faTrash, faSave } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../components/Pagination'
import { ConfirmationModal, DeleteConfirmationModal } from '../../components/ConfirmationModal'
import { getItem } from '../../utils/storageUtils'
import { timeZones } from '../Dashboard/constants'
import { convertToTimeZone, getFormattedTimeZoneOffset } from '../../utils/helper'
import useDailyBonusStreak from './hooks/useDailyBonusStreak'
import { toast } from '../../components/Toast'

const DailyBonusStreakListing = () => {
    const { isHidden } = useCheckPermission()
    const [dataBonus, setDataBonus] = useState([])
    const [editRowId, setEditRowId] = useState(null) 
    const [editValues, setEditValues] = useState({})
    const [errorGC, setErrorGC] = useState(""); 
    const [errorSC, setErrorSC] = useState("");
    const { updateBonus } = useDailyBonusStreak()

    const handleSubmit = (updateValues) => {
        if (!editValues.gcAmount || !editValues.scAmount || errorGC || errorSC) {
        toast('SC Amount and GC Amount cannot be empty!', 'error')
            return;
        }
        updateBonus(updateValues)
        setEditRowId(null) 
    }

    const {
        t,
        navigate,
        limit,
        page,
        search,
        setPage,
        setLimit,
        setSearch,
        bonusType,
        setBonusType,
        handleStatusShow,
        statusShow,
        setStatusShow,
        handleDeleteModal,
        status,
        handleYes,
        dailyBonusStreakData,
        totalPages,
        loading,
        active,
        setActive,
        handleDeleteYes,
        deleteModalShow,
        setDeleteModalShow,
        bonus
    } = useDailyBonusStreak()

    useEffect(() => {
        const set = new Set()
        dailyBonusStreakData?.rows?.map((bonus) => { set.add(bonus?.bonusType) })
        setDataBonus(Array.from(set))
    }, [dailyBonusStreakData])

    const timeZone = getItem("timezone");
    const timezoneOffset = timeZone != null ? timeZones.find(x => x.code === timeZone).value : getFormattedTimeZoneOffset()

    const handleEditClick = (row) => {
        setEditRowId(row.bonusId)
        setEditValues({
            scAmount: row.scAmount,
            gcAmount: row.gcAmount,
            bonusImg: row.bonusImg
        })
    }

    const handleChange = (e, field) => {
        setEditValues({
            ...editValues,
            [field]: e.target.value
        })
    }
   

    const validateSCInput = (value) => {
      if (!/^\d*\.?\d*$/.test(value)) {
        return "Only numbers are allowed.";
      }
      if ( Number(value) === 0 && value !== '') {
        return "Value must be greater than 0.";
      }
      if (Number(value) > 1000000) {
        return "Value must be between 1 and 1,000,000.";
      }
      return "";
    };
    const validateGCInput = (value) => {
        if (!/^\d*\.?\d*$/.test(value)) {
          return "Only numbers are allowed.";
        }
        if (Number(value) === 0 && value !== '') {
          return "Value must be greater than 0.";
        }
        if (Number(value) > 1000000) {
          return "Value must be between 1 and 1,000,000.";
        }
        return "";
      };
  
    const handleInputSCChange = (e) => {
      let value = e.target.value.replace(/\s+/g, ""); // Remove spaces
  
      const errorMessage = validateSCInput(value);
      setErrorSC(errorMessage);
  
      e.target.value = value; // Ensure cleaned value is set in input

      if (!errorMessage || value === "") {
          handleChange(e, "scAmount"); // Allow clearing but prevent invalid values
      }
    };
    const handleInputGCChange = (e) => {
        let value = e.target.value.replace(/\s+/g, ""); // Remove spaces
    
        const errorMessage = validateGCInput(value);
        setErrorGC(errorMessage);
    
        if (!errorMessage) {
          handleChange(e, "gcAmount"); // Update state only if valid
        }
    
        e.target.value = value; // Ensure cleaned value is set in input
      };
    return (
        <>
            <Row>
                <Col xs='9'>
                    <h3>Daily Bonus Streak</h3>
                </Col>

            </Row>
            <Row className='mt-2'>
            </Row>
            {<Table bordered striped responsive hover size='sm' className='text-center mt-4'>
                <thead className='thead-dark'>
                    <tr>
                        {['Day','SC Amount', 'GC Amount', 'Image',  'Action'].map((h) => (
                            <th key={h}>{h}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Boolean(dailyBonusStreakData) &&
                        dailyBonusStreakData?.rows?.map(
                            (bonus) => {
                                const { bonusId, bonusName, imageUrl, day, validFrom, isActive, minimumPurchase, gcAmount, scAmount, description, btnText, termCondition } = bonus;
                                return (
                                    <tr key={bonusId}>
                                        <td>
                                            {day}
                                        </td>
                                        <td>
                                            {editRowId === bonusId ? (
                                                <>
                                                    <BForm.Control
                                                        type="number"
                                                        name="scAmount"
                                                        min="1"
                                                        max="1000000"
                                                        step="any"
                                                        value={editValues.scAmount || ""}
                                                        onChange={handleInputSCChange}
                                                        // onKeyDown={(e) => {
                                                        //     // const allowedKeys = [
                                                        //     //    // "Backspace",
                                                        //     //     "Delete",
                                                        //     //     "Tab",
                                                        //     //     "ArrowLeft",
                                                        //     //     "ArrowRight",
                                                        //     //     "ArrowUp",
                                                        //     //     "ArrowDown",
                                                        //     // ];
                                                        //    // if (allowedKeys.includes(e.key)) return;

                                                        //     if (!/^\d$|\./.test(e.key)) {
                                                        //         e.preventDefault();
                                                        //     }
                                                        // }}
                                                    />
                                                    {errorSC && <div style={{ color: "red", marginTop: "5px" }}>{errorSC}</div>}
                                                </>
                                            ) : (
                                                scAmount
                                            )}
                                        </td>
                                        <td>
                                            {editRowId === bonusId ? (
                                                <>
                                                <BForm.Control
                                                type="number"
                                                name="gcAmount"
                                                min="1"
                                                max="1000000"
                                                step="any"
                                                value={editValues.gcAmount || ""}
                                                onChange={handleInputGCChange}
                                                // onKeyDown={(e) => {
                                                //     const allowedKeys = [
                                                //         "Backspace",
                                                //         "Delete",
                                                //         "Tab",
                                                //         "ArrowLeft",
                                                //         "ArrowRight",
                                                //         "ArrowUp",
                                                //         "ArrowDown",
                                                //     ];
                                                //     if (allowedKeys.includes(e.key)) return;

                                                //     if (!/^\d$|\./.test(e.key)) {
                                                //         e.preventDefault();
                                                //     }
                                                // }}
                                            />
                                            {errorGC && <div style={{ color: "red", marginTop: "5px" }}>{errorGC}</div>}
                                            </>
                                            ) : (
                                                gcAmount
                                            )}
                                        </td>
                                        <td>
                                            {editRowId === bonusId ? (
                                                <BForm.Control
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(event) => {
                                                        const file = event.currentTarget.files[0];
                                                        setEditValues({scAmount: scAmount,
                                                            gcAmount: gcAmount,
                                                            bonusImg: file})
                                                      //  setFieldValue("bonusImg", file);
                                                       // setPreview(URL.createObjectURL(file));
                                                    }}
                                                />
                                            ) : (
                                                imageUrl
                                            )}
                                        </td>
                                        <td>
                                            {editRowId === bonusId ? (
                                                <>
                                                    <Trigger message={'Save'} id={bonusId + 'save'} />
                                                    <Button
                                                       id={bonusId +'save'}
                                                        className='m-1'
                                                        size='sm'
                                                        variant='warning'
                                                        onClick={() => handleSubmit({
                                                            bonusId,
                                                            bonusName,
                                                            startDate: validFrom,
                                                            gcAmount: editValues.gcAmount,
                                                            scAmount: editValues.scAmount,
                                                            bonusImg: editValues.bonusImg,
                                                            description,
                                                            isActive,
                                                            btnText,
                                                            termCondition,
                                                        })}
                                                        hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                                                    >
                                                        <FontAwesomeIcon icon={faSave} />
                                                    </Button>
                                                    <Trigger message={'Cancel'} id={bonusId + 'cancel'} />

                                                    <Button
                                                       id={bonusId +'cancel'}
                                                        className='m-1'
                                                        size='sm'
                                                        variant='warning'
                                                        onClick={() => setEditRowId(null)}
                                                        hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                                                    >
                                                        <FontAwesomeIcon icon={faCancel} />
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Trigger message={'Edit'} id={bonusId + 'edit'} />
                                                    <Button
                                                       id={bonusId +'edit'}
                                                        
                                                        className='m-1'
                                                        size='sm'
                                                        variant='warning'
                                                        onClick={() => handleEditClick(bonus)}
                                                        hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                )
                            }
                        )}
                    {dailyBonusStreakData?.count === 0 &&
                        (
                            <tr>
                                <td
                                    colSpan={6}
                                    className='text-danger text-center'
                                >
                                    {'No Data Found'}
                                </td>
                            </tr>
                        )}
                </tbody>
            </Table>}
            {loading && <InlineLoader />}
            {/* {dailyBonusStreakData?.count !== 0 && (
                <PaginationComponent
                page={dailyBonusStreakData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                />
            )} */}
             {statusShow && 
      <ConfirmationModal
        isBonus={true}
        bonus={bonus}
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
      />}
        

        </>
    )
}

export default DailyBonusStreakListing
