import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { actionConstants, activityConstants, statusConstants, transactionConstants } from "../../constants";
import { formatDateYMD, formattedDate, getDateThreeMonthsBefore, getStartDateMonthsAgo } from "../../../../utils/dateFormatter";
import Trigger from "../../../../components/OverlayTrigger";
import { toast } from "../../../../components/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { convertTimeZone, getFormattedTimeZoneOffset, onDownloadCsvClick } from "../../../../utils/helper";
import DateRangePicker from "../../../../components/DateRangePicker";
import { timeZones } from "../../../Dashboard/constants";
import { getItem } from "../../../../utils/storageUtils";
import DatePicker from "react-datepicker";
import Datetime from 'react-datetime'


const ActivityTableFilters = ({
  startDate, 
  endDate,
  providerName,
  setProviderName,
  transaction,
  setTransaction,
  coinType,
  setCoinType,
  action,
  setAction,
  data,
  resetFilters,
  casinoProvidersData,
  providerNameArray,
  setProviderNameArray,
  getCsvDownloadUrl,
  handleStartDateChange,
  handleEndDateChange,
  errorEnd,
  errorStart
}) => {

  const handleDownloadClick = async () => {
    try {
      let filename = 'User_Activity';

      const formattedStartDate = formattedDate(startDate)
      const formattedEndDate = formattedDate(endDate)

      if (action == 'all' && transaction == 'all' && coinType == 'all' && providerName == 'all') {
        filename = `User_Activity_${formattedStartDate}_${formattedEndDate}`;
      } else if (action == 'all' && coinType == 'all' && providerName =='all') {
        filename = `User_Activity_${formattedStartDate}_${formattedEndDate}_${transaction}`;
      } else if (transaction == 'all' && coinType == 'all' && providerName =='all') {
        filename = `User_Activity_${formattedStartDate}_${formattedEndDate}_${action}`;
      }else if (action == 'all' && transaction == 'all' && providerName =='all') {
        filename = `User_Activity_${formattedStartDate}_${formattedEndDate}_${coinType}`;
      }else if (action == 'all' && transaction == 'all' && coinType =='all') {
        filename = `User_Activity_${formattedStartDate}_${formattedEndDate}_${providerName}`;
      }else if (action == 'all') {
        filename = `User_Activity_${formattedStartDate}_${formattedEndDate}_${transaction}_${coinType}_${providerName}`;
      }else if (transaction == 'all') {
        filename = `User_Activity_${formattedStartDate}_${formattedEndDate}_${action}_${coinType}_${providerName}`;
      } else if (coinType == 'all') {
        filename = `User_Activity_${formattedStartDate}_${formattedEndDate}_${action}_${transaction}_${providerName}`;
      } else if (providerName == 'all') {
        filename = `User_Activity_${formattedStartDate}_${formattedEndDate}_${action}_${transaction}_${coinType}`;
      } else {
        filename = `User_Activity_${formattedStartDate}_${formattedEndDate}_${action}_${transaction}_${coinType}_${providerName}`;
      }
      const url = getCsvDownloadUrl();
      await onDownloadCsvClick(url, filename)
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };
  return (
    <Row>
      {/* <Col xs="12" sm="6" lg="3" className="mb-3">
        <Form.Label column="sm" className="mx-auto text-nowrap px-2">
          Time Period
        </Form.Label>
        <DateRangePicker width="auto" state={state} setState={setState} timeZoneCode={timezoneOffset} startDate = {startDate} endDate={endDate}/>
      </Col> */}
      <Col
        className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
      >
        <Form.Label column="sm" className="mx-auto text-nowrap px-2">
          Start Date
        </Form.Label>
        <Datetime
          value={startDate}
          onChange={handleStartDateChange} 
          //onChange={(date) => setStartDate(date)}
          timeFormat={false}
        />
         {errorStart && <div style={{ color: "red", marginTop: "5px" }}>{errorStart}</div>}
      </Col>
      <Col
        className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
      >
        <Form.Label column="sm" className="mx-auto text-nowrap px-2">
          End Date
        </Form.Label>
        <Datetime
          value={endDate}
          timeFormat={false}
          onChange={handleEndDateChange}
          //onChange={(date) => setEndDate(date)}
        />
         {errorEnd && <div style={{ color: "red", marginTop: "5px" }}>{errorEnd}</div>}
      </Col>
      <Col xs="auto" className="mb-3">
        <Form.Label>Actions</Form.Label>
        <Form.Select value={action} onChange={(e) => setAction(e.target.value)}>
          {actionConstants?.map(({ label, value }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </Form.Select>
      </Col>

      <Col xs="auto" className="mb-3">
        <Form.Label>Transaction</Form.Label>
        <Form.Select
          value={transaction}
          onChange={(e) => setTransaction(e.target.value)}
        >
          {transactionConstants?.map(({ label, value }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </Form.Select>
      </Col>

      <Col xs="auto" className="mb-3">
        <Form.Label>Coin Type</Form.Label>
        <Form.Select
          value={coinType}
          onChange={(e) => setCoinType(e.target.value)}
        >
          <option value="">All</option>
          <option value="GC">Gold Coins</option>
          <option value="SC">Sweep Coins</option>
        </Form.Select>
      </Col>
      {casinoProvidersData?.rows && casinoProvidersData?.rows.length > 0 ? (
        <>
          <Col xs="auto" className="mb-3">
            <Form.Label>Provider</Form.Label>
            <Form.Select
              value={providerName}
              onChange={(e) => setProviderName(e.target.value)}
            >
              {providerNameArray?.map(({ label, value }) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        </>
      ) : (
        <></>
      )}
      <Col
        xs="auto"
        className="d-flex justify-content-center align-items-end mb-3"
      >
        <Trigger message="Download as CSV" id={"csv"} />
        <Button
          id={"csv"}
          variant="success"
          disabled={data?.count === 0 || data?.totalPages === null}
          onClick={handleDownloadClick}
        >
          <FontAwesomeIcon icon={faFileDownload} />
        </Button>
      </Col>

      <Col
        xs="auto"
        className="d-flex justify-content-center align-items-end mb-3"
      >
        <Trigger message="Reset Filters" id={"redo"} />
        <Button id={"redo"} variant="success" onClick={resetFilters}>
          <FontAwesomeIcon icon={faRedoAlt} />
        </Button>
      </Col>
    </Row>
  );
};

export default ActivityTableFilters;
