import React, { useEffect } from 'react'
import useNotifications from '../../pages/NotificationCenter/hooks/useNotifications';
import NotificationList from './NotificationList';

const Notifications = () => {

    const {
        notificationsData,
        setSearch,
        setIsUnread,
        markReadNotifications,
        handleSetUnread,
        showNotificationDetails,
        setShowNotificationDetails,
        handleLoadMore
    } = useNotifications()

    return (
        <>
            <NotificationList
                notificationsData={notificationsData}
                setSearch={setSearch}
                setIsUnread={setIsUnread}
                handleSetUnread={handleSetUnread}
                markReadNotifications={markReadNotifications}
                showNotificationDetails={showNotificationDetails}
                setShowNotificationDetails={setShowNotificationDetails}
                handleLoadMore={handleLoadMore}
            />
        </>
    );
}

export default Notifications