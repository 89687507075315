import { Button, Col, Row, Form } from "@themesberg/react-bootstrap";
import React from "react";
import Trigger from "../OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import useAmoeList from "../../pages/PlayerDetails/hooks/useAmoeList";
import AmoeList from "./AmoeList";

const AmoeData = () => {
  const {
    t,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    AmoeRefetch,
    AmoeData,
    loading,
    search,
    setSearch,
    setUsername,
    username,
    statusFilter,
    setStatusFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    postalCode,
    setPostalCode,
    resetFilters
  } = useAmoeList();

  const statusTypeOptions = [
    { label: 'All', value: 'all' },
    { label: 'Pending', value: 0 },
    { label: 'Success', value: 1 },
    { label: 'Reject', value: 2 },
  ]

  return (
    <>
      {
        <Row className="mb-3">
          <Col sm={12}>
            <h3>{t("amoe.amoeHeading")}</h3>
          </Col>
        </Row>
      }
      <Row className="w-100 m-auto">
        <Col xs="12" sm="6" lg="3" className="mb-3">
          <Form.Label
            style={{
              marginBottom: "0",
              marginRight: "15px",
              marginTop: "5px",
            }}
          >
            {t("amoe.filters.search")}
          </Form.Label>

          <Form.Control
            type="search"
            value={search}
            placeholder="Search By Email"
            onChange={(event) => {
              setPage(1);
              setSearch(event.target.value.replace(/[~`%^#)()><?]+/g, "").trim());
            }}
          />
        </Col>

        <Col className="col-lg-3 col-sm-6 col-12">
          <Form.Label
            style={{
              marginBottom: "0",
              marginRight: "15px",
              marginTop: "5px",
            }}
          >
            {t("amoe.filters.userName")}
          </Form.Label>

          <Form.Control
            type="text"
            value={username}
            placeholder="Search by Username"
            onChange={(event) => {
              setPage(1);
              setUsername(
                event.target.value.replace(/[~`%^#)()><?]+/g, "").trim()

              );
            }}
          />
        </Col>

        <Col className="col-lg-3 col-sm-6 col-12">
          <Form.Label
            style={{
              marginBottom: "0",
              marginRight: "15px",
              marginTop: "5px",
            }}
          >
            {t("amoe.filters.postalCode")}
          </Form.Label>

          <Form.Control
            type="text"
            value={postalCode}
            placeholder="Search by Postal code"
            onChange={(event) => {
              setPage(1);
              setPostalCode(
                event.target.value.replace(/[~`%^#)()><?]+/g, "").trim()
              );
            }}
          />
        </Col>

        <Col xs='12' lg='auto'>
          <div className='d-flex justify-content-start align-items-center w-100 flex-wrap'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('amoe.filters.status')}
            </Form.Label>

            <Form.Select
              value={statusFilter}
              onChange={(e) => {
                setPage(1);
                setStatusFilter(e.target.value);
              }}
            >
              {statusTypeOptions?.map(({ label, value }) => {
                return (
                  <option key={label} value={value}>
                    {label}
                  </option>
                );
              })}
            </Form.Select>
          </div>
        </Col>

        <Col
          className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
        >
          <Form.Label column="sm" className="mx-auto text-nowrap px-2">
            {t('amoe.filters.startDate')}
          </Form.Label>
          <Datetime
            value={startDate}
            onChange={(date) => setStartDate(date)}
            timeFormat={false}
          />
        </Col>
        <Col
          className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
        >
          <Form.Label column="sm" className="mx-auto text-nowrap px-2">
            {t('amoe.filters.endDate')}
          </Form.Label>
          <Datetime
            value={endDate}
            onChange={(date) => setEndDate(date)}
            timeFormat={false}
          />
        </Col>

        <Col
          xs="12"
          sm="6"
          lg="1"
          className="d-flex align-items-end mt-2 mt-sm-0 mb-0"
        >
          <Trigger message="Reset Filters" id={"redo"} />
          <Button
            id={"redo"}
            variant="success"
            onClick={resetFilters}
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>
        </Col>
      </Row>
      <AmoeList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        data={AmoeData}
        loading={loading}
        amoeRefetch={AmoeRefetch}
      />
    </>
  );
};

export default AmoeData;
