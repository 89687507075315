// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imagecontainer {
  position: relative; }

.imagecontainer img {
  display: block; }

.imagecontainer .copy {
  position: absolute;
  top: 0;
  right: 10px; }

.imagecontainer .text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.modal-height-width {
  width: 100vw !important; }

.fixTableHead {
  overflow-y: auto;
  height: 50px; }

.fixTableHead-th th {
  position: sticky;
  top: 0;
  z-index: 1; }

table {
  border-collapse: collapse;
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/PromoCodeBlocking/Components/modalStyle.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA;;AAEpB;EACE,cAAc,EAAA;;AAEhB;EACE,kBAAkB;EAClB,MAAM;EACN,WAAW,EAAA;;AAEb;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB,EAAA;;AAGzB;EACE,uBAAuB,EAAA;;AAGzB;EACE,gBAAgB;EAChB,YAAY,EAAA;;AAGd;EAEE,gBAAgB;EAChB,MAAM;EACN,UAAU,EAAA;;AAGZ;EACE,yBAAyB;EACzB,WAAW,EAAA","sourcesContent":[".imagecontainer {\n  position: relative;\n}\n.imagecontainer img {\n  display: block;\n}\n.imagecontainer .copy {\n  position: absolute;\n  top: 0;\n  right: 10px;\n}\n.imagecontainer .text {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.modal-height-width {\n  width: 100vw !important;\n}\n\n.fixTableHead {\n  overflow-y: auto;\n  height: 50px;\n}\n\n.fixTableHead-th th {\n  position: -webkit-sticky;\n  position: sticky;\n  top: 0;\n  z-index: 1;\n}\n\ntable {\n  border-collapse: collapse;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
