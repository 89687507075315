import React from "react";
import {
  Button,
  Row,
  Col,
  Table,
  Form,
} from "@themesberg/react-bootstrap";
import { CRM_PROMOTION_TYPE, tableHeaders } from "./constant";
import Trigger from "../../components/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown, faArrowAltCircleUp, faCheckSquare, faEdit, faEye, faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { AdminRoutes } from "../../routes";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../components/Pagination";
import { InlineLoader } from "../../components/Preloader";
import { ConfirmationModal, DeleteConfirmationModal } from "../../components/ConfirmationModal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { searchRegEx } from "../../utils/helper";
import useCheckPermission from "../../utils/checkPermission";
import useCrmPromoCodeListing from "./hooks/usePromoCodeListing";

const CRMPromoBonus = () => {
  const navigate = useNavigate()
  const { promoCodeList, selected, loading, page, totalPages, setPage, limit, setLimit, handleShow, show, setShow, handleYes, active,
    handleDelete, handleDeleteYes, deleteModalShow, setDeleteModalShow, setSearch, search, setOrderBy, sort, over, setOver, setSort,
    selectedType,
    setSelectedType,
    promoName,
    setpromoName, }
    = useCrmPromoCodeListing();
  
    const { isHidden } = useCheckPermission()

  return (
    <>
      <Row className="mb-2">
        <Col>
          <h3>CRM Promo Bonus</h3>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Button
              variant="success"
              hidden={isHidden({ module: { key: 'CrmPromotion', value: 'C' } })}
              size="sm"
              style={{ marginRight: "10px" }}
              onClick={() => {
                navigate(AdminRoutes.CrmPromoBonusCreate);
              }}
            >
              Create
            </Button>
          </div>
        </Col>
        <Row className="mb-2">
        <Col>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '8px' }}>
            Search
          </Form.Label>

          <Form.Control
            type='search'
            placeholder='Search Promocode'
            value={search}
            style={{ maxWidth: '330px', marginRight: '10px', marginTop: '5px' }}
            onChange={(event) => {
              setPage(1)
              const mySearch = event.target.value.replace(searchRegEx, '')
              setSearch(mySearch)
            }}
          />
        </Col>
         <Col>
                    <Form.Label
                      style={{
                        marginBottom: "0",
                        marginRight: "15px",
                        marginTop: "8px",
                      }}
                    >
                      Promotion Name
                    </Form.Label>
                    <Form.Control
                      type="search"
                      placeholder="Search by Name"
                      value={promoName}
                      style={{
                        maxWidth: "330px",
                        marginRight: "10px",
                        marginTop: "5px",
                      }}
                      onChange={(event) => {
                        setPage(1);
                        const mySearch = event.target.value.replace(searchRegEx, "");
                        setpromoName(mySearch);
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Label column="sm" className="mx-auto text-nowrap px-2">
                      Promotion Type
                    </Form.Label>
        
                    <Form.Select
                      style={{
                        maxWidth: "330px",
                        marginRight: "10px",
                        marginTop: "5px",
                      }}
                      onChange={(e) => {
                        setPage(1);
                        setSelectedType(e.target.value);
                      }}
                      value={selectedType}
                    >
                      {CRM_PROMOTION_TYPE &&
                        CRM_PROMOTION_TYPE?.map(({ label, value }) => (
                          <option key={label} value={value}>
                            {label}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  </Row>  
      </Row>
      <Table
        bordered
        striped
        responsive
        hover
        size="sm"
        className="text-center mt-4"
      >
        <thead className="thead-dark">
          <tr>
            {tableHeaders.map((h, idx) => (
              <th
                key={idx}
                onClick={() => h.value !== "" && setOrderBy(h.value)}
                style={{
                  cursor: "pointer",
                }}
                className={selected(h) ? "border-3 border border-blue" : ""}
              >
                {h.labelKey}{" "}
                {selected(h) &&
                  (sort === "ASC" ? (
                    <FontAwesomeIcon
                      style={over ? { color: "red" } : {}}
                      icon={faArrowAltCircleUp}
                      onClick={() => setSort("DESC")}
                      onMouseOver={() => setOver(true)}
                      onMouseLeave={() => setOver(false)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={over ? { color: "red" } : {}}
                      icon={faArrowAltCircleDown}
                      onClick={() => setSort("ASC")}
                      onMouseOver={() => setOver(true)}
                      onMouseLeave={() => setOver(false)}
                    />
                  ))}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {promoCodeList?.details?.count > 0 ?(
            promoCodeList?.details?.rows?.map(
              ({
                crmPromotionId,
                promocode,
                name,
                isActive,
                scAmount,
                gcAmount,
                // claimBonus,
                promotionType,
              }) => {
                return (
                  <tr key={crmPromotionId}>
                    <td>{crmPromotionId}</td>
                    <td>{promocode}</td>
                    <td>{name}</td>
                    <td>{isActive ? "Active" : "Inactive"}</td>
                    <td>{promotionType ==='scheduled-campaign' ? 'SCHEDULED' :'TRIGGERED'}</td>
                    <td>{scAmount}</td>
                    <td>{gcAmount}</td>

                    
                    <td>
                      <Trigger message={"View"} id={crmPromotionId + "view"} />
                      <Button
                        id={crmPromotionId + "view"}
                        className="m-1"
                        size="sm"
                        variant="info"
                        onClick={() =>
                          navigate(
                            `${AdminRoutes.CrmPromoBonusView.split(":").shift()}${promocode}`
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Trigger message="Edit" id={crmPromotionId + "edit"} />
                      <Button
                        id={crmPromotionId + "edit"}
                        hidden={isHidden({ module: { key: 'CrmPromotion', value: 'U' } })}
                        className="m-1"
                        size="sm"
                        variant="warning"
                        onClick={() =>
                          navigate(
                            `${AdminRoutes.CrmPromoBonusEdit.split(":").shift()}${crmPromotionId}`
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <Trigger message="Delete" id={crmPromotionId + "delete"} />
                         <Button
                          id={crmPromotionId + "delete"}
                          hidden={isHidden({ module: { key: 'CrmPromotion', value: 'U' } })}
                          className="m-1"
                          size="sm"
                          variant="warning"
                          onClick={() => handleDelete(promocode)}
                        >
                       <FontAwesomeIcon icon={faTrash} />
                      </Button>
                  
                      {!isActive ? (
                      <>
                        <Trigger
                          message="Set Status Active"
                          id={crmPromotionId + "active"}
                        />
                        <Button
                          id={crmPromotionId + "active"}
                          hidden={isHidden({
                            module: { key: "Promocode", value: "U" },
                          })}
                          className="m-1"
                          size="sm"
                          variant="success"
                          onClick={() => handleShow(crmPromotionId, isActive)}
                        >
                          <FontAwesomeIcon icon={faCheckSquare} />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Trigger
                          message="Set Status In-Active"
                          id={crmPromotionId + "inactive"}
                        />
                        <Button
                          id={crmPromotionId + "inactive"}
                          hidden={isHidden({
                            module: { key: "Promocode", value: "U" },
                          })}
                          className="m-1"
                          size="sm"
                          variant="danger"
                          onClick={() => handleShow(crmPromotionId, isActive)}
                        >
                          <FontAwesomeIcon icon={faWindowClose} />
                        </Button>
                      </>
                    )}
                    </td>
                  </tr>
                );
              }
            )):
            <tr>
              <td colSpan={10} className="text-danger text-center">
                No Data Found
              </td>
            </tr>
          }
        </tbody>

      </Table>
      {loading && <InlineLoader />}
      {promoCodeList?.details?.count !== 0 && (
        <PaginationComponent
          page={promoCodeList?.details?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
        />
      )}
      {deleteModalShow &&
        (
          <DeleteConfirmationModal
            deleteModalShow={deleteModalShow}
            setDeleteModalShow={setDeleteModalShow}
            handleDeleteYes={handleDeleteYes}
          />)
      }
      
    </>
  );
};

export default CRMPromoBonus;
