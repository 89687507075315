import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import {
  faUserAlt,
  faAward,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { loginCountSocket } from "../../../../utils/socket";
import { useUserStore } from "../../../../store/store";

export const Ticker = ({ loginData, data }) => {
  const [loginCount, setLoginCount] = useState(0);
  const [livePlayersCount, setLivePlayersCount] = useState(0);
  const [vaultSc,setVaultSc] = useState(0);
  const [walletSc,setWalletSc] = useState(0);
  const labelList = loginData.find(
    (data) => data?.label === "loginKeys.CURRENT_LOGIN"
  ).total;
  
  const loginCountSocketConnection = useUserStore(
    (state) => state.loginCountSocketConnection
  );
  const livePlayersCountConnection = useUserStore(
    (state) => state.livePlayersCountConnection
  );
  
  const loginCountSocketData = (data) => {
    setLoginCount(data);
  };

  
  const livePlayersCountSocketData = (data) => {
    setLivePlayersCount(data);
  };

  
  useEffect(() => {
    if (!loginCountSocketConnection) return;
    loginCountSocket.on("COMBINED_LIVE_UPDATE", (data) => {
      setWalletSc(Math.round(data?.totalScCoin * 100) / 100);
setVaultSc(Math.round(data?.totalVaultScCoin * 100) / 100);

      loginCountSocketData(data?.liveLoginCount);
    });
    return () => {
      loginCountSocket.off("COMBINED_LIVE_UPDATE", () => {
        console.log("socket disconnected");
      });
    };
  }, [loginCountSocketConnection]);

  useEffect(() => {
    if (!livePlayersCountConnection) return;

    loginCountSocket.on("COMBINED_LIVE_UPDATE", (data) => {
      setWalletSc(Math.round(data?.totalScCoin * 100) / 100);
setVaultSc(Math.round(data?.totalVaultScCoin * 100) / 100);

      livePlayersCountSocketData(data?.liveGamePlayCount);
    });
    return () => {
      loginCountSocket.off("COMBINED_LIVE_UPDATE", () => {
        console.log("socket disconnected");
      });
    };
  }, [livePlayersCountConnection]);
  const formattedVaultData = Math.round(data?.DASHBOARD_REPORT?.totalVaultScCoin * 100) / 100;
  const formattedWalletData = Math.round(data?.DASHBOARD_REPORT?.totalWalletScCoin * 100) / 100;



  return (
    <>
      <div className="ticker-wrapper p-2">
        <div className="ticker-container">
          <div className="ticker-today-loginC">
            <div className="new-icon">
              <FontAwesomeIcon icon={faUserAlt} style={{ color: "black" }} />
            </div>
            <label>Online Players</label>
            <div className="ticket-todayC">
              {" "}
              <p>{loginCount ? loginCount.toLocaleString() : labelList}</p>
              {/* <p className="green-c">{" (+50%) "}</p> */}
              {/* <FontAwesomeIcon icon={faArrowUp} style={{fontSize:"25px",color:"green"}} /> */}
            </div>
          </div>
        </div>
        <div className="ticker-container">
          <div className="ticker-today-loginC">
            <div className="new-icon">
              <FontAwesomeIcon icon={faUserAlt} style={{ color: "black" }} />
            </div>
            <label>Active Players</label>
            <div className="ticket-todayC">
              {" "}
              <p>{livePlayersCount ? livePlayersCount.toLocaleString() : labelList}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="ticker-wrapper p-2">
        <div className="ticker-container">
          <div className="ticker-today-loginC">
            <div className="new-icon">
              <FontAwesomeIcon icon={faDollarSign} style={{ color: "black" }} />
            </div>
            <label>Total Wallet SC</label>
            <div className="ticket-todayC">
              {" "}
              <p>{walletSc?walletSc.toLocaleString():(data ? formattedWalletData.toLocaleString() : 0)}</p>
            </div>
          </div>
        </div>
        <div className="ticker-container">
          <div className="ticker-today-loginC">
            <div className="new-icon">
              <FontAwesomeIcon icon={faDollarSign} style={{ color: "black" }} />
            </div>
            <label>Total Vault SC</label>
            <div className="ticket-todayC">
              {" "}
              <p>{vaultSc?vaultSc.toLocaleString():(data ? formattedVaultData.toLocaleString() : 0)}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ticker;
