// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30px; }

.custom-container.date {
  width: -moz-fit-content;
  width: fit-content;
  position: relative; }

.custom-input-field {
  width: 100%;
  min-height: 44px;
  color: #66799e;
  padding-left: 15px;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem; }
`, "",{"version":3,"sources":["webpack://./src/components/DateRangePicker/DateRangePicker.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY,EAAA;;AAEd;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,kBAAkB,EAAA;;AAGpB;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,+BAA+B;EAC/B,qBAAqB,EAAA","sourcesContent":[".custom-container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 30px;\n}\n.custom-container.date{\n  width: fit-content;\n  position: relative;\n}\n\n.custom-input-field {\n  width: 100%;\n  min-height: 44px;\n  color: #66799e;\n  padding-left: 15px;\n  border: 0.0625rem solid #d1d7e0;\n  border-radius: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
