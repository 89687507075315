

export const tableHeaders = [
    { labelKey: 'Promocode Id', value: 'promocodeId' },
    { labelKey: 'Promocode', value: 'promocode' },
    { labelKey: 'Discount Percentage', value: 'discountPercentage' },
    { labelKey: 'Per User Limit', value: 'perUserLimit' },
    { labelKey: 'Max Users Availed', value: 'maxUsersAvailed' },
    { labelKey: 'Used Count', value: 'maxUsersAvailedCount' },
    { labelKey: 'Is Active', value: 'isActive' },
    // { labelKey: 'Is Discount On Amount', value: 'isDiscountOnAmount' },
    { labelKey: 'Created At', value: 'createdAt' },
    { labelKey: 'Valid Till', value: 'validTill' },
    { labelKey: 'Action', value: 'Action' },
];


export const viewPromocodeHeaders = [
    { labelKey: 'User Id', value: 'userId' },
    { labelKey: 'Username', value: 'username' },
    { labelKey: 'Email', value: 'email' },
    { labelKey: 'Name', value: 'firstName' },
    // { labelKey: 'Last Name', value: 'lastName' },
    { labelKey: 'Status', value: 'isActive' },
    { labelKey: 'Claimed At', value: 'claimedAt' },
  ];
  
  export const CRM_PROMOTION_TYPE = {
    SCHEDULED: 'scheduled-campaign',
    TRIGGERED: 'triggered-campaign'
  }