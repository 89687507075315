import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
} from "@themesberg/react-bootstrap";
import moment from 'moment';

import Datetime from "react-datetime";
import { serialize } from "object-to-formdata";
import { toast } from "../../../components/Toast/index.jsx";
import {
  errorHandler,
  useGetRandomPromoCodeMutation,
  useUpdateCRMPromotionMutation,
  useUpdatePromotionMutation,
  useUpdateRaffleMutation,
} from "../../../reactQuery/hooks/customMutationHook/index.js";
import Trigger from "../../../components/OverlayTrigger/index.jsx";
import { AdminRoutes } from "../../../routes.js";
import {
  getCRMPromoCodeHistory,
  getPromotionBonusDetail,
  getRaffleDetail,
} from "../../../utils/apiCalls.js";
import { useQuery } from "@tanstack/react-query";
import { editCrmPromoSchema, editPromotionSchema } from "../schemas.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleRight } from "@fortawesome/free-regular-svg-icons";

const EditCRMPromoCode = ({ data }) => {
  const navigate = useNavigate();
  const { crmPromotionId } = useParams();
  const yesterday = new Date();
  const [randomCode, setRandomCode] = useState("");
  const { data: promotionBonusDetail, isLoading: loading } = useQuery({
    queryFn: () => {
      return getCRMPromoCodeHistory(crmPromotionId);
    },
    select: (res) => res?.data?.details,
    refetchOnWindowFocus: false,
  });

  const { mutate: updateCRMPromotionBonus, isLoading: createLoading } =
    useUpdateCRMPromotionMutation({
      onSuccess: (res) => {
        toast("Promotion Bonus Updated Successfully", "success");
        navigate(AdminRoutes.CRMPromoCode);
      },
      onError: (error) => {
        toast(error.response.data.errors[0].description, "error");
        errorHandler(error);
      },
    });

  const handleEditPromotionBonusSubmit = (formValues) => {
    const body = {
      ...formValues,
      crmPromotionId,
      // promocode: formValues.promocode,
      // name : formValues.name,
      claimBonus: true,
      promotionType: formValues.promotionType,
      scAmount: formValues.scAmount,
      gcAmount: formValues.gcAmount,
      isActive: formValues.isActive,
    };
    updateCRMPromotionBonus(body);
  };

  return (
    <div>
      <Row>
        <Col sm={8}>
          <h3>Edit CRM Promo Codes </h3>
        </Col>
      </Row>

      {promotionBonusDetail ? (
        <Formik
          initialValues={{
            promocode: randomCode || promotionBonusDetail?.promocode,
            name: promotionBonusDetail?.name,
            promotionType: promotionBonusDetail?.promotionType,
            scAmount: promotionBonusDetail?.scAmount,
            gcAmount: promotionBonusDetail?.gcAmount,
            isActive: promotionBonusDetail?.isActive,
          }}
          validationSchema={editCrmPromoSchema}
          enableReinitialize
          onSubmit={handleEditPromotionBonusSubmit}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <Row>
                <Col>
                  <BForm.Label>Promocode</BForm.Label>
                  <Row>
                    <Col >
                      <BForm.Control
                        type="text"
                        name="promocode"
                        min="0"
                        value={values.promocode}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled
                      />
                    </Col>
               
                  </Row>
                 
                </Col>
                <Col>
                  <BForm.Label>Name</BForm.Label>

                  <BForm.Control
                    type="text"
                    name="name"
                    min="0"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                 
                </Col>
                <Col>
                  <BForm.Label>
                    Promotion Type
                    <span className="text-danger"> *</span>
                  </BForm.Label>
                  <BForm.Select
                    type="text"
                    name={"promotionType"}
                    value={values.promotionType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {
                      <>
                        <option key={"scheduled"} value={"scheduled-campaign"}>
                          Scheduled
                        </option>
                        <option key={"triggered"} value={"triggered-campaign"}>
                          Triggerred
                        </option>
                      </>
                    }
                  </BForm.Select>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <BForm.Label>Bonus Sc</BForm.Label>

                  <BForm.Control
                    type="number"
                    name="scAmount"
                    min="0"
                    value={values.scAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="scAmount"
                    className="text-danger"
                  />
                </Col>
                <Col>
                  <BForm.Label>Bonus Gc</BForm.Label>

                  <BForm.Control
                    type="number"
                    name="gcAmount"
                    min="0"
                    value={values.gcAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="gcAmount"
                    className="text-danger"
                  />
                </Col>

                <Col >
                  <BForm.Label>Is Active</BForm.Label>

                  <BForm.Check
                    type="switch"
                    name="isActive"
                    checked={values.isActive}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component="div"
                    name="claimBonus"
                    className="text-danger"
                  />
                </Col>
              </Row>

              <div className="mt-4 d-flex justify-content-between align-items-center">
                <Button
                  variant="warning"
                  onClick={() => navigate(AdminRoutes.CRMPromoCode)}
                >
                  Cancel
                </Button>

                <Button
                  variant="success"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="ml-2"
                  disabled={createLoading}
                >
                  Submit
                  {createLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditCRMPromoCode;
