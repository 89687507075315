import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getTournamentDashboard, getTournamentDashboardGameIds, getTournamentDashboardTotalPlayer, getTournamentDashboardWinnerBootedSummary } from '../../../utils/apiCalls'
import {  useState } from 'react'
import moment from 'moment'


const useTournamentDashboardDetails = ({type}) => {
    const { tournamentId } = useParams()

    const [selectedGame, setSelectedGame] = useState()
    const [selectedPlayer, setSelectedPlayer] = useState()


    const { data: tournamentSummaryData } = useQuery({
        queryKey: [`tournamentDashboard-${tournamentId}`],
        queryFn: ({ queryKey }) => {
            return getTournamentDashboard({ tournamentId })
        },
        select: (res) => res?.data?.data,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        enabled: type !== "CREATE" && type !=="EDIT"
    })

    const { data: tournamentGameIds } = useQuery({
        queryKey: [`tournamentGameIds-${tournamentId}`],
        queryFn: ({ queryKey }) => {
            return getTournamentDashboardGameIds({ tournamentId })
        },
        select: (res) => res?.data?.data,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        enabled: type !== "CREATE" && type !=="EDIT"
    })

    const { data: tournamentTotalPlayers } = useQuery({
        queryKey: [`tournamentTotalPlayer-${tournamentId}`],
        queryFn: ({ queryKey }) => {
            return getTournamentDashboardTotalPlayer({ tournamentId })
        },
        select: (res) => res?.data?.data,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        enabled: type !== "CREATE" && type !=="EDIT"
    })

    const { data: tournamentWinnerBootedSummary, loading: tournamentBootedLoading} = useQuery({
        queryKey: [`tournamentWinner-${tournamentId}`],
        queryFn: ({ queryKey }) => {
            return getTournamentDashboardWinnerBootedSummary({ tournamentId })
        },
        select: (res) => res?.data?.data,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        enabled: type !== "CREATE" && type !=="EDIT"
    })

    return {
        tournamentSummaryData,
        tournamentGameIds,
        tournamentTotalPlayers,
        tournamentWinnerBootedSummary,
        tournamentBootedLoading,
        selectedGame,
        selectedPlayer,
        setSelectedGame,
        setSelectedPlayer,
    }
}

export default useTournamentDashboardDetails
