import { useEffect, useState, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";
import { getAllNotifications } from "../../../utils/apiCalls";
import {
  useMarkAllNotificationReadMutation,
  useMarkNotificationReadMutation,
} from "../../../reactQuery/hooks/customMutationHook";
import { useUserStore } from "../../../store/store";
import { getLoginToken } from "../../../utils/storageUtils";
import { whaleAlertSocket } from "../../../utils/socket";
import { NotificationToast } from "../../../components/Notifications/NotificationToast";

const useNotifications = ({showNotification}) => {
  const queryClient = useQueryClient();
  const [showNotificationsTab, setShowNotificationsTab] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const [isUnread, setIsUnread] = useState(false);
  const [count, setCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0)
  const [showNotificationDetails, setShowNotificationDetails] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const handleShowNotifications = () => {
    setShowNotificationsTab(!showNotificationsTab);
  };
  

  const queryKey = useMemo(
    () => ["notificationsList", limit, page, debouncedSearch, isUnread],
    [limit, page, debouncedSearch, isUnread]
  );

  const { isLoading: loading, data: notificationsData, refetch: refetchNotifications } = useQuery({
    // queryKey: ["notificationsList", limit, page, debouncedSearch, isUnread],
    queryKey,
    queryFn: ({ queryKey }) => {
      const params = { pageNo: queryKey[2], limit: queryKey[1] };
      if (queryKey[3]) params.contentSearch = queryKey[3];
      if (queryKey[4]) params.isUnread = queryKey[4];
      return getAllNotifications(params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const { mutate: updateNotifications, isLoading: updateloading } =
    useMarkNotificationReadMutation({
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries({
          queryKey: ["notificationsList"],
        });
      },
      onError: (error) => {
        console.log(error);
      },
    });
  
    // mark all read
  const { mutate: markAllRead, isLoading: markAllReadLoading } =
  useMarkAllNotificationReadMutation({
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ["notificationsList"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSetUnread = () => {
    setIsUnread(!isUnread);
  };

  const markReadNotifications = (id) => {
    updateNotifications(id);
  };

  const markAllReadNotifications = () => {
    markAllRead()
  };

  const handleLoadMore = () => {
    setLimit(prevLimit => Math.min(prevLimit + 15, totalCount));
  };

  useEffect(() => {
    setTotalCount(notificationsData?.data?.notifications?.count);
    setCount(notificationsData?.data?.notifications?.unreadCount);
  }, [notificationsData]);

  useEffect(() => {
    if (notificationsData?.data?.notifications?.rows) {
      const newData = notificationsData.data.notifications.rows;
      if (page === 1) {
        setNotifications(newData);
      } else {
        setNotifications((prev) => [...prev, ...newData]);
      }
    }
  }, [notificationsData, page]);

  useEffect(() => {
    if (notificationsData?.data?.notifications?.count > limit) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [notificationsData]);
  
  
    const notificationsSocketConnection = useUserStore(
      (state) => state.notificationsSocketConnection
    );
  
    useEffect(() => {
      if (notificationsSocketConnection) {
        whaleAlertSocket.on('ADMIN_NOTIFICATIONS', (data) => {
          let title = data?.data?.title
          let message = data?.data?.message
          {showNotification && NotificationToast(title, message)}
         
          setCount((prevCount) => {
            return Number(prevCount) + 1
          })
          setNotifications((prevNotifications) => {
            return [{ ...data?.data, createdAt: Date.now() }, ...prevNotifications];
          });
        })
      }
    }, [notificationsSocketConnection])


  return {
    notificationsData,
    notifications,
    refetchNotifications,
    setNotifications,
    loading,
    count,
    setCount,
    search,
    setSearch,
    isUnread,
    setIsUnread,
    showNotificationsTab,
    setShowNotificationsTab,
    handleShowNotifications,
    handleSetUnread,
    markReadNotifications,
    markAllReadNotifications,
    showNotificationDetails,
    setShowNotificationDetails,
    handleLoadMore,
    hasMore
  };
};

export default useNotifications;
