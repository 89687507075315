// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 767.9px) {
  .permission-row {
    border-bottom: 1px solid #80808042;
    width: 100%;
    margin: auto; } }
`, "",{"version":3,"sources":["webpack://./src/pages/Staff/staff.scss"],"names":[],"mappings":"AAAA;EACI;IACI,kCAAkC;IAClC,WAAW;IACX,YAAY,EAAA,EACf","sourcesContent":["@media screen and (max-width: 767.9px){\n    .permission-row{\n        border-bottom: 1px solid #80808042;\n        width: 100%;\n        margin: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
