import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getPromoCode, getRaffle } from '../../../utils/apiCalls'
import { toast } from '../../../components/Toast'
import { errorHandler, useDeletePromoCodeMutation, useReusePromocode, useUpdatePromoCodeMutation } from '../../../reactQuery/hooks/customMutationHook'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { getDateDaysAgo } from '../../../utils/dateFormatter'

const usePromoCodeListing = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { t } = useTranslation('casino');
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('')
  const [sort, setSort] = useState('DESC')
  const [over, setOver] = useState(false)
  const [promocodeId, setPromocodeId] = useState()
  const [deletePromocode, setDeletePromocode] = useState()
  const [active, setActive] = useState()
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [type, setType] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [statusFilter, setStatusFilter] = useState('all')
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  const [crmPromocode, setIsCrmPromo] = useState(false); 
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [reuseModalShow, setReuseModalShow] = useState(false);
  const [isActive, setIsActive] = useState('all');
  const [isArchive, setIsArchive] = useState(false)

  const [selectedMaxUsersAvailed, setSelectedMaxUsersAvailed] = useState(0)
  const [selectedPerUserLimit, setSelectedPerUserLimit] = useState(0)

  const { data: promoCodeList, isLoading: loading } = useQuery({ 
    queryKey: ['promoCodeList', limit, page, orderBy, 
    sort, debouncedSearch, isActive, isArchive, crmPromocode],
    queryFn: ({ queryKey }) => {
      const params = {pageNo: queryKey[2], limit: queryKey[1]};
      if (queryKey[3]) params.orderBy = queryKey[3]
      if (queryKey[4]) params.sort = queryKey[4]
      if (queryKey[5]) params.promocode = queryKey[5]
      if (queryKey[6]) params.isActive = queryKey[6]
      if (queryKey[7]) params.isArchive = queryKey[7]
      if (queryKey[8]) {params.crmPromocode = true }
      return getPromoCode(params)
    },
    select: (res) => res?.data,
    refetchOnWindowFocus: false
  })

  const selected = (h) =>
    orderBy === h.value &&
    h.labelKey !== 'Actions'

  const totalPages = Math.ceil(promoCodeList?.promocodeDetail?.count / limit)

  const handleShow = (id, active) => {
    setPromocodeId(id)
    setActive(!active)
    setShow(true)
  }
  const handleDelete = (id)=>{
    setDeletePromocode(id)
    setDeleteModalShow(true)
  }
  const { mutate: updateStatus,isLoading:updateloading } = useUpdatePromoCodeMutation({ 
    onSuccess: ({ data }) => {
        if (data.message) toast(data.message, 'success')
        queryClient.invalidateQueries({ queryKey: ['promoCodeList'] })
      setShow(false)
    }, onError: (error) => {
      errorHandler(error)
    }
  })

  const handleYes = () => {
    updateStatus({
      promocodeId: promocodeId,
      isActive: active
    })
  }
  const { mutate: deletePromoCode,isLoading:deleteLoading } = useDeletePromoCodeMutation({
    onSuccess: ({ data }) => {
        if (data.message) toast(data.message, 'success')
        queryClient.invalidateQueries({ queryKey: ['promoCodeList'] })
        setDeleteModalShow(false)
    }, onError: (error) => {
      errorHandler(error)
    }
  })
  const handleDeleteYes = () => {
    deletePromoCode({
      promocode: deletePromocode,
    })
  }
  const handleClose = () => setShowModal(false)

  const { mutate: reusePromocode , isLoading: reuseLoading } = useReusePromocode({
    onSuccess: ({ data }) => {
      if (data.success) {
        if (data.message) {
          toast(data.message, "success")
          queryClient.invalidateQueries({ queryKey: ['promoCodeList'] })
        }
      
      }
      setReuseModalShow(false);
    },
    onError: (error) => {
      errorHandler(error)
      setReuseModalShow(false);
    },
  });

  const handleReusePromocodeYes = (data)=>{
    console.log('Promocodeeee' ,data)
    if(!promocodeId){
      toast("Promocode ID is missing", { type: "error" });
      return;
    }
    data.promocodeId = parseInt(promocodeId);
    reusePromocode(data);
  }

  const handleReuseModal = (id, maxUsersAvailed, perUserLimit)=>{
    setPromocodeId(id);
    setSelectedMaxUsersAvailed(maxUsersAvailed)
    setSelectedPerUserLimit(perUserLimit)
    setReuseModalShow(true);
  }

  return {
    t,
    limit,
    page,
    loading,
    promoCodeList,
    show,
    setLimit,
    setPage,
    setShow,
    totalPages,
    handleShow,
    handleYes,
    showModal,
    type,
    handleClose,
    selectedCategory,
    setSelectedCategory,
    active,
    navigate,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    setOrderBy,
    selected,
    sort,
    setSort,
    over,
    setOver,
    statusFilter,
    setStatusFilter,
    search,
    setSearch,
    selectedCurrency,
    setSelectedCurrency,
    state,
    setState,
    setType,
    handleDelete,
    deleteLoading,
    updateloading,
    crmPromocode, setIsCrmPromo,
    reuseModalShow, setReuseModalShow, handleReusePromocodeYes, handleReuseModal,
    reuseLoading,
    isActive, setIsActive,
    isArchive, setIsArchive,
    selectedMaxUsersAvailed,
    selectedPerUserLimit
  }
}

export default usePromoCodeListing
