import * as Yup from 'yup'
const today = new Date(Date.now());

const yesterday = new Date(Date.now() - 86400000);

export const casinoCategorySchema = (t) => Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .min(5, 'Title must be at least 5 characters')
    .max(50, 'Title must not exceed 50 characters')
    .matches(
      /^[a-zA-Z0-9\s]+$/,
    'Title must only contain letters, numbers, and spaces'
    ),
  entryAmount: Yup.number()
    .min(0, 'Joining Amount should be 0 or more')
    //  .matches(
    //   /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    //   'Enter Positive number'
    // )
    .required('Joining Amount Required'),

  playerLimitIsActive: Yup.boolean(),

  playerLimit: Yup.number().when('playerLimitIsActive', {
    is: (riskClassification) => riskClassification,
    then: (schema) => schema.min(1, 'Minimum player should be 1').required('Player limit is Required'),
    otherwise: () => Yup.number().nullable()
  }),
  // playerLimit: Yup.string()
  //   .matches(
  //     /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
  //     'Enter Positive number'
  //   )
  //   .required('Allow Players Required'),

  description: Yup.string()
    .required('Descriptions is required')
    .min(1, 'descriptions must be at least 5 characters'),

  winGc: Yup.number()
    .min(0.01, 'Prize GC should  be greater than 0')
    .required('Prize GC Required'),

  winSc: Yup.number()
    .min(0.01, 'Prize SC should  be greater than 0')
    .required('Prize SC Required'),

  numberOfWinners: Yup.number().when('playerLimitIsActive', {
    is: (riskClassification) => riskClassification,
    then: (schema) => schema.max(Yup.ref('playerLimit'), 'Number should be less/equal to playerLimit')
    .test("max", "Winner should not be exceeds Player Limit and max(5)", function(value) {
      const { playerLimit } = this.parent;
      return value <= 5 && value <= playerLimit;
    })
    .required('Number of winners is required'),
    otherwise: () =>Yup.number()
        .min(1, 'Number should be greater than 0')
        .max(5, 'Number should not be greater than 5')
        .required('Number of winners is required')
    }),

  startDate: Yup.date()
    .min(today, 'Date cannot be earlier than today.')
    .max(Yup.ref('endDate'), "Start Date must be less than end Date.")
    .typeError('The value must be a date (MM-DD-YYYY)')
    .required('Start Date field is required'),

  endDate: Yup.date().min(
    Yup.ref('startDate'),
    "End Date must be greater than start Date."
  )
    .test('notSameAsStart', 'End date cannot be the same as start date', function (endDate) {
      const { startDate } = this.parent;
      return !startDate || !endDate || startDate.getTime() !== endDate.getTime();
    }).required('End Date field is required'),

  gameId: Yup.array()
    .min(1, 'Please select at least one game')
    .max(6, `You can select maximum of ${6} games`),

  winnerPercentage: Yup.array()
    .of(Yup.number().min(1, 'Minimum value should be 1').max(100, `Maximum value should be 100`).required("Field is required"))
    .test('winnerPercentage', 'Sum of Ranks must be  100', (value) =>
      value.reduce((acc, curr) => acc + curr, 0) === 100
    )
  // .required('At least one number is required'),

  // winnerPercentage: Yup.mixed().test({
  //     message: 'Required',test: 
  //    val => val.filter(i => i !== 0)})

})

