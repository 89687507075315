export const tableHeaders =  [
    { labelKey: 'Id', value: 'id' },
    { labelKey: 'email', value: 'email' },
    { labelKey: 'user Id', value: 'userId' },
    { labelKey: 'user Name', value: 'userName' },
    { labelKey: 'claim Status', value: 'claimStatus' },
    { labelKey: 'postal Code', value: 'postalCode' },
    { labelKey: 'created At', value: 'createdAt' },
    { labelKey: 'valid upto', value: 'validTo' },
    { labelKey: 'ip Address', value: 'ipAddress' },
    { labelKey: 'action', value: 'action' },
]
